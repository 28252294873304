/*
 * @Description: 固化的字段或者模板id等,有一些写死的逻辑
 * @Author: 梁平贤
 * @Date: 2020/7/4 15:54
 */

// 固化的字段field,还有很多需要补充
const BusinessFixedField = {
  bankAccountNo: "bankAccountNo" // 银行账号

};

// 固化的模板id
const BusinessFixedTemplateId = {
  overtime: "180824142656336667", // 加班
  task: "180824142656336515"// 任务
};

export {
  BusinessFixedField,
  BusinessFixedTemplateId
};
