/*
 * @Description: 业务按钮功能实现,有些特殊的按钮放到流程那儿去了,比如编辑,删除等
 * @Author: 梁平贤
 * @Date: 2020/8/18 16:32
 */

import { Message } from "element-ui";
import { request, componentMixins } from "en-js";
import { btnService } from "@/api/businessModel";
import API from "@/api/businessModel/list";
import { BillStatus } from "@/tools/classes/process/ProcessHeader";
import { enEqual } from "@/tools/compare";
import { customEach } from "@/tools/util";
import {
  convertCurrentDetailRouteToCopy, newBusinessTaskParams, convertCurrentDetailRouteToEdit, businessListEntranceRoute, newTabAddBusinessData
} from "../../helper.js";
import CustomAndAdvanceButtonProcess from "./CustomAndAdvanceButtonProcess";
import { BusinessDataOpenType, BusinessType, BusinessDataPageType } from "../../data/BusinessCommonHeader";
import { BusinessFixedTemplateId } from "../../data/fixedKeys";
import { ProcessButtonType, ProcessButton } from "../../../../tools/classes/process/ProcessButton";

export default {
  mixins: [componentMixins, CustomAndAdvanceButtonProcess],
  computed: {
    // 一般情况下的提交,暂存参数saveType不一样
    paramsForSubmit() {
      return this.businessData.paramsForSubmit();
    },
    // 业务建模组件
    businessComponents() {
      return this._MxFindComponentUpward(this, "BusinessDetail");
    }
  },
  methods: {
    /**
     * @description: 新增任务 任务暂时没做
     * @item :当前按钮信息
     */
    async btnAddTask() {
      const { id, templateId } = this.businessData.businessParams;
      const res = await btnService.mdSearchPowerInfo({});
      const query = newBusinessTaskParams({
        // 任务默认模板id
        templateId: BusinessFixedTemplateId.task,
        taskSource: "3",
        taskSourceId: id,
        taskSourceTemplateId: templateId
      });
      if (res === 0) {
        // this.$router.push({
        //   path: "/businessModel/detail",
        //   query
        // });
        newTabAddBusinessData(query);
      } else {
        Message.warning("没有任务新增权限，请联系管理员");
      }
    },
    /**
     * @description: 复制
     * @item :当前按钮信息
     */
    btnCopy() {
      // fixme: 需要比较数据, 保存? 需要问问产品,先做着
      if (this.sourceType === "list") {
        // 列表
        let detailRoute = businessListEntranceRoute({
          id: this.id, templateId: this.templateId, businessType: this.businessType
        });
        detailRoute = convertCurrentDetailRouteToCopy(detailRoute);
        this.$router.push(detailRoute);
      } else {
        const copyRoute = convertCurrentDetailRouteToCopy(this.$route);
        this.$router.replace(copyRoute);
      }
      // 这里有个比较，提供方法返回true or false
      // if (oldData === newData || (this.openType === BusinessDataOpenType.add && this.copyId)) {
      //   console.log(1);
      //   this.$confirm("是否保存此次编辑?", "", {
      //     confirmButtonText: "是",
      //     cancelButtonText: "否",
      //     type: "warning",
      //     showCancelButton: false
      //   })
      //     .then(() => {
      //       if (this.businessData.mdInfo?.billStatus === "000") {
      //         // this.draft(); // 暂存
      //       } else {
      //         // this.submitStart(); // 提交
      //       }
      //     });
      // }
    },
    /**
     * @description: 分享
     * @item :当前按钮信息
     */
    btnShare() {
      // 弹窗
      this.shareDialogVisible = true;
    },
    /**
     * @description: 发票
     * @item :当前按钮信息
     */
    @request()
    async btnInvoice() {
      const params = { id: this.id };
      const res = await btnService.mdInvoiceAutoResolve(params);
      // 发票比较特殊,单独跳转的
      this.alertToUserWithResp(res);
      this.jumpToList(res.templateId, res.templateName);
    },
    @request(false)
    /**
     * @description: 打印
     * @item :当前按钮信息
     */
    btnPrint() {
      this.$refs.print.print();
    },
    /**
     * @description: 高级按钮方法
     * @item :当前按钮信息
     */
    btnAdvance(item) {
      this.advanceButtonProcess({
        button: item,
        templateId: this.templateId,
        selectIds: this.id,
        isFirstRequst: true
      });
    },

    /**
     * @description: 高级按钮批量生成
     * @data :返回的数据，需要呈现出来
     * @comm :参数
     */
    popBatch(data, comm) {
      this.advanceRes = data;
      this.advanceParams = comm;
      // 未完，等UI提供
    },
    // 业务按钮方法
    //  编辑
    async businessEdit() {
      if (this.businessData.businessType !== BusinessType.basic && this.businessData.viewType !== "chat") { // 是否在沟通中调用this.businessData.viewType !== "chat"
        this.$showLoading();
        try {
          await btnService.queryEditObjectCheck({
            ids: this.businessData.id,
            templateId: this.businessData.mdInfo.templateId
          });
        } catch {
          this.$hideLoading();
          return;
        }
        this.$hideLoading();
      }
      if (this.sourceType === "detail" && this.businessData.viewType !== "chat") {
        // 检查版本;
        if (this.businessData.mdInfo.newVersion && this.businessData.mdInfo.newVersion !== this.businessData.mdInfo.version) {
          // this.versionDialog = true;
          this.$confirm("检测到新模板，是否按新模板编辑？", "", {
            confirmButtonText: "确定",
            showCancelButton: false,
            type: "warning"
          })
            .then(() => {
              this.businessEditChooseVersion(this.businessData.mdInfo.newVersion);
            })
            .catch(() => {
              this.businessEditChooseVersion(this.businessData.mdInfo.version);
            });
        } else {
          this.businessEditChooseVersion(this.businessData.mdInfo.version);
        }
      } else {
        // 列表直接push
        let detailRoute = businessListEntranceRoute({
          id: this.id, templateId: this.templateId, businessType: this.businessType, sourcetype: "list_normal"
        });
        detailRoute = convertCurrentDetailRouteToEdit(detailRoute);
        this.$router.push(detailRoute);
      }
    },
    //  选择版本编辑
    businessEditChooseVersion(version) {
      // 如果当前路由就是编辑路由,并且版本号相等 就不需要替换路由了, 这种场景存在于列表点击编辑进入
      if (this.businessData.openType === BusinessDataOpenType.edit && version === this.businessData.mdInfo.version) {
        return;
      }
      // 弹窗不处理,直接刷新当前页面
      if (this.isBusinessDialog) {
        // 是弹窗的话,参数修改为编辑再刷新
        this.businessComponents.notRouteParam.version = version;
        this.businessComponents.notRouteParam.openType = BusinessDataOpenType.edit;
        this.businessComponents.init();
      } else {
        const editRoute = convertCurrentDetailRouteToEdit(this.$route, version);
        this.$router.replace(editRoute);
      }
    },

    // 删除
    businessDelete(button) {
      this.businessDeleteComm(button);
    },
    @request()
    async businessDeleteComm(button) {
      const params = {
        ids: this.id,
        templateId: this.templateId
      };
      const res = await btnService.mdDeleteObject(params);
      if (res.failCount === 0) {
        this.businessCustomJump(button, res);
      } else {
        Message.warning(`删除失败：${res.failReason}`);
      }
    },
    @request(false)
    // 暂存
    @request()
    async btnDraft(button) {
      const params = this.businessData.paramsForSubmit({ isSave: true });
      const res = await btnService.mdSubmitData(params);
      if (res.checkMessage) {
        Message.warning(res.checkMessage);
      }
      // 记录下提交,防止路由钩子触发
      this.businessData.isSubmitted = true;
      this.businessCustomJump(button, res);
    },
    @request(false)
    // 关闭浏览器页签
    handleCloseSheet() {
      window.location.href = "about:blank";
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    findComponentUpward(context, componentName) {
      let parent = context.$parent;
      let name = parent.$options.name;

      while (parent && (!name || [componentName].indexOf(name) < 0)) {
        parent = parent.$parent;
        if (parent) name = parent.$options.name;
      }
      return parent;
    },
    // 4.9.3 zjh 允许重叠 禁止重叠 的校验
    async allowOrBan(p, callback) {
      const field7 = {};// 校验字段的 集合 用来取详细配置
      const allow = [];// 允许提交的时间字段
      const ban = [];// 禁止字段集合 eg：["m123"]
      const mdData = JSON.parse(p.mdData);
      const checkField = [];// eg:[{"field":"校验字段，d2.f1", "fieldArea":"校验区域，1主表区、2明细区、3子集区"}]
      const checkFieldList = [];// ["m123"],判重用
      const checkData = {};// 类似提交数据只有类型为7的durationCalculationFlg！=1的数据
      // v4.2 使用频率selectionFields  zjh 所有 单选 多选 基础数据 使用的集合
      const selectionFields = [];
      this.businessData.originData.fieldAttr.forEach((v) => {
        const fieldType = `${v.fieldType}`;
        if ((fieldType === "5" || fieldType === "15") && `${v.dataSource}` === "1") {
          // 只管基础数据类型的 数据
          Object.keys(mdData).forEach((a) => {
            if (v.field === a) {
                // 找到相同的 field 的 字段
                const b = mdData[a];
                b.forEach((b1) => {
                    selectionFields.push({ id: b1.id, objTypeId: v.areaObjTypeId, objType: v.areaObjType });
                });
            }
        });
        }
        if (fieldType === "7" && v.dateRange === 0 && v.durationCalculationFlg !== 1) { //  数据 durationCalculationFlg 重叠开关，0开、1关 并且是时间区间
          const b = mdData[v.field];
          if (b) {
            field7[v.field] = v;
            if (v.allowConflict === 1) {
                // 允许
                allow.push(v);
            } else {
                ban.push(v.field);
            }
            checkFieldList.push(v.field);
            checkField.push({ field: v.field, fieldArea: "1", targetField: v.targetField });
            checkData[v.field] = b;
            checkData[v.targetField] = mdData[v.targetField];
          }
        }

        if (fieldType === "11" && v.sub && v.sub.length) { // 明细
          const arr = mdData[v.field] || [];
          checkData[v.field] = [];
          // 循环数据体
          customEach(arr, (i, b) => {
              const obj = {};
               customEach(b, (k1, v1) => {
                customEach(v.sub, (k2, v2) => {
                  const fieldType2 = `${v2.fieldType}`;
                  if ((fieldType2 === "5" || fieldType2 === "15") && `${v2.dataSource}` === "1" && v2.field === k1) {
                      // 找到相同的 field 的 字段
                      customEach(v1, (k3, v3) => {
                        selectionFields.push({ id: v3.id, objTypeId: v1.areaObjTypeId, objType: v1.areaObjType });
                      });
                  }
                  if (v2.field === k1 && fieldType2 === "7" && v2.dateRange === 0 && v1 && v2.durationCalculationFlg !== 1) {
                      // 找到字段配置
                      if (checkFieldList.indexOf(v2.field) === -1) {
                          field7[(`${v2.parentField }.${v2.field}`)] = v2;
                          if (v2.allowConflict === 1) {
                              // 允许
                              allow.push(v2);
                          } else {
                              ban.push(`${v2.parentField }.${v2.field}`);
                          }
                          checkFieldList.push(v2.field);
                          checkField.push({ field: `${v2.parentField }.${ v2.field}`, fieldArea: "2", targetField: v2.targetField });
                          checkData[v2.targetField] = mdData[v2.targetField];
                      }
                      obj[k1] = v1;
                  }
                  if (v2.field === k1 && fieldType2 === "35" && v2.sub && v2.sub.length) {
                      // 子集数据
                      obj[k1] = [];
                      const arr1 = v1;// 数据
                      customEach(arr1, (a1, b1) => {
                        const obj1 = {};
                        customEach(b1, (k3, v3) => {
                          customEach(v2.sub, (k4, v4) => {
                            const fieldType4 = `${v4.fieldType}`;
                            if (k3 === v4.field && fieldType4 === "7" && `${v4.dateRange}` === "0" && v3 && v4.durationCalculationFlg !== 1) {
                              if (checkFieldList.indexOf(v4.field) === -1) {
                                  field7[(`${v4.parentField }.${v4.field}`)] = v4;
                                  if (v4.allowConflict === 1) {
                                      // 允许
                                      allow.push(v4);
                                  } else {
                                      ban.push(`${v4.parentField }.${v4.field}`);
                                  }
                                  checkFieldList.push(v4.field);
                                  checkField.push({ field: `${v4.parentField }.${ v4.field}`, fieldArea: "3", targetField: v4.targetField });
                                  checkData[v4.targetField] = mdData[v4.targetField];
                              }
                              obj1[k3] = v3;
                            }
                            if ((fieldType4 === "5" || fieldType4 === "15") && `${v4.dataSource}` === "1") {
                              customEach(b[v2.field] || [], (aa1, bb1) => {
                                customEach(bb1 || [], (aa2, bb2) => {
                                    if (v4.field === aa2) {
                                        // 找到相同的 field 的 字段
                                        customEach(bb2 || [], (aa3, bb3) => {
                                            selectionFields.push({ id: bb3.id, objTypeId: v4.areaObjTypeId, objType: v4.areaObjType });
                                        });
                                    }
                                });
                              });
                            }
                          });
                        });
                        obj[k1].push(obj1);
                      });
                  }
                });
              });
              checkData[v.field].push(obj);
          });
        }
      });
      console.log(checkData);
      if (checkField.length) {
        const checkParams = {
          id: p.id,
          templateId: p.templateId,
          checkField: JSON.stringify(checkField),
          checkData: JSON.stringify(checkData)
        };
        // 需要调接口校验数据
        const data = await btnService.mdCheckDateRangeConflict(checkParams);
        // "result": "0有重叠、1没有重叠",
        // 如果全为1 没有重叠的 数据
        let all = true;// true 没有重叠 false有重叠数据
        const result0 = [];// 所有重叠的结果
        data.forEach((v) => {
            if (v.result === 0) {
                all = false;
                result0.push(v);
            }
        });
        if (all) {
          // 全部 无重叠 直接提交
          callback && callback({ ...p, selectionFields: JSON.stringify(selectionFields) });
        } else {
          // 有重叠
          // 循环所有的 重叠数据
          const banflg = [];
          result0.forEach((v) => {
              if (ban.indexOf(v.field) > -1) {
                  banflg.push(v);
              }
          });
          if (banflg.length === 0) {
            // 重叠的 没有禁止的时间字段
            if (allow.length === 0) {
              // 没有配置允许的字段 直接提交数据
              callback && callback({ ...p, selectionFields: JSON.stringify(selectionFields) });
            } else {
              // 有配置允许的字段 提示允许的配置 点击确定可以提交数据
              let attrAllow = {};
              // eslint-disable-next-line consistent-return
              allow.forEach((v) => {
                if (v.subTitle) {
                    attrAllow = v;
                    return false;
                }
              });
              // 取配置了 提示语的提示语
              if (attrAllow.subTitle) {
                this.$confirm(attrAllow.subTitle, "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(() => {
                  callback && callback({ ...p, selectionFields: JSON.stringify(selectionFields) });
                });
              } else {
                // 都没有配置提示语 直接提交
                callback && callback({ ...p, selectionFields: JSON.stringify(selectionFields) });
              }
            }
          } else {
            // 重叠的是 禁止
            const field = banflg[0].field;
            this.$confirm(field7[field].subTitle, "提示", {
              confirmButtonText: "确定"
            });
          }
        }
      } else {
        // 不需要校验数据
        callback && callback({ ...p, selectionFields: JSON.stringify(selectionFields) });
      }
    },

    // 提交数据公共方法
    async submitData(params, button) {
      // 参考model-user-fill.js 中subdata方法
      let res;
      let cType;
      // 状态是已提交或已结束，非复制的单据，执行编辑方法
      if (this.businessData.mdData && [BillStatus.committed, BillStatus.over].includes(this.businessData.mdInfo.billStatus) && !this.copyId) {
        try {
          this.$showLoading();
          console.log(params, "修改数据");
          res = await btnService.mdEditDataInfo(params);
          cType = res?.controlType?.toString();
        } catch (error) {
          console.log(error);
          this.$hideLoading();
          const components = this.findComponentUpward(this, "BusinessDetail");
          components.validatorData = JSON.parse(error);
          return;
        }
      } else if (this.businessData.businessType !== BusinessType.pureBasic) {
        this.$showLoading();
        try {
          res = await btnService.mdSubmitData({ ...params, schemeVersionld: "", id: this.$route.query.id || "" });
          cType = res?.controlType?.toString();
          if (res.checkMessage) {
            Message.warning(res.checkMessage);
          }
        } catch (error) {
          this.$hideLoading();
          return;
        }
      } else {
        this.$showLoading();
        try {
          res = await btnService.mdSubmitBasicData(params);
        } catch (error) {
          this.$hideLoading();
          return;
        }
      }
      if (enEqual(this.businessData.businessParams.pageType, BusinessDataPageType.newPage) && this.businessData.openType === BusinessDataOpenType.add) {
        // 新开页签并且是新增时
        localStorage.setItem("businessDetail_newUserDefinedOption", JSON.stringify({
          id: res.id,
          name: JSON.parse(params.mdData).name,
          field: this.businessData.businessParams.field,
          selectKey: this.businessData.businessParams.selectKey
        }));
        localStorage.setItem("businessDetail_newCashObjectInfo", `${Math.random()}`);
        // 关联数据控件 基础数据新增
        localStorage.setItem("businessDetail_baseData", JSON.stringify(res));
        // 关联数据控件 业务建模新增
        localStorage.setItem("businessDetail_businessData", JSON.stringify(res));
        // 关闭页签
        this.handleCloseSheet();
        return;
      }
      // 记录下提交,防止路由钩子触发
      this.businessData.isSubmitted = true;
      // cType表示预算控制的类型
      if (cType === "2" || cType === "3") {
        Message.success(res.controlMessage);
        this.businessCustomJump(button, res);
      } else {
        this.businessCustomJump(button, res);
      }
    },
    // 校验基础数据人员的手机号是否存在
    async checkTenantInfoSingleByParams(params, button) {
      const par = {
        id: this.businessData.id,
        mobileNo: JSON.parse(params.mdData).mobileNo
      };
      this.$showLoading();
      const res = await btnService.checkTenantInfoSingleByParams(par);
      if (res.name) {
        this.$hideLoading();
        this.$confirm(`该人员已归属于“${res.name}”，确定添加到当前机构？`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.allowOrBan(params, (p) => {
              this.submitData(p, button);
            });
          });
      } else {
        this.allowOrBan(params, (p) => {
          this.submitData(p, button);
        });
      }
    },
    // 提交，新数据首次的提交
    btnSumbitStart(button) {
      const params = this.paramsForSubmit;
      if (this.businessData.mdInfo.basicType === "002") { // 002代表人员 001机构
        const checkData = JSON.parse(params.mdEditField);
        if (checkData.mobileNo) {
          this.checkTenantInfoSingleByParams(params, button);
        } else {
          this.allowOrBan(params, (p) => {
            this.submitData(p, button);
          });
        }
      } else {
        this.allowOrBan(params, (p) => {
          this.submitData(p, button);
        });
      }
    },
    /**
     * 行程报销
     */
    btnTripExpense() {

    },
    // 日志
    btnLog() {
      this.$refs.logDialog?.showDialog();
    },
    /**
     * 打印二维码标签
     */
    async btnQRCodeTag() {
      // API.doMdRuleByBtnKey({
      //   ids,
      //   templateId,
      //   btnKey,
      //   busType: type
      // })
      // 获取列表的表头，详情的表头字段少了
      if (!this.qrcodeColList.length) {
        const res = await API.mdListCol({
          templateId: this.templateId
        });
        this.qrcodeColList = res.colList;
      }
      this.$refs.qrcodeTagDialog?.open();
    },
    // 二维码分享
    async btnQRCodeShare() {
      const { id: dataId } = this.businessData;
      const { templateId } = this;
      const res = await API.mdShareTempInfoByCondition({
        templateId,
        dataId
      });
      const {
        url, tenantId, templateId: tempId, userId, shareCheckId, timestamp
      } = res;
      this.$refs.qrcodeDialog.show(res);
      // eslint-disable-next-line no-useless-escape
      this.qrCodeUrl = `https:\/\/h5.softheluo.com\/?t=${tenantId}&m=${tempId}&u=${userId}&s=${shareCheckId}&p=${timestamp}&sid=${dataId}`;
    },
    /**
     * @description: 自定义按钮方法
     * @item :当前按钮信息
     */
    async btnCustom(item) {
      const { ruleIds, funcType } = item;
      let has = false;
      let btnFunType = "";
      for (let i = 0; i < ruleIds.length; i++) {
        const item = ruleIds[i];
        if (Number(item.type) === 4) {
          has = true;
          btnFunType = Number(item.btnFunType);
        }
      }
      this.currentButton = item;
      // btnFunType 3 => 对账单  1 => 邮件
      if (btnFunType === 3 && funcType === 4) {
        this.$refs.asyncDataDialog.open();
        return;
      }
      this.customButtonProcess({
        button: item,
        templateId: this.templateId,
        selectIds: this.id,
        isFirstRequst: true,
        batch: this.batch,
        businessData: this.businessData
      });
      if (has && btnFunType === 1) { // 邮件功能
        await API.checkGenerateMailCustomBtnValid({
          recordId: this.id
        });
        const url = `/mailbox/mailSend?id=${this.id}&templateId=${item.templateId}&btnKey=${item.btnKey}&mailType=business`;
        window.open(url, "_black");
        // return;
      }
    },
    // 同步对账单
    confirmAsyncData(paramList) {
      this.customButtonProcess({
        button: this.currentButton,
        templateId: this.templateId,
        selectIds: "",
        isFirstRequst: true,
        data: {
          paramList: JSON.stringify(paramList),
          btnName: this.currentButton.btnName
        }
      });
    },
    async businessCustomJump(button, res) {
      console.log(button, res, ProcessButtonType);
      if (button.buttonType === ProcessButtonType.aginAdd) {
        this.alertToUserWithResp(res);
        // 清除数据重新添加
        this.businessComponents.init();
        this.$hideLoading();
        return;
      }
      // 详情的弹窗不处理,直接刷新当前页面
      if (this.isBusinessDialog) {
        this.alertToUserWithResp(res);
        if ((button.buttonType === ProcessButtonType.submitStart && enEqual(this.businessData.businessParams.openType, BusinessDataOpenType.add))
            || (button.buttonType === ProcessButtonType.delete && enEqual(this.businessData.businessParams.openType, BusinessDataOpenType.view))
        ) {
          // 新增,提交直接关闭弹窗
          // 关闭弹窗 或许还需要回调~~
          const dialog = this._MxFindComponentUpward(this, "BusinessDialog");
          dialog?.submitEmit();
          dialog?.close();
          this.$hideLoading();
          return;
        }
        // 其它情况暂时先刷新页面
        this.businessComponents.init();
        this.$hideLoading();
        return;
      }
      if (button.buttonType === ProcessButtonType.submitStart) {
        let buttons = [];
        this.$showLoading();
        try {
          buttons = await btnService.mdQueryBtnfunc({ templateId: this.businessData.mdInfo.templateId });
          // 提交分几种情况, 如果是编辑要读取编辑按钮的配置
          const isEdit = enEqual(this.businessData.businessParams.openType, 4);
          const isAdd = enEqual(this.businessData.businessParams.openType, 1);
          if (isEdit) {
            const buttonData = buttons.filter((val) => val.btnKey === ProcessButtonType.edit)[0];
            if (buttonData) {
              button = new ProcessButton(buttonData);
            }
          } else if (isAdd) {
            const buttonData = buttons.filter((val) => val.btnKey === ProcessButtonType.add)[0];
            if (buttonData) {
              button = new ProcessButton(buttonData);
            }
          }
        } catch {
          return;
        } finally {
          this.$hideLoading();
        }
      }
      // this.alertToUserWithResp(res);
      this.makeJump(button, res);
    },
    // 最终根据按钮进行跳转
    makeJump(button, res) {
      console.log(button, "buttonbutton");
      console.log(res, "resresres");
      const jumpPage = button && button.jumpPage;
      // 内部按钮触发页面跳转的都当做已提交数据,不再触发检测修改保存路由
      // 记录下提交,防止路由钩子触发
      if (this.businessData) {
        this.businessData.isSubmitted = true;
      }
      // 无配置,跳转列表
      if (!jumpPage || Object.keys(jumpPage).length === 0) {
        this.alertToUserWithResp(res);
        if (this.sourceType === "detail") {
          if (button.buttonType === ProcessButtonType.draft) {
            // 暂存无配置,刷新当前页
            this.jumpToDetail(this.templateId, res?.id, this.businessData.businessType);
            return;
          }
          // 列表处理下,不能直接返回
          const source = this.businessData.businessParams.sourcetype;
          if (source && source.indexOf("list") === 0) {
            // 只有列表来的才有这个特殊逻辑
            const path = {
              list_normal: "/businessModel/list",
              list_resources: "/businessModel/resources",
              list_calendar: "/businessModel/calendar",
              list_gantt: "/businessModel/gantt"
            }[source] || "/businessModel/list";
            console.log(source, this.$route.query, "????????????");
            this.$router.replace({
              path,
              query: {
                templateId: this.templateId || this.$route.query.templateId,
                templateName: this.templateName || this.$route.query.templateName,
                picType: this.$route.query.picType || "1",
                viewType: this.$route.query.viewType
              }
            });
          } else {
              this.$router.back();
            }
        } else {
          this.jumpToList(this.templateId);
        }
        // return;
      } else {
        this.customJump(button, res, false, res?.id || this.$route.query.id, this.businessData);
      }
      // if (jumpPage && jumpPage.id && jumpPage.jumpType === "2") {
      //   this.jumpToDetail(jumpPage.id, res?.id, jumpPage.templateType);
      // } else {
      //   this.jumpToList(jumpPage.id || this.templateId);
      // }
    }
  }
};
