import BaseClass from "@/tools/classes/base/BaseClass";
import ExpressFormulaAttr from "./ExpressFormulaAttr";

// 计算公式配置项
export default class ExpressFormula extends BaseClass {
  constructor(params) {
    super();
    this.bindProperties([
      // 1操作符  2字段  3常量  4日期计算  5日期取值  6日期数值加减
      "type",
      // 字段名
      "field",
      // 字段中文名
      "fieldName",
      // 操作符值   常量值  字段uuid
      "value",
      // 日期取值 和 日期计算  需要的参数
      {
        name: "attrParams",
        type: Array,
        className: ExpressFormulaAttr
      }
    ], params);
  }
}
