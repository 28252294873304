<!--
 * @Description: 映射赋值,手动填写dialog,有一些特殊处理,故新建一个弹窗
 * @Author: 梁平贤
 * @Date: 2020/11/25 09:05
 -->
<template>
  <el-dialog
    :title="buttonInfo.btnName"
    :visible="dialogVisible"
    class="mapping-business-detail-dialog"
    width="1100px"
    append-to-body
    @close="close"
  >
    <div class="advance-mapping" v-if="isAdvancedButton">
      <div class="top">
        <div class="top-placeholder" style="margin-left: 20px">
          <span style="font-weight: bold; font-size:12px">生成条件</span>
          <span style="color: #cdcdcd;font-size:12px">(以下条件一致时允许生成)</span>
        </div>
        <div class="fields-content" style="margin: 20px; display: flex">
          <div class="field" v-for="field in showFieldList" :key="field.field">
            {{field.name}}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-placeholder" style="margin-left: 20px;font-weight: bold">
          字段取值
        </div>
        <detail ref="business"
                :shouldChangeLayout="false"
                :notRouteParam="copiedBusinessPrams"
                :exsitBusinessData="exsitBusinessData"
                :dataType="dialogType"></detail>
      </div>
    </div>
    <detail v-else ref="business"
            :notRouteParam="copiedBusinessPrams"
            :shouldChangeLayout="false"
            :exsitBusinessData="exsitBusinessData"
            :dataType="dialogType"></detail>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <en-button
        type="primary"
        @click="confirm"
      >确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>

import { ProcessButtonType } from "@/tools/classes/process/ProcessButton";
import { BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default {
  name: "MappingDialog",
  props: {
    // 展示的字段信息
    showFieldList: Array,
    // 按钮信息
    buttonInfo: Object,
    dialogVisible: Boolean,
    // 业务建模参数
    businessParams: Object,
    // 类型
    dialogType: {
      type: Number,
      default: 1,
      validator(val) {
        // 1: 正常走弹窗参数,请求数据(businessParams)
        // 2: 外部传入业务建模数据
        return [1, 2].indexOf(val) !== -1;
      }
    },
    // 外部存在的业务建模数据,不需要请求接口,直接渲染
    exsitBusinessData: Object
  },
  data() {
    return {};
  },
  beforeCreate() {
    this.$options.components.detail = () => import("./index.vue");
  },
  computed: {
    // 复制下,具体防止什么问题忘记了,可能不是改变源数据的pageType?
    copiedBusinessPrams() {
      const copy = JSON.parse(JSON.stringify(this.businessParams));
      copy.pageType = BusinessDataPageType.dialog;
      return copy;
    },
    // 是否是高级功能, 高级功能要多展示很多东西
    isAdvancedButton() {
      return this.buttonInfo.buttonType === ProcessButtonType.other;
    }
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("update:dialogVisible", false);
    },
    //  提交的回调
    async confirm() {
      const businessComponents = this.$refs.business;
      const res = await businessComponents.triggerValidate(1);
      if (res) {
        const businessData = businessComponents.businessData;
        // 映射赋值的提交参数
        const params = [];
        businessData.mainInfos.forEach((column) => {
          const columnParam = column.paramsForSubmit;
          if (columnParam) {
            params.push({
              field: column.field,
              fieldType: column.fieldType,
              value: columnParam[column.field]
            });
          }
        });
        const submitData = Object.assign(businessData.businessParams.additionsData, {
          data: {
            paramList: JSON.stringify(params)
          },
          isFirstRequst: false
        });
        this.$emit("submitCallBack", submitData);
        this.dialogVisible = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .mapping-business-detail-dialog {
    font-size: 12px;
    .business-detail {
      min-height: 400px;
    }
    .top {
      .fields-content {
        .field {
          width: auto;
          height: 28px;
          font-size:12px;
          line-height: 28px;
          border: 1px solid #e8e8e8;
          color: #606060;
          padding: 0 10px;
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          border-radius: 5px;
          cursor: default;
        }
      }
    }
    & /deep/ .el-dialog__body {
      padding: 0;
      & /deep/ .detail-content {
        min-height: 400px;
      }
    }
    & /deep/ .detail-header {
      /*把业务按钮都隐藏掉*/
      display: none;
    }
    & /deep/ .model-select .right-addition{
      display: none !important;
    }
  }
</style>
