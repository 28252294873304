import * as math from "mathjs";
import dayjs from "dayjs";
import { isNumber } from "lodash";

export function getMainInfos(businessData) {
  return businessData.mainInfos;
}

export function evaluate(expression) {
  let evaluateFn = math.evaluate;
  // if (expression === 0) return null;
  if (expression.indexOf("_tc_") > -1) {
    // eslint-disable-next-line no-eval
    evaluateFn = window.eval;
    expression = expression.replaceAll("_tc_", "'");
  }
  if (Array.isArray(expression)) {
    if (expression.length === 1) {
      return expression[0];
    }
    if (expression.some((item) => item === undefined)) {
      throw Error("存在非法字段");
    }
    return math.evaluate(expression.join(""));
  }
  return evaluateFn(expression);
}

export function sum(datas) {
  return math.sum(datas.filter((item) => item).map(Number));
}
export function abs(data) {
  return math.abs(data);
}
export function systemTime() {
  return dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss");
}

/**
 * 逗号分割数据
 * @param values 公式数据
 * @param once true分割一次 false全部分割
 * @returns {[]}
 */
export function commaSplitDatas(values, once = false) {
  let valueItems = [...values];
  const groupValueItems = [];
  let arr = [];
  while (valueItems.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const obj of valueItems) {
      if (obj.value === ",") {
        const index = valueItems.indexOf(obj);
        valueItems.splice(index, 1);
        break;
      } else {
        arr.push(obj);
      }
    }
    if (arr.length > 0) {
      groupValueItems.push([...arr]);
      // eslint-disable-next-line no-loop-func
      valueItems = valueItems.filter((obj) => arr.indexOf(obj) === -1);
      if (once) {
        groupValueItems.push([...valueItems]);
        valueItems = [];
      }
      arr = [];
    }
  }
  return groupValueItems;
}

/**
 * 获取控件info所在路径，如果data存在，获取到data的路径
 * @param info 控件数据
 * @param businessData 业务建模数据
 * @param data 控件子数据
 * @returns {[]}
 */
export function getTriggerPath(info, businessData, data) {
  const parentInfos = [];
  let parentNode = info.parentNode;
  while (parentNode) {
    if (parentNode !== businessData && parentNode.parentNode !== businessData) {
      parentInfos.push(parentNode);
    }
    parentNode = parentNode.parentNode;
  }
  const triggerPath = [];
  parentInfos.reverse().forEach((item) => {
    if (item.subDetails) {
      triggerPath.push(item.field);
    } else if (item.fields) {
      const index = item.parentNode.subDetails.indexOf(item);
      triggerPath.push(index);
    }
  });
  if (data) {
    const index = info.columnValue.indexOf(data);
    triggerPath.push(info.field);
    triggerPath.push(index);
  }
  return triggerPath;
}

/**
 * 获取数据值
 * data.changePath 改变的数据路径
 * item.parentFields 父节点字段
 * @param data 所有数据，包含改变数据路径和触发数据路径
 * @param item 计算公式fields类型元素
 * @param getParentData 获取父项数据
 * @returns {(*|[])[]|*[]|*}
 */
export function getDataValue(data, item, getParentData) {
  // getParentData = true 返回数组，第一个元素为key，第二个字段为包含这个key的所有数据
  if (getParentData) {
    // 最多两级
    if (item.parentFields.length > 0) {
      const firstLevelDatas = data[item.parentFields[0]];
      if (!firstLevelDatas) {
        return [item.value, []];
      }
      if (item.parentFields.length === 1) {
        return [item.value, firstLevelDatas];
      }
      const datas = [];
      firstLevelDatas.forEach((item1) => {
        const secondLevelDatas = item1[item.parentFields[1]];
        if (!secondLevelDatas) {
          return;
        }
        secondLevelDatas.forEach((item2) => {
          datas.push({ ...item1, ...item2 });
        });
      });
      return [item.value, datas];
    }
  }
  // getParentData = false 先找最外层值，在找路径所在值
  let value = data[item.value];
  if (data?.changePath?.length > 0 && !value) {
    let temData = data;
    data.changePath.forEach((path) => {
      temData = temData[path];
      if (!Array.isArray(value) && !value) {
        value = temData[item.value];
      }
    });
  }
  return value;
}

/**
 * 获取结果值
 * @param items 表达式
 * @param data 表单数据
 * @param handleErrorValue
 * @returns {Promise<unknown[]>}
 */
export function getResultValues(items, data = {}, handleErrorValue = false, isGetParentData = false, businessData, rowIndex) {
  rowIndex = rowIndex ?? 0;
  return Promise.all(items.map((item) => new Promise((resolve, reject) => {
    let cloumn = null;
    let result;
    // if (item.parentField === "d36") {
    //   console.log(businessData);
    //   debugger;
    // }
    if (businessData) {
      cloumn = businessData?._mainInfos[0]?.findColumnByField(item.value);
    }
    // if (cloumn && !cloumn.displayable) { // 不显示的字段不参与计算
    //   result = 0;
    // } else
    if (item.parentField) {
        result = item.result(data, true);
        console.log(1313, rowIndex, result);
        result = result[1][rowIndex][result[0]];
      } else {
        result = item.result(data, isGetParentData, rowIndex);
      }

    if (Object.prototype.toString.call(result).slice(8, -1) === "Promise") {
      result.then((value) => {
        resolve(handleErrorValue ? value || 0 : value || 0);
      }).catch((error) => {
        handleErrorValue ? resolve(0) : reject(error);
      });
    } else {
      resolve(handleErrorValue ? result || 0 : result || 0);
    }
  }))).then((response) => {
    console.log(41312, response);
    console.log(41312, items);
    console.log(41312, businessData);
    return response;
  });
}

export function getMainData(mainInfos) {
  const data = {};
  mainInfos.forEach((item) => {
    const val = item.paramsForSubmit;
    if (!val) {
      return;
    }
    Object.assign(data, val);
  });
  return data;
}

export function dealResponse(resp) {
  const operList = ["+", "-", "*", "/", ">", "<", ">=", "<=", "==", "!=", "(", ")", ",", "%"];
  return resp.map((item) => {
    if (operList.includes(item)) {
      return item;
    }
    if (isNumber(item)) {
      return `[${item}][0]`;
    }
    return `['${item}'][0]`;
  });
}
