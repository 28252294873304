var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "mapping-business-detail-dialog",
      attrs: {
        title: _vm.buttonInfo.btnName,
        visible: _vm.dialogVisible,
        width: "1100px",
        "append-to-body": "",
      },
      on: { close: _vm.close },
    },
    [
      _vm.isAdvancedButton
        ? _c("div", { staticClass: "advance-mapping" }, [
            _c("div", { staticClass: "top" }, [
              _c(
                "div",
                {
                  staticClass: "top-placeholder",
                  staticStyle: { "margin-left": "20px" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "12px",
                      },
                    },
                    [_vm._v("生成条件")]
                  ),
                  _c(
                    "span",
                    { staticStyle: { color: "#cdcdcd", "font-size": "12px" } },
                    [_vm._v("(以下条件一致时允许生成)")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "fields-content",
                  staticStyle: { margin: "20px", display: "flex" },
                },
                _vm._l(_vm.showFieldList, function (field) {
                  return _c("div", { key: field.field, staticClass: "field" }, [
                    _vm._v(" " + _vm._s(field.name) + " "),
                  ])
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "content-placeholder",
                    staticStyle: {
                      "margin-left": "20px",
                      "font-weight": "bold",
                    },
                  },
                  [_vm._v(" 字段取值 ")]
                ),
                _c("detail", {
                  ref: "business",
                  attrs: {
                    shouldChangeLayout: false,
                    notRouteParam: _vm.copiedBusinessPrams,
                    exsitBusinessData: _vm.exsitBusinessData,
                    dataType: _vm.dialogType,
                  },
                }),
              ],
              1
            ),
          ])
        : _c("detail", {
            ref: "business",
            attrs: {
              notRouteParam: _vm.copiedBusinessPrams,
              shouldChangeLayout: false,
              exsitBusinessData: _vm.exsitBusinessData,
              dataType: _vm.dialogType,
            },
          }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }