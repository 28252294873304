/*
 * @Description: 审批/业务建模等通用的按钮处理等
 * @Author: 梁平贤
 * @Date: 2020/6/24 12:00
 */
import { ProcessButton, ProcessButtonType } from "./ProcessButton";
import BaseClass from "../base/BaseClass";

// 处理展示按钮,私有方法
const processShowButtons = Symbol("processShowButtons");

export default class EventEntity extends BaseClass {
  // 页面上需要展示的流程按钮
  showFlowButtons = [];

  // 页面上需要展示的业务按钮
  showBusinessButtons = [];

  btnStyle = ""

  // 所有按钮,需要屏蔽一部分才提供出去,私有属性,外部请勿调用
  _allButtons = [];

  constructor(btnList, mdBtnList, btnStyle) {
    super();
    this.btnStyle = btnStyle;
    // 全部按钮,私有方法,外部请勿直接使用
    this.bindProperties(
      [
        {
          name: "btnList",
          type: Array,
          className: ProcessButton
        },
        {
          name: "mdBtnList",
          type: Array,
          className: ProcessButton
        }],
      {
        btnList,
        mdBtnList
      }
    );

    this._allButtons = [...this.btnList || [], ...this.mdBtnList || []];
    this[processShowButtons]();
  }

  // 检查是否有这个button存在
  checkIfHasButton(buttonType) {
    return this._allButtons.some((val) => val.buttonType === buttonType);
  }

  // 创造一个沟通按钮
  creatCommunicateButton() {
    return new ProcessButton({
      btnKey: "communicate",
      buttonType: "communicate",
      icon: "20",
      btnName: "沟通",
      colour: "#4a485f"
    });
  }

  // 创造一个提交按钮, 纯基础数据没有提交按钮
  creatSubmitButton() {
    return new ProcessButton({
      btnKey: "submitStart",
      icon: "19",
      btnName: "提交"
    });
  }

  // 获取某一个button 可能返回是空
  giveMeButton(buttonType) {
    let button = null;
    this._allButtons.some((val) => {
      if (val.buttonType === buttonType) {
        button = val;
        return true;
      }
      return false;
    });
    return button;
  }

  // 处理展示按钮
  [processShowButtons]() {
    // 写死展示在底部的业务按钮
    const fixedOnTheBottomButtons = [ProcessButtonType.submitStart,
      ProcessButtonType.draft,
      ProcessButtonType.delete,
      ProcessButtonType.edit,
      ProcessButtonType.aginAdd];

    // 先拿到删除,暂存,提交,再次提交..这些按钮比较恶心,一会在btnList下一会在mdBtnList下
    const couldShowFlowButtons = this._allButtons.filter((button) => fixedOnTheBottomButtons.indexOf(button.buttonType) > -1);
    // 把这些按钮再加上流程按钮
    this.showFlowButtons = [...couldShowFlowButtons];
    this.btnList.forEach((button) => {
      // 有一些按钮是不需要在界面上展示的
      const notShowButtons = [ProcessButtonType.seeflow, ProcessButtonType.del];
      if (notShowButtons.indexOf(button.buttonType) > -1) {
        return;
      }
      // 防止加两次
      if (this.showFlowButtons.indexOf(button) === -1) {
        this.showFlowButtons.push(button);
      }
    });
    //  过滤特殊的不展示按钮
    const notShowBusinessButtons = [ProcessButtonType.collect, ProcessButtonType.comment, ProcessButtonType.giveLike, ProcessButtonType.previewPlan, ProcessButtonType.addtask];
    // 过滤已经加在右下角的按钮
    this.showBusinessButtons = this.mdBtnList.filter((button) => [...fixedOnTheBottomButtons, ...notShowBusinessButtons].indexOf(button.buttonType) === -1);
  }
}
