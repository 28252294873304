/*
 * @Description: 按钮功能实现
 * @Author: 梁平贤
 * @Date: 2020/8/18 16:32
 */

import { componentMixins } from "en-js";
import { ProcessButtonType, ProcessButton } from "@/tools/classes/process/ProcessButton";
import { enEqual } from "@/tools/compare";
import FlowButtonFunctions from "./FlowButtonFunctions";
import BusinessButtonFunctions from "./BusinessButtonFunctions";
import { businessListEntranceRoute } from "../../helper";

export default {
  mixins: [componentMixins, BusinessButtonFunctions, FlowButtonFunctions],
  computed: {
    // 业务建模组件
    businessComponents() {
      return this._MxFindComponentUpward(this, "BusinessDetail");
    },
    // 是否是弹窗
    isBusinessDialog() {
     return this.sourceType === "detail" && this.businessData && !enEqual(this.businessData.businessParams.dataType, 0);
    }
  },
  methods: {
    // 按钮事件
    async handleButtonClick(button) {
      // debugger
      // if (this.sourceType === "list" && !this.id && Number(button.funcType) !== 4 && button.ruleIds && !button.ruleIds.length) {
      //   this.$message.info("请先选择数据");
      //   return;
      // }
      // 处理下按钮
      const isProcessButton = button instanceof ProcessButton;
      if (!isProcessButton) {
        button = new ProcessButton(button);
      }

      const could = await this.checkCouldSubmit(button);
      if (!could) {
        return;
      }
      // 按钮操作
      const functionMap = {
        [ProcessButtonType.submitStart]: this.btnSumbitStart,
        [ProcessButtonType.aginAdd]: this.btnSumbitStart,
        [ProcessButtonType.draft]: this.btnDraft,
        [ProcessButtonType.agree]: this.btnAgree,
        [ProcessButtonType.flowDataSave]: this.btnFlowDataSave,
        [ProcessButtonType.disagree]: this.btnDisAgree,
        [ProcessButtonType.submit]: this.btnAgree,
        [ProcessButtonType.reject]: this.btnReject,
        [ProcessButtonType.rebut]: this.btnRebut,
        [ProcessButtonType.propose]: this.btnPropose,
        [ProcessButtonType.proposeTo]: this.btnProposeTo,
        [ProcessButtonType.signed]: this.btnSigned,
        [ProcessButtonType.getback]: this.btnGetBack,
        [ProcessButtonType.stop]: this.btnStop,
        [ProcessButtonType.wfcustom]: this.wfBtnCustom,
        [ProcessButtonType.edit]: this.businessEdit,
        [ProcessButtonType.delete]: this.businessDelete,
        [ProcessButtonType.print]: this.btnPrint,
        [ProcessButtonType.log]: this.btnLog,
        [ProcessButtonType.custom]: this.btnCustom,
        [ProcessButtonType.other]: this.btnAdvance,
        [ProcessButtonType.share]: this.btnShare,
        [ProcessButtonType.addtask]: this.btnAddTask,
        [ProcessButtonType.invoice]: this.btnInvoice,
        [ProcessButtonType.copy]: this.btnCopy,
        [ProcessButtonType.tripExpense]: this.btnTripExpense,
        [ProcessButtonType.QRCodeTag]: this.btnQRCodeTag,
        [ProcessButtonType.QRCodeShare]: this.btnQRCodeShare
      };

      const method = functionMap[button.buttonType];
      if (method) {
        method(button);
      }
    },
    // 不同的按钮校验不一样
    async checkCouldSubmit(button) {
      if (this.sourceType !== "detail") {
        return true;
      }
      // 触发校验
      const shouldAllCheckTypes = [ProcessButtonType.submitStart,
        ProcessButtonType.aginAdd,
        ProcessButtonType.agree,
        ProcessButtonType.submit];
      const shouldInputCheckTypes = [
        ProcessButtonType.signed,
        ProcessButtonType.propose,
        ProcessButtonType.proposeTo,
        ProcessButtonType.disagree
      ];
      let shouldCheck = false;
      let checkType = 1;
      if (shouldAllCheckTypes.indexOf(button.buttonType) !== -1) {
        //  触发全部校验
        checkType = 1;
        shouldCheck = true;
      } else if (shouldInputCheckTypes.indexOf(button.buttonType) !== -1) {
        // 触发输入限制校验
        checkType = 2;
        shouldCheck = true;
      }
      if (shouldCheck) {
        const res = await this.businessComponents.triggerValidate(checkType);
        return res;
      }
      return true;
    },
    // 跳转详情的通用方法
    jumpToDetail(templateId = this.templateId, id = this.id, businessType = this.businessType, displayWindow = 1) {
      const routeParams = {
        templateId,
        id,
        businessType
      };
      let route = {};
      if (templateId === this.templateId && id === this.id && this.sourceType === "detail") {
        // 相同模板,相同数据并且是在详情才特殊处理刷新下页面
        // this.businessComponents.init();
        route = businessListEntranceRoute({ ...routeParams, openType: 2 });
      } else {
        // 相同模板不同数据 或者 不同模板
        route = businessListEntranceRoute(routeParams);
      }
      if (Number(displayWindow) === 2) { // 在新窗口打开
        this.jumpToList();
        this.openNewPage(route);
        return;
      }
      this.$router.replace(route);
    },
    // 跳转列表的通用方法
    jumpToList(templateId = this.templateId, templateName = "", displayWindow = 1) {
      const route = {
        path: "/businessModel/list",
        query: {
          templateId,
          templateName
        }
      };
      if (templateId === this.templateId && this.sourceType === "list") {
        // 相同模板,相同列表页才特殊处理刷新下页面
        // setTimeout(() => {
        if (Number(displayWindow) === 2) { // 新开页面
          this.openNewPage(route);
          return;
        }
        this.handleListRefresh();
        // }, 500);
      } else {
        // 其他情况都是跳转到列表页面
        if (Number(displayWindow) === 2) { // 新窗口打开
          this.openNewPage(route);
          return;
        }
        this.$router.replace(route);
      }
    },
    // 打开新页签
    openNewPage(route) {
      const routeUrl = this.$router.resolve(route);
      window.open(routeUrl.href, "_blank");
    },
    // 刷新当前页面
    refreshCurrentPage() {
      if (this.sourceType === "detail") {
        this.jumpToDetail();
      } else {
        this.jumpToList();
      }
    },
    // 提示信息
    alertToUserWithResp(res) {
      if (!this.id) {
        // 新增
        this.$message.success("操作成功");
        return;
      }
      const len = this.id.split(",").length;
      // 批量处理
      if (len > 1 && res) {
        const { successCount = len, failCount = 0, failReason } = res;
        this.$message({
          message: `操作完成，成功处理${successCount}条数据,处理失败${failCount}条`,
          type: Number(len) === Number(successCount) ? "success" : "info"
        });

        if (failReason) {
          setTimeout(() => {
            this.$message.error(failReason);
          }, 3000);
        }
      } else {
        this.$message.success("操作成功");
      }
    }
  }
};
