var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "bus-btn-share",
      attrs: {
        visible: _vm.dialogVisible,
        "append-to-body": true,
        title: "选择共享人",
        width: "1000px",
        "close-on-click-modal": false,
      },
      on: { close: _vm.closeFn },
    },
    [
      _c("en-transfer-data", {
        attrs: { config: _vm.transferSet },
        model: {
          value: _vm.selectData,
          callback: function ($$v) {
            _vm.selectData = $$v
          },
          expression: "selectData",
        },
      }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("en-button", { on: { click: _vm.save } }, [_vm._v(" 确定 ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }