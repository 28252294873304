import BaseClass from "@/tools/classes/base/BaseClass";
// 计算公式 内的 时间配置
export default class ExpressFormula extends BaseClass {
  constructor(params) {
    super();
    this.bindProperties([
      // : 类型：1-时间差(时)，2-时间年，3-时间月，4-时间日，5-时间年月，6时间年月日，7-取时间对应的星期，
      // 8-时间差剔除非工作日（时），9-当前时间         10-时间差（天）,11-时间差（分）,12-时间差剔除非工作日（天）, 13-时间差剔除非工作日（分) 14取周数 15月日 16时间加(年)17时间加(月) 18时间加(日)19时间减(年)20时间减(月) 21时间减(日) 22-时间差(月)23-时间差剔除非工作日（月)
      // 日期计算支持:1,10,11,12  日期取值支持: 2,3,4,5,6  日期取值拿field1的东西
      // 日期加减支持  15 - 21
      "selectType",
      "field1",
      "field1Name",
      "field1Uuid",
      // 0 - 数据字段 1 - 当前时间(系统时间)2 - 固定时间(管理员配置时间值时间格式：yyyy - MM - dd HH: mm: ss)
      "timeFlag1",
      // "当timeFlag1为2时传 2018-11-02 12:33:45";
      "timeVal1",
      // 当字段为区间时 1 为开始时间  2为结束时间";
      "timeSelType1",
      // 当字段为区间时 1 为开始时间  2为结束时间";
      "timeSelType2",
      "field2",
      "field2Name",
      "field2Uuid",
      // 0 - 数据字段 1 - 当前时间(系统时间)2 - 固定时间(管理员配置时间值时间格式：yyyy - MM - dd HH: mm: ss)
      "timeFlag2",
      "timeVal2"
    ], params);
  }
}
