/*
 * @Description: 流程以及业务按钮等
 * @Author: 梁平贤
 * @Date: 2020/6/24 15:11
 */

import BaseClass from "../base/BaseClass";

export const ProcessButtonType = {

  //  流程按钮  start .....
  submit: "submit", // 提交
  submitStart: "submitStart", // 顶部提交
  aginAdd: "aginAdd", // 再次添加
  agree: "agree", // 同意
  disagree: "disagree", // 不同意
  draft: "draft", // 暂存
  del: "del", // 删除
  reject: "reject", // 退回
  rebut: "rebut", // 驳回
  flowDataSave: "flowDataSave", // 流程保存
  propose: "propose", // 拟办
  proposeTo: "proposeTo", // 再次拟办
  signed: "signed", // 加签
  getback: "getback", // 收回
  seeflow: "seeflow", // 查看流程
  stop: "stop", // 终止
  wfcustom: "wfcustom", // 流程自定义按钮

  //  业务按钮  start .....
  add: "add", // 新增
  edit: "edit", // 编辑
  disable: "disable", // 停用
  enable: "enable", // 启用
  delete: "delete", // 删除
  recover: "recover", // 恢复
  export: "export", // 导出
  print: "print", // 打印
  import: "import", // 打印
  log: "log", // 日志
  share: "share", // 共享
  collect: "collect", // 收藏
  addtask: "addtask", // 新增任务
  transfer: "transfer", // 转移
  invoice: "invoice", // 开票
  previewPlan: "previewPlan", // 计息预览
  eyeSkyX: "setLetter", // 天眼查
  openDoor: "openDoor", // 魔钥开门
  returnReceipt: "returnReceipt", // 回执
  comment: "comment", // 评论
  giveLike: "giveLike", // 点赞
  copy: "copy", // 复制
  QRCodeShare: "QRCodeShare", // 分享
  tripExpense: "tripExpense", // 报销
  custom: "custom", // 自定义
  other: "other", // 其他 其实就是高级功能
  QRCodeTag: "QRCodeTag", // 二维码标签
  //  沟通是页面上自己加的
  communicate: "communicate"// 沟通
};

export class ProcessButton extends BaseClass {
  // 按钮类型
  buttonType = ProcessButtonType.submit;

  // 按钮的显示类型
  showType = "primary";

  constructor(data) {
    super();
    super.parsingData(data);
    // this.bindProperties(
    //   [
    //     "btnName", // 按钮名称
    //     "btnKey", // 按钮key
    //     "icon", // 业务按钮的图片
    //     "jumpPageType", // 流程自定义按钮跳转
    //     "isBatchData", // 批量处理 0批量  1不能批量
    //     "btnArea", // 批量 1  单条2
    //     // 自定义按钮用到的属性
    //     "actionType", // 1刷新页面 2.目标数据列表页（templateId） 3.目标数据详情页(templateId,数据targetId)
    //     "targetId", // 目标数据id
    //     "templateId", // 模板ID 当templateType=1时,这个字段有值。该字段用于业务建模类型的自定义项查询及保存使用。
    //     "templateType", // 1-基础数据 2-业务建模 3-任务数据 4-数据对象 5-内部资源
    //     "templateName", // 模板name
    //     "color", // 配置的颜色
    //     "colour"
    //   ],
    //   data
    // );
    // 处理按钮类型
    this._processButtonType();
    // 处理按钮的显示样式
    this._processButtonStyle();
    // 处理按钮的icon,规则是不统一的
    this._processButtonIcon();
  }

  // 处理按钮类型
  _processButtonType() {
    this.buttonType = ProcessButtonType[this.btnKey];
    // 三种特殊的按钮
    if (this.btnKey.indexOf("wfcustom") === 0) {
      this.buttonType = ProcessButtonType.wfcustom;
    } else if (this.btnKey.indexOf("custom") === 0) {
      this.buttonType = ProcessButtonType.custom;
    } else if (this.btnKey.indexOf("btn") === 0) {
      this.buttonType = ProcessButtonType.other;
    }
  }

  // 处理按钮的icon
  _processButtonIcon() {
    if (this.buttonType === ProcessButtonType.tripExpense) {
      this.processIcon = "baoxiaodan-wodehangcheng";
      return;
    }
    this.processIcon = `bus-btn${this.icon}_1`;
  }

  _processButtonStyle() {
    switch (this.buttonType) {
      case ProcessButtonType.submitStart:
      case ProcessButtonType.submit:
      case ProcessButtonType.edit:
      case ProcessButtonType.agree:
        this.showType = "blue";
        break;
      case ProcessButtonType.rebut:
      case ProcessButtonType.reject:
      case ProcessButtonType.stop:
      case ProcessButtonType.delete:
      case ProcessButtonType.del:
        this.showType = "red";
        break;
      case ProcessButtonType.aginAdd:
      case ProcessButtonType.getback:
      case ProcessButtonType.signed:
        this.showType = "green";
        break;
      case ProcessButtonType.draft:
      case ProcessButtonType.propose:
      case ProcessButtonType.proposeTo:
        this.showType = "white";
        break;
      case ProcessButtonType.wfcustom:
        this.showType = "custom";
        break;
      default:
        this.showType = "white";
        break;
    }
  }
}
