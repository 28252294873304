<!--
 * @Author: zoujp
 * @Date: 2020-07-24 15:59:24
 * @LastEditTime: 2020-08-15 09:42:35
 * @LastEditors: 梁平贤
 * @Description: 拟办，再拟办
-->
<template>
  <el-dialog
    :visible="dialogVisible"
    :append-to-body="true"
    :title="type === 1 ? '拟办' : '再次拟办'"
    class="bus-btn-share"
    width="1000px"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="content" style="padding: 0;" v-en-loading="loading">
      <en-transfer-data v-model="selectData" :config="transferSet">
        <template #left-content="{ data }">
          <div class="left-content-defined">
            <span class="left-post-name">{{ data.postName + "   " + data.no }}</span>
            <span class="left-name">{{ data.name }}</span>
          </div>
        </template>
        <template slot="right-title">
          <en-icon name="tishi" color="#F7BF27" size="16" style="vertical-align: middle"></en-icon>
          <span style="color:#91A1B7;font-weight: normal;vertical-align: middle">办理时按照从上往下的顺序流转，可拖动排序，最后一个必须为决策者</span>
        </template>
        <template #right-content="{ item }">
          <div style="width: 100%;">
            <div class="role" :class="{'role-dicision':item.role === 1}" @click.stop.prevent="handleRoleClick(item)">
              {{ showRoleNameWithItem(item) }}
            </div>
            <en-user-logo :user-name="item.name"
                          :image-url="item.userLogo"
                          size="30px"
                          class="logo"
            >
            </en-user-logo>
            <div class="label" style="display: inline-block">
              {{ item.name }}
            </div>
          </div>
        </template>
      </en-transfer-data>
    </div>

    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import enTransferData from "@/components/en-transfer-data";
import { btnService } from "@/api/businessModel";

// 过滤
export default {
  name: "Propose",
  components: { enTransferData },
  props: {
    businessData: Object,
    id: String,
    pId: String,
    title: {
      type: String,
      default: "拟办"
    },
    type: {
      type: Number,
      default: 1,
      validator(val) {
        // 1 拟办, 2再次拟办
        return [1, 2].indexOf(val) > -1;
      }

    },
    dialogVisible: Boolean
  },
  data() {
    return {
      loading: false,
      selectData: [],
      transferSet: {
        canSort: true,
        isMulti: true, // 是否多选
        placeholder: "自定义请求服务",
        pageSize: 10,
        selectMode: "tree",
        showHead: true, // 右侧选中的数据是否需要显示头像
        exclude: false, // 是否需要显示排除按钮
        imgPath: "userLogo", // 数据的头像承载字段
        dependData: {
          requestService: btnService, // 引入服务名称
          requestFn: "queryProposeRange", // 服务中方法
          requestParams: { pId: this.pId } // 请求参数
        }
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"])
  },
  mounted() {
    this.queryAlreadyProposeRange();
  },
  methods: {
    // 角色点击
    handleRoleClick(item) {
      if (item.disabled) {
        return;
      }
      // 决策
      let toRole = 1;
      if (item.role === 1) {
        // 参与
        toRole = 0;
      }
      this.$set(item, "role", toRole);
    },
    // 展示的角色名称
    showRoleNameWithItem(item) {
      if (item.role && item.role === 1) {
        return "决策者";
      }
      return "参与者";
    },
    // 关闭弹窗
    close() {
      this.$emit("update:dialogVisible", false);
    },

    @request(true, "loading")
    // 获取已经存在的拟办人员
    async queryAlreadyProposeRange() {
      const proposedPerson = await btnService.queryAlreadyProposeRange({ pId: this.pId }) || [];
      proposedPerson.forEach((person) => {
        if (person.status !== "001") {
          person.disabled = true;
        }
      });
      this.selectData = proposedPerson;
    },
    @request()
    async submit() {
      let params = this.selectData.map((val) => ({
        proposeUserId: val.id,
        id: val.id,
        proposeType: val.role === 1 ? "000" : "001",
        endorseType: val.role === 1 ? "000" : "001",
        status: val.status ? "000" : val.status
      }));
      params = {
        userList: JSON.stringify(params)
      };
      params = Object.assign(params, this.businessData ? this.businessData.paramsForSubmit() : {
        id: this.id,
        pId: this.pId
      });
      if (this.type === 1) {
        await btnService.mdPropose(params);
      } else {
        await btnService.mdProposeAgain(params);
      }
      this.$message.success("操作成功");
      this.$emit("proposeOk");
      this.close();
    },
    @request(false)
    /**
     * @description: 保存
     */
    save() {
      if (!this.selectData || this.selectData.length === 0) {
        this.$message("请选择拟办人员");
        return;
      }
      if (this.selectData[this.selectData.length - 1].role !== 1) {
        this.$message("最后的拟办节点必须为决策者");
        return;
      }
      this.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
  .logo {
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 3px;
  }

  .role {
    color: #3e90fe;
    float: right;
    margin-right: 100px;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
    width: 62px;
    height: 26px;
    background: #3e90fe1A;
    border-radius: 4px;
  }

  .role-dicision {
    width: 62px;
    height: 26px;
    background: #FFAD2C1A;
    border-radius: 4px;
    color: #FFAD2C;
  }

  .left-content-defined {
    width: 100%;
  }

  .left-post-name {
    color: #a6b3c5;
    float: right;
    margin-right: 200px;
  }

</style>
