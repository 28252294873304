/*
 * @Description: 通知人
 * @Author: 梁平贤
 * @Date: 2020/7/8 10:08
 */

export default class Notice {
  constructor(data) {
    // 通知类型：000-环节结束 001-流程结束
    this.notifyType = data.notifyType;
    // 通知人 [{ID,name,userlogo}]
    this.userList = data.userList;
    // 流程结束 001  环节结束 000
    this.type = data.type;
  }
}
