/*
 * @Description: file content
 * @Date: 2020-04-27 11:14:25
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-04-27 13:45:11
 */

import BaseClass from "@/tools/classes/base/BaseClass";
import TemplateExtConfig from "./TemplateExtConfig.js";

export default class BusinessTemplateExt extends BaseClass {
  constructor(data) {
    super();
    this.bindProperties([
      // 计算公式配置
      {
        name: "computeRuleList",
        type: Array,
        className: TemplateExtConfig
      },
      // 向上汇总
      "rollUpList",
      // 向下分解
      "breakDownList"
    ], data);
  }
}
