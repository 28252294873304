/*
 * @Description: 流程/权限等通用处理
 * @Author: 梁平贤
 * @Date: 2020/6/24 14:00
 */

import { enEqual } from "@/tools/compare";
import EventEntity from "./EventEntity";
import { ProcessButtonType } from "./ProcessButton";
import { BillStatus } from "./ProcessHeader";
import BaseClass from "../base/BaseClass";
import Notice from "./extension/Notice";

export default class ProcessEntity extends BaseClass {
  // 是否能编辑自由流控件
  isEditFlow;

  // 是否显示自由流控件
  isShowFlow;

  // 是否显示和编辑审批意见
  isShowComment;

  // 是否显示编辑审批意见
  approveCommentShow;

  // 是否显示通知人
  isShowNotify;

  // 4.2是否显示通知人
  isShowNotifyNew;

  // 单据状态 000-未提交  001- 待处理  002-审批中 003-审批结束  004-终止  005-暂缓
  billStatus;

  // 状态名称
  billStatusName;

  // 审批按钮 []
  btnList;

  // 业务按钮 []
  mdBtnList;

  // 0代表需要回显回执按钮，1不显示
  receiptEnable;

  // 审批概要 自己写
  approvalComment;

  // 节点ID，请求参数带了mainId才会返回
  nodeId;

  // 退回指定的节点id
  customNodeId;

  // 退回指定的用户id
  customUserId;

  // 退回名称(环节名称/人员名称)
  customName;

  // RejectType 枚举类型 V2.7需求 退回读取 1默认配置 2审核环节 3审批人员 (V4.7弃用)
  backAttr;

  // 通知人相关 Notice Class
  notice;

  // 单据数据Id
  mainId;

  // 流程Id
  pId;

  // 是否有流程 000-有流程 001-无流程
  hasFlow;

  // List选填审批人知人员可修改，列表包含值（userId，userName，userLogo，isApproval）isApproval=000时，不可删除该节点
  customList;

  constructor(data) {
    super();
    this.eventEntity = new EventEntity(data?.btnList || [], data?.mdBtnList || [], data?.btnStyle || "1");
    console.log(data, "datadatadatadatadata");
    this.parsingData(data);
    this.notice = new Notice(data.notice || {});
  }

  // 流程是否结束
  get flowComplete() {
    // 单据状态
    return this.billStatus === BillStatus.over || this.billStatus === BillStatus.terminated || this.billStatus === BillStatus.committed;
  }

  // 是否显示通知人控件
  get shouldShowNotifier() {
    if (!this.isShowNotifyNew) {
      return enEqual(this.isShowNotify, 0);
    }
    return enEqual(this.isShowNotifyNew, 0);
  }

  // 是否展示自由流控件
  get shouldShowFlow() {
    return enEqual(this.isShowFlow, 0);
  }

  // 是否能编辑自由流
  get canEditFlow() {
    return enEqual(this.isEditFlow, 0);
  }

  // 是否能编辑通知人
  get canEditNotifier() {
    return enEqual(this.isShowNotify, 0);
  }

  // 是否显示审批意见
  get shouldShowApprovalComments() {
    return enEqual(this.isShowComment, 0) && enEqual(this.approveCommentShow, 0);
  }

  // 是否显示日志
  get shouldShowLog() {
    return this.eventEntity.checkIfHasButton(ProcessButtonType.log);
  }

  // 是否显示查看流程
  get shouldShowSeeFlow() {
    return this.eventEntity.checkIfHasButton(ProcessButtonType.seeflow);
  }

  // 显示回执按钮
  get showReceiptButton() {
    return enEqual(this.receiptEnable, 0);
  }

  // 是否显示提交按钮
  get shouldShowSubmitButton() {
    return this.eventEntity.checkIfHasButton(ProcessButtonType.submitStart);
  }

  // 是否能暂存
  get canDraft() {
    return this.eventEntity.checkIfHasButton(ProcessButtonType.draft);
  }

  // 通知人提交数据
  get noticeSubmitData() {
    if (this.notice.userList && this.notice.userList.length > 0) {
      // 所有ID集合
      const ids = this.notice.userList.map((user) => user.id);
      return {
        notice: JSON.stringify({
          noticeUserIds: ids.join(",")
        })
      };
    }
    return {};
  }

  // /自由流提交数据
  get flowSubmitData() {
    if (this.customList && this.customList.length > 0) {
      return {
        customList: JSON.stringify(this.customList)
      };
    }
    return {};
  }

  // 是否有点赞评论 zjh
  get shouldShowComment() {
    return this.eventEntity.checkIfHasButton(ProcessButtonType.comment) || this.eventEntity.checkIfHasButton(ProcessButtonType.giveLike);
  }
}
