import BaseClass from "@/tools/classes/base/BaseClass";
import ExpressFormula from "./ExpressFormula.js";

export default class TemplateExtConfig extends BaseClass {
  constructor(datas) {
    super();
    this.bindProperties([
      // 1 向上汇总,2向下分解,3计算公式
      "attrType",
      "createTime",
      "id",
      // 不知道干嘛的
      "objectTypeId",
      "refSchemeId",
      // 源字段
      "srcField",
      // 0手动修改后，不在触发。1一直触发。
      "triggerRule",
      "uuid",
      // 日期取值 和 日期计算  需要的参数
      {
        name: "expressRule",
        type: Array,
        className: ExpressFormula
      }
    ], datas);
  }
}
