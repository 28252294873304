<!--
 * @Description: 通用的退回/驳回弹出框
 * @Author: 梁平贤
 * @Date: 2020/8/19 17:55
 -->
<template>
  <div>
    <el-dialog
      :visible="dialogVisible"
      title="退回"
      :close-on-click-modal="false"
      @close="closeDialog"
      :append-to-body="true"
    >
      <div class="content" v-en-loading="loading">
        <div
          class="content-node"
          v-if="options.length > 0"
        >
          <span>选择退回</span>
          <en-select
            v-model="customNode"
            placeholder="请选择"
            data-mode="data"
            value-key="nodeId"
            :data="options"
            :props="{label:'nodeName',value:'nodeId'}"
            filterable
          ></en-select>
        </div>
        <el-input
          v-model="approvalComment"
          type="textarea"
          maxlength="500"
          :rows="3"
          :placeholder="type === 'reject' ? '请输入退回意见' : '请输入驳回意见'"
        >
        </el-input>
        <en-button @click="saveClick" class="save-button">
          确定
        </en-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { request } from "en-js";
import { enService } from "@/api/en/index";

export default {
  name: "RejectDialog",
  props: {
    // 整个数据体 { businessKey(数据id), pId, backAttr, nodeId}
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "reject"
    }
  },
  data() {
    return {
      // 加载
      loading: false,
      options: [],
      approvalComment: "",
      customNode: {}
    };
  },
  mounted() {
    this.visiable = this.show;
    if (this.type === "reject") {
      this.queryFlowNodeInfo();
    }
  },
  methods: {
    @request(true, "loading")
    // 获取流程节点
    async queryFlowNodeInfo() {
      const p = {
        businessKey: this.content.businessKey,
        pId: this.content.pId,
        backAttr: this.content.backAttr,
        nodeId: this.content.nodeId
      };
      const rspData = await enService.queryFlowNodeInfo(p) || [];
      this.options = rspData;
    },
    // 确定按钮
    saveClick() {
      if (this.type === "reject") {
        this.mdReject();
      } else {
        this.rebut();
      }
    },
    // 退回
    async mdReject() {
      // 选择节点的
      if (this.approvalComment === "") {
        this.$message({
          message: "请输入退回意见",
          type: "waring"
        });
        return;
      }
      this.$showLoading();
      const p = {
        tenantId: this.content.tenantId,
        id: this.content.businessKey,
        pId: this.content.pId,
        approvalComment: this.approvalComment,
        customName: this.customNode.nodeName,
        customNodeId: this.customNode.nodeId
      };
      try {
        await enService.mdReject(p);
        this.$message.success("退回成功");
        this.$emit("rejectOrRebutOK", true);
        this.closeDialog();
      } catch {
        console.log("111");
      }
      this.$hideLoading();
    },
    // 驳回
    async rebut() {
      if (this.approvalComment === "") {
        this.$message({
          message: "请输入驳回意见",
          type: "waring"
        });
        return;
      }
      const p = {
        id: this.content.businessKey,
        pId: this.content.pId,
        customNodeId: "start",
        customUserId: "",
        businessType: this.content.businessType,
        approvalComment: this.approvalComment
      };
      this.$showLoading();
      try {
        await enService.mdReject(p);
        this.$message.success("驳回成功");
        this.$emit("rejectOrRebutOK", true);
        this.closeDialog();
      } catch {
        console.log("111");
      }
      this.$hideLoading();
    },
    // 关闭回调
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
  .content {
    padding: 0;
    position: relative;
    .content-node{
      display: flex;
      margin-bottom: 12px;
      line-height: 32px;
      .en-select {
        margin-left: 12px;
        flex: 1;
      }
    }
    .save-button {
      position:absolute;
      bottom: 12px;
      right: 0px;
      /*float: right;*/
    }
    min-height: 200px;
  }
</style>
