/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
import dayjs from "dayjs";
import { templateDataHandle } from "@/api/businessModel";
import { BusinessFieldType } from "../BusinessCommonHeader";
import {
  commaSplitDatas,
  evaluate,
  getResultValues,
  sum,
  abs,
  dealResponse
} from "./tools";
/**
 * 获取后台计算值
 * @param item 计算公式部分
 * @param templateId 模板id
 * @param id 数据id
 * @returns {Promise<*>}
 */
export function handleCalcValueFunction(item, templateId, id) {
  return templateDataHandle.queryCalcValue(templateId, id, item);
}

/**
 * 阶梯函数,
 * step函数是根据条件字段范围不同，计算结果不同。条件字段：数值、金额类型
 用法：step(条件字段)，一个条件字段
 示例：个税=step(工资)。固化函数公式=工资*参数1值-参数2值
 * @param item
 * @returns {number}
 */
export function handleStepFunction(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      if (response.length === 0) {
        reject(Error("阶梯函数配置不完整"));
        return;
      }
      const value = response[0];
      if (value.length === 0) {
        reject(Error("阶梯行数参数值无效"));
        return;
      }
      const evalRuleVal = item.evalRuleValList.filter((item) => {
        const rangeValue = item.evalCondition.split(",");
        if (rangeValue.length !== 2) {
          return false;
        }
        return evaluate(`${rangeValue[0]}<=${value}<=${rangeValue[1]}`);
      })[0];
      if (evalRuleVal) {
        const result = evaluate(`${value}*${evalRuleVal.taxRate}-${evalRuleVal.quickDeduction}`);
        resolve(result);
      } else {
        resolve(0);
      }
    });
  });
}

/**
 *  IF函数判断一个条件能否满足；如果满足返回一个值，如果不满足则返回另外一个值。
 If(逻辑表达式，“ture值”，“false值”)
 * @param item
 * @returns {number}
 */
export function handleIfFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, true, false, businessData, rowIndex).then((response) => {
      response = dealResponse(response);
      const results = response.join("").split(",");
      // eslint-disable-next-line no-eval
      const evalRuleValFn = window.eval;
      if (results.length < 3) {
        reject(Error("if函数配置不完整"));
        return;
      }
      const condition = evalRuleValFn(results[0]);
      const trueValue = evalRuleValFn(results[1]);
      const falseValue = evalRuleValFn(results[2]);

      condition ? resolve(trueValue) : resolve(falseValue);
    });
  });
}

/**
 *  SUM函数可以获取一组数值的总和。
 数组可以是一个参数，也可以是多个参数的求和。
 参数类型：数值、金额类型
 * @param item
 * @returns {number}
 */
export function handleSumFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    if (item.value.length === 0) {
      reject(Error("sum函数配置不完整"));
      return;
    }
    getResultValues(item.value, data, false, true, businessData, rowIndex).then((response) => {
      const values = [];
      response.forEach((res) => {
        if (Array.isArray(res)) {
          const val = res[1].map((item) => item[res[0]]);
          values.push(sum(val));
        } else {
          values.push(res);
        }
      });
      const strs = values.join(",").split(",");// ？？？？？？？？？？？？
      let result = 0;
      strs.forEach((str) => {
        result += evaluate(str || 0);
      });
      resolve(result);
    });
  });
/**
 * @description:
 * @param {*}
 * @return {*}
 */
/**
 * @description:
 * @param {*}
 * @return {*}
 */
}
/**
 * @description:ABSABS函数返回数字的绝对值。一个数字的绝对值是该数字不带其符号的形式。
                用法：ABS(数值或金额)
                示例：ABS(-3.3)，返回结果为：3.3
 * @param {*} item
 * @param {*} data
 * @return {*}
 */
export function handleABSFunction(item, data, formulaItem, businessData, rowIndex) {
  console.log(item, data);
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, true, false, businessData, rowIndex).then((response) => {
      const results = response.join("").split(",");
      if (results.length !== 1) {
        reject(Error("ABS函数配置出错"));
        return;
      }
      const condition = abs(results[0]);
      resolve(condition);
    });
  });
}
/**
 *  可以对数据中符合多个指定条件的一组数值进行求和。
 sumifs函数语法是：=SUMIFS(求和参数,条件1,条件2)
 sumif函数的参数如下：
 第一个参数：求和参数
 第二个参数：是求和条件，由数字、逻辑表达式等组成的判定条件。可以是1个或多个
 * @param item
 * @returns {number}
 */
export function handleSumIfsFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    if (item.value.length === 0) {
      reject(Error("配置不完整"));
      return;
    }
    const groupValueItems = commaSplitDatas(item.value, true);
    const sumItems = groupValueItems[0];
    const conditions = groupValueItems.length > 1 ? groupValueItems[1] : [];
    getResultValues(sumItems, data, false, true, businessData, rowIndex).then((response) => {
      const all = response.map((res, index) => new Promise((resolve) => {
        const sumItem = sumItems[index];
        if (sumItem.key === "fun") {
          getResultValues(conditions, data, true, false, businessData, rowIndex).then((response) => {
            const conditionStrs = response.join("").split(",");
            let result = true;
            conditionStrs.forEach((str) => {
              result = result && evaluate(str);
            });
            result ? resolve(res) : resolve(0);
          });
        } else if (Array.isArray(res)) {
          const valueDatas = res[1];
          const all = valueDatas.map((valueData) => getResultValues(
            conditions,
            { ...data, ...valueData },
            true, false, businessData, rowIndex
          ).then((response1) => {
            const conditionStrs = response1.join("").split(",");
            let result = true;
            conditionStrs.forEach((str) => {
              result = result && evaluate(str);
            });
            return result ? valueData[res[0]] : 0;
          }));
          Promise.all(all).then((response) => {
            resolve(sum(response));
          });
        } else {
          resolve(res);
        }
      }));
      Promise.all(all).then((response) => {
        resolve(evaluate(response.join("")));
      }).catch((error) => {
        reject(error);
      });
    }).catch((error) => {
      reject(error);
    });
  });
}

/**
 *  可以对数据中符合指定条件的一组数值进行求和。
 sumif函数语法是：=SUMIF(求和参数，条件)
 sumif函数的参数如下：

 第一个参数：求和参数。
 第二个参数：求和条件，由数字、逻辑表达式等组成的判定条件。
 * @param item
 * @returns {number}
 */
export function handleSumIfFunction(item, data, formulaItem, businessData, rowIndex) {
  return handleSumIfsFunction(item, data, formulaItem, businessData, rowIndex);
}

// 行累加
export function handleSumrowFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    const triggerItem = item.value[0];
    if (!triggerItem?.trigger) {
      reject(Error("行累加配置有误"));
      return;
    }
    if (triggerItem.parentFields.length === 0) {
      getResultValues([triggerItem], data, false, false, businessData, rowIndex).then((reponse) => {
        resolve(reponse);
      }).catch((error) => {
        reject(error);
      });
    } else {
      getResultValues([triggerItem], data, true, true, businessData, rowIndex).then((reponse) => {
        reponse = reponse[0];
        const field = reponse[0];
        const reponseData = reponse[1];
        const index = data.changePath[data.changePath.length - 1];
        const values = reponseData.slice(0, index + 1).map((item) => item[field]);
        resolve(sum(values));
      });
    }
  });
}

// Int
// 取整数
function intFunc(val) {
  val = Number(val);
  let v = parseInt(val, 0);
  const dot = val - v;
  if (v < 0) {
    if (dot !== 0) {
      v -= 1;
    }
  }
  return v;
}
export function handleIntFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    const triggerItem = item.value[0];
    getResultValues([triggerItem], data, false, false, businessData, rowIndex).then((reponse) => {
      resolve(intFunc(reponse));
    }).catch((error) => {
      reject(error);
    });
  });
}

// Round
// eslint-disable-next-line func-names
const toCustomFixed = function(s, d) {
  s += "";
  if (!d) d = 0;
  if (s.indexOf(".") === -1) s += ".";
  s += new Array(d + 1).join("0");
  if (new RegExp(`^(-|\\+)?(\\d+(\\.\\d{0,${ d + 1 }})?)\\d*$`).test(s)) {
    s = `0${ RegExp.$2}`; const pm = RegExp.$1; let a = RegExp.$3.length;
    let b = true;
    if (a === d + 2) {
      a = s.match(/\d/g);
      if (parseInt(a[a.length - 1]) > 4) {
        for (let i = a.length - 2; i >= 0; i--) {
          a[i] = parseInt(a[i]) + 1;
          if (a[i] === 10) {
              a[i] = 0;
              b = i !== 1;
          } else break;
        }
      }
      s = a.join("").replace(new RegExp(`(\\d+)(\\d{${ d }})\\d$`), "$1.$2");
    } if (b) s = s.substr(1);
    return (pm + s).replace(/\.$/, "");
  } return `${s }`;
};
// 隔断参数， 有多个参数的函数用到 比如 SUM函数
// eslint-disable-next-line func-names
const splitParam = function(arr) {
  const params = [[]];
  let i = 0;
  arr.forEach((item) => {
    if (item.key === "oper" && item.value === ",") {
      i += 1;
      params[i] = [];
    } else {
        params[i].push(item);
    }
  });
  return params;
};
export function handleRoundFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    const triggerItem = item.value[0];
    const params = splitParam(item.value);
    const c = params[0];
    const t = params[1];
    if (!c || !c.length) {
      console.log("ROUND:未配置数字。 源"); throw new Error("ROUND");
    }
    if (!t || !t.length) {
      console.log("ROUND:未配置位数。 源："); throw new Error("ROUND");
    }
    getResultValues([triggerItem], data, false, false, businessData, rowIndex).then((reponse) => {
      resolve(toCustomFixed(Number(reponse), Number(t[0].value)));
    }).catch((error) => {
      reject(error);
    });
  });
}

// compare函数比较两个地址之间的差值是否在数据范围内，返回是或否
export function handleCompareFunction(item, data, formulaItem, businessData, rowIndex) {
  function calcDistance(lat1, lng1, lat2, lng2) {
    const radLat1 = (lat1 * Math.PI) / 180.0;
    const radLat2 = (lat2 * Math.PI) / 180.0;
    const a = radLat1 - radLat2;
    const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
    // eslint-disable-next-line no-restricted-properties
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
    // eslint-disable-next-line no-restricted-properties
    + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s *= 6378.137; // EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return s;
  }
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      const params = splitParam(item.value);
      const c = params[0];
      const t = params[1];
      let firstAddress;
      let secondAddress;
      if ((c && c.length) && (t || t.length)) {
        // todo
      } else {
        console.log("COMPARE:参数不全。 源："); throw new Error("COMPARE");
      }
      if (!c || !c.length) {
        console.log("COMPARE:参数不全。 源："); throw new Error("COMPARE");
      } else if (c.length === 1 && c[0].key === "fields" && c[0].fieldType === BusinessFieldType.address) {
          firstAddress = response[0];
      } else {
          // 如果 缺少值 结果默认是否
          resolve("否");
      }
      if (!t || !t.length) {
        console.log("COMPARE:参数不全。 源："); throw new Error("COMPARE");
      } else if (t.length === 1 && t[0].key === "fields" && t[0].fieldType === BusinessFieldType.address) {
        secondAddress = response[2];
      } else {
        // 如果 缺少值 结果默认是否
        resolve("否");
      }
      if (firstAddress && firstAddress.lng && firstAddress.lat && secondAddress && secondAddress.lng && secondAddress.lat) {
        const s = calcDistance(firstAddress.lat, firstAddress.lng, secondAddress.lat, secondAddress.lng) * 1000;
        resolve(s <= response[4] ? "是" : "否");
      }
      resolve("否");
    }).catch((error) => {
      reject(error);
    });
  });
}

// 日期
export function handleDateFunction(item, data, formulaItem, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      response = response.filter((item) => item === ",");
      if (response.length === 0) {
        reject(Error("日期函数配置不完整"));
        return;
      }
      if (response.indexOf(undefined) !== -1 || response.indexOf("") !== -1) {
        reject(Error("日期元素填写不完整"));
        return;
      }
      let dateString = "";
      if (response.length > 3) {
        dateString = response.slice(0, 2).join("-");
        if (response.length >= 6) {
          dateString = `${response.join("-")} ${response[3]}:${response[4]}:${response[5]}`;
        } else if (response.length === 4) {
          dateString = `${response.join("-")} ${response[3]}:00:00`;
        } else if (response.length === 5) {
          dateString = `${response.join("-")} ${response[3]}:${response[4]}:00`;
        }
      } else if (response.length === 3) {
        dateString = `${response.join("-")}`;
      } else if (response.length === 2) {
        dateString = `${response.join("-")}-1`;
      } else if (response.length === 1) {
        dateString = `${response}-1-1`;
      }
      const date = dayjs(dateString);
      if (date.isValid()) {
        resolve(date.format("YYYY-MM-DD HH:mm:ss"));
      } else {
        reject(Error("填写元素合成时间无效"));
      }
    });
  });
}

/**
 * 获取日期的一部分元素值
 * @param item 公式配置部分
 * @param data 数据
 * @param type "year", "month", "day", "hour", "minute"
 * @returns {number}
 */
export function handleDateElement(item, data, type, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      let date = dayjs(response[0]);
      if (!date.isValid()) {
        date = dayjs(response.join(""));
      }
      if (!date.isValid()) {
        reject(Error(`无效时间${date}`));
        return;
      }
      if (type === "year") {
        resolve(date.year());
      } else if (type === "month") {
        resolve(date.month());
      } else if (type === "day") {
        resolve(date.date());
      } else if (type === "hour") {
        resolve(date.hour());
      } else if (type === "minute") {
        resolve(date.minute());
      } else if (type === "second") {
        resolve(date.second());
      } else {
        reject(Error(`不支持获取日期的元素${type}, 仅支持year、month、day、hour、minute`));
      }
    });
  });
}

/**
 * 处理元素时间差
 * @param item 公式配置部分
 * @param data 数据
 * @param type month, day,  hour, minute
 * @returns {number}
 */
export function handleTimeDifference(item, data, type, handleDateElement, businessData, rowIndex) {
  console.log("---===>>");
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      response = response.filter((item) => item !== ",");
      if (response.length < 2) {
        reject(Error("配置不完整"));
        return;
      }
      let startData = response[0];
      let endData = response[1];
      if (startData?.length === 0 || endData?.length === 0) {
        reject(Error("时间差需要两个元素都有值，填写完整才会计算"));
      } else {
        startData = dayjs(startData).format("YYYY-MM-DD HH:mm:ss");
        endData = dayjs(endData).format("YYYY-MM-DD HH:mm:ss");
        const startDateTemp = dayjs(startData).unix();
        const endDateTemp = dayjs(endData).unix();
        let transformNum = 0;
        if (type === "month") {
          transformNum = 60 * 60 * 24 * 30;
        } else if (type === "day") {
          transformNum = 60 * 60 * 24;
        } else if (type === "hours") {
          transformNum = 60 * 60;
        } else if (type === "minute") {
          transformNum = 60;
        } else {
          reject(Error(`不支持获取日期的元素${type}差，仅支持获取两个时间月、天、时、分的差`));
        }
        resolve(endDateTemp / transformNum - startDateTemp / transformNum);
      }
    });
  });
}
export function handleLeftStringFunction(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      const startIndex = 0;
      let endIndex = 1;
      let result;
      if (response.length === 1) {
        result = response[0].slice(startIndex, endIndex);
        resolve(result);
      } else if (response.length === 3) {
        let subLen = Number(`${response[2]}`);
        if (isNaN(subLen)) {
          reject(Error("LEFT函数第二个参数配置错误"));
        } else {
          subLen < 0 ? subLen = 0 : "";
          endIndex = startIndex + subLen;
          result = response[0].slice(startIndex, endIndex);
          resolve(result);
        }
      } else {
        reject(Error("LEFT函数配置错误"));
      }
      console.log(response);
    });
  });
}
export function handleMIDStringFunction(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      let startIndex = 0;
      let endIndex = 1;
      let result;
      let textString;
      if (response.length !== 5) {
        reject(Error("MID函数配置错误"));
      } else {
        textString = response[0];
        startIndex = Number(`${response[2]}`) - 1;
        const subLen = Number(`${response[4]}`);
        if (isNaN(startIndex) || startIndex < 0 || isNaN(subLen)) {
          reject(Error("MID函数参数配置错误"));
        } else {
          endIndex = startIndex + subLen;
          result = textString.slice(startIndex, endIndex);
          resolve(result);
        }
      }
    });
  });
}
export function handleRightStringFunction(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      let subLen;
      let result;
      if (response.length === 1) {
        result = response[0].slice(-1);
        resolve(result);
      } else if (response.length === 3) {
        subLen = Number(`${response[2]}`);
        subLen < 0 ? subLen = 0 : "";
        if (isNaN(subLen)) {
          reject(Error("Right函数第二个参数必须为正整数"));
        } else if (subLen === 0) {
          resolve("");
        } else {
          result = response[0].slice(-subLen);
          resolve(result);
        }
      } else {
        reject(Error("Right函数配置错误"));
      }
    });
  });
}
/**
 * 获取时间差
 * @param item 计算公式部分数据
 * @param data 数据
 * @param type 时间差类型类型 day hours minute
 * @returns {Promise<unknown>}
 */
export function handleAsyncTimeDifference(item, data, type, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, false, businessData, rowIndex).then((response) => {
      response = response.filter((item) => item === ",");
      if (response.length < 2) {
        reject(Error("配置不完整"));
        return;
      }
      let startData = response[1];
      let endData = response[0];
      if (startData?.length === 0 || endData?.length === 0) {
        reject(Error("填写不完整，不触发计算"));
      } else {
        startData = dayjs(startData).format("YYYY-MM-DD HH:mm:ss");
        endData = dayjs(endData).format("YYYY-MM-DD HH:mm:ss");
        templateDataHandle.calcDateTimeDifference(startData, endData, type).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  });
}
/** 数字金额大写转换(可以处理整数,小数,负数) */
function smalltoBIG(n) {
    const fraction = ["角", "分"];
    const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    const unit = [["元", "万", "亿"], ["", "拾", "佰", "仟"]];
    const head = n < 0 ? "欠" : "";
    n = Math.abs(n);
    let s = "";
    for (let i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * (10 ** i)) % 10] + fraction[i]).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);

    for (let i = 0; i < unit[0].length && n > 0; i++) {
        let p = "";
        for (let j = 0; j < unit[1].length && n > 0; j++) {
            p = digit[n % 10] + unit[1][j] + p;
            n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    return head + s.replace(/(零.)*零元/, "元").replace(/(零.)+/g, "零").replace(/^整$/, "零元整");
}

export function handleSmalltoBIGFunction(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    const triggerItem = item.value[0];
    getResultValues([triggerItem], data, false, false, businessData, rowIndex).then((reponse) => {
      resolve(smalltoBIG(reponse));
    }).catch((error) => {
      reject(error);
    });
  });
}

function unitConvert(typ) {
  if (typ == "年" || typ == "Year") {
    return "year";
  } if (typ == "季" || typ == "Season") {
    return "season";
  } if (typ == "月" || typ == "Month") {
    return "month";
  } if (typ == "日" || typ == "Day") {
    return "day";
  }
    return typ;
}

function getSeasonFromMonth(month) {
  if (["01", "02", "03"].includes(month)) {
    let mn = parseInt(month);
    return { sn: "1sn", mon: mn };
  } if (["04", "05", "06"].includes(month)) {
    let mn = parseInt(month) - 3;
    return { sn: "2sn", mon: mn };
  } if (["07", "08", "09"].includes(month)) {
    let mn = parseInt(month) - 6;
    return { sn: "3sn", mon: mn };
  } if (["10", "11", "12"].includes(month)) {
    let mn = parseInt(month) - 9;
    return { sn: "4sn", mon: mn };
  }
    return { sn: "1sn", mon: parseInt(month) };
}

// 年季月日计算
export function handleAsyncAddSubDate(item, data, handleDateElement, businessData, rowIndex) {
  return new Promise((resolve, reject) => {
    getResultValues(item.value, data, false, true, businessData, rowIndex).then((response) => {
      // response：函数配置每个参数对应值的集合数组
      response = response.filter((item) => item !== ",");

      if (response.length < 3) {
        reject(Error("函数配置不完整"));
        return;
      }

      console.log("日期转换函数参数", response);

      // 转换参数为可计算的数start
      let date1 = dayjs(response[0]).format("YYYY-MM-DD"); // 对象日期
      let month = date1.substring(5, 7);
      console.log(month);
      const sym = response[1]; // +-符号
      const n1 = Number(response[2]);
      let typ = JSON.parse(JSON.stringify(response[3])); // 第一个单位
      let day1 = ""; // 初/末/指定一天
      let n2 = "";
      let n3 = "";

      if (response.length == 5) {
        // +n月-的- 初/末/指定一天
        // addSubDate(date1,+/-,n1,单位,天)
        day1 = response[4];
      } else if (response.length == 6) {
        // +n季-的第1/2/3个月的- 初/末/指定一天
        // addSubDate(date1,+/-,n1,单位,n2(第几个月),天)
        n2 = response[5];
        if (n2 == "第一个月" || n2 == "first month") {
          n2 = 1;
        } else if (n2 == "第二个月" || n2 == "second month") {
          n2 = 2;
        } else if (n2 == "第三个月" || n2 == "third month") {
          n2 = 3;
        }

        day1 = response[6];
      } else if (response.length == 7) {
        // n年又n季-的第1/2/3个月的- 初/末/指定一天
        // addSubDate(date1,+/-,n1,typ,n2,typ2,n3,day1)
        n2 = response[5];

        n3 = response[7];
        if (n3 == "第一个月" || n3 == "first month") {
          n3 = 1;
        } else if (n3 == "第二个月" || n3 == "second month") {
          n3 = 2;
        } else if (n3 == "第三个月" || n3 == "third month") {
          n3 = 3;
        }

        day1 = response[8];
      }

      if (day1 == "最初一天" || day1 == "first day") {
        day1 = "firstd";
      } else if (day1 == "最后一天" || day1 == "last day") {
        day1 = "lastd";
      }
      // 转换参数为可计算的数end

      // 计算日期转换结果
      let newDate;
      newDate = new Date(date1);
      if (response.length === 4) {
        typ = unitConvert(typ);
        // ±n年/月/季/日
        // addSubDate(date1,+/-,n1,单位)
        if (typ == "year") {
          if (sym == "+") {
            newDate.setFullYear(newDate.getFullYear() + n1);
          } else {
            newDate.setFullYear(newDate.getFullYear() - n1);
          }
        } else if (typ == "season") {
          const lastdate = new Date(date1);
          lastdate.setMonth(Number(newDate.getMonth()) + 1);
          lastdate.setDate(0);
          lastdate.setFullYear(newDate.getFullYear());
          // 如果date1是其当月最后一天，加季节/月份后的日也要在当月最后一天
          if (newDate.getFullYear() === lastdate.getFullYear() && newDate.getMonth() === lastdate.getMonth() && newDate.getDate() === lastdate.getDate()) {
            if (sym === "+") {
              newDate.setMonth(Number(newDate.getMonth()) + 3 * n1);
            } else {
              newDate.setMonth(Number(newDate.getMonth()) - 3 * n1);
            }
            newDate.setMonth(Number(newDate.getMonth()) + 1);
            newDate.setDate(0);
            newDate.setFullYear(newDate.getFullYear());
          } else {
            if (sym === "+") {
              newDate.setMonth(Number(newDate.getMonth()) + 3 * n1);
            } else {
              newDate.setMonth(Number(newDate.getMonth()) - 3 * n1);
            }
            newDate.setDate(newDate.getDate());
            newDate.setFullYear(newDate.getFullYear());
          }
        } else if (typ === "month") {
          const lastdate = new Date(date1);
          lastdate.setMonth(Number(newDate.getMonth()) + 1);
          lastdate.setDate(0);
          lastdate.setFullYear(newDate.getFullYear());
          // 如果date1是其当月最后一天，加季节/月份后的日也要在当月最后一天
          if (newDate.getFullYear() === lastdate.getFullYear() && newDate.getMonth() === lastdate.getMonth() && newDate.getDate() === lastdate.getDate()) {
            if (sym === "+") {
              newDate.setMonth(Number(newDate.getMonth()) + n1);
            } else {
              newDate.setMonth(Number(newDate.getMonth()) - n1);
            }
            newDate.setMonth(Number(newDate.getMonth()) + 1);
            newDate.setDate(0);
            newDate.setFullYear(newDate.getFullYear());
          } else {
            if (sym === "+") {
              newDate.setMonth(Number(newDate.getMonth()) + n1);
            } else {
              newDate.setMonth(Number(newDate.getMonth()) - n1);
            }
            newDate.setDate(newDate.getDate());
            newDate.setFullYear(newDate.getFullYear());
          }
        } else if (typ === "day") {
          newDate.setDate(newDate.getDate() + n1);
          newDate.setMonth(newDate.getMonth());
          newDate.setFullYear(newDate.getFullYear());
        }
      } else if (response.length === 5) {
        // ±n月的某天
          if (day1 === "firstd") {
            newDate.setMonth(Number(newDate.getMonth()) + n1);
            newDate.setDate(1);
            newDate.setFullYear(newDate.getFullYear());
          } else if (day1 === "lastd") {
            newDate.setMonth(Number(newDate.getMonth()) + n1 + 1);
            newDate.setDate(0);
            newDate.setFullYear(newDate.getFullYear());
          } else {
            newDate.setMonth(Number(newDate.getMonth()) + n1);
            newDate.setDate(day1);
            newDate.setFullYear(newDate.getFullYear());
          }
      } else if (response.length === 6) {
        //  ±n季-的第1/2/3个月的- 初/末/指定一天
        // 识别date1所在季节，季节的第几个月
        const sm = this.getSeasonFromMonth(month);
        // 定月
        newDate.setMonth(newDate.getMonth() + (n1 - 1) * 3 + (3 - sm.mon) + n2);
        // 定日
        if (day1 === "firstd") {
          newDate.setDate(1);
        } else if (day1 === "lastd") {
          newDate.setMonth(newDate.getMonth() + 1);
          newDate.setDate(0);
        } else {
          newDate.setDate(day1);
        }
        newDate.setFullYear(newDate.getFullYear());
      } else if (response.length === 7) {
        // ±n年n季-的第1/2/3个月的- 初/末/指定一天
        // 识别date1所在季节，季节的第几个月
        const sm = this.getSeasonFromMonth(month);
        // 定月
        newDate.setMonth(newDate.getMonth() + (n2 - 1) * 3 + (3 - sm.mon) + n3);
        // 定日
        if (day1 === "firstd") {
          newDate.setDate(1);
        } else if (day1 === "lastd") {
          newDate.setMonth(newDate.getMonth() + 1);
          newDate.setDate(0);
        } else {
          newDate.setDate(day1);
        }
        // 加减年
        newDate.setFullYear(newDate.getFullYear() + n1);
      }

      if (!newDate) {
        console.log("未得出正确日期结果");
        return;
      }

      newDate = dayjs(newDate).format("YYYY-MM-DD");
      resolve(newDate);
    });
  });
}
