import dayjs from "dayjs";
import * as math from "mathjs";
import {
  getMainData, getMainInfos, sum
} from "./tools";
import { AssignmentOptions, BusinessFieldType, ConditionOperatorType } from "../BusinessCommonHeader";

// 向上汇总
export default class SummaryUpHandle {
  /**
   * 初始化
   * @param businessData 业务数据
   * @param rollUpList 向上汇总配置
   */
  constructor(businessData, rollUpList) {
    this.businessData = businessData;
    this.rollUpList = rollUpList;
    this.mainInfos = null;
    this.subItem = null;
  }

  /**
   * 处理数据
   * @param sourceInfo 触发取值操作控件数据 单选、时间、文本、数值、金额、进度、明细区、子集区、子项
   * @returns {Promise<unknown>}
   */
  handle(sourceInfo) {
    return new Promise((resolve, reject) => {
      console.log("开始向上汇总");
      // 获取需要汇总的配置
      const rollUpList = this.rollUpList.filter((item) => {
        const sources = item.source.filter((source) => {
          // 明细区增删，或者改变了对应的值
          if (source.destField === sourceInfo.field
            || source.detailField === sourceInfo.field) {
            return true;
          }
          // 子项改变，配置了子项汇总
          if (source.businessType === 2 && sourceInfo.fieldType === BusinessFieldType.subItem) {
            return true;
          }
          // 条件改变
          if (source.conditions) {
            return source.conditions.filter((item1) => item1.field === sourceInfo.field).length > 0;
          }
          return false;
        });
        return sources.length > 0;
      });
      if (rollUpList.length === 0) {
        resolve();
        console.log("没有触发汇总计算");
        console.log("汇总结束");
        return;
      }
      // 初始化必要数据
      if (!this.mainInfos) {
        this.mainInfos = getMainInfos(this.businessData);
        this.subItem = this.mainInfos.filter((item) => item.fieldType === BusinessFieldType.subItem)[0];
      }
      Promise.all(rollUpList.map((item) => this.handleSingleSummaryUpDatas(item, getMainData(this.mainInfos)))).then((response) => {
        response.forEach((item) => {
          // 全部计算完成，赋值
          const { configData, value } = item;
          const info = this.mainInfos.filter((info) => info.field === configData.srcField)[0];
          if (info) {
            info.updateValueWithOptions(value, AssignmentOptions.summary);
          }
          console.log(`${info.name} change of result: ${value}`);
        });
        console.log("汇总结束");
        resolve();
      }).catch((error) => {
        reject(error);
        console.log(error);
        console.log("汇总结束");
      });
    });
  }

  /**
   * 处理单条汇总
   * @param configData 配置数据
   * @param data 业务建模数据
   * @returns {Promise<unknown>}
   */
  handleSingleSummaryUpDatas(configData, data) {
    return new Promise((resolve) => {
      let result = 0;
      configData.source.forEach((source) => {
        if (source.businessType === 3) { // 明细
          result += this.calculateDetail(source, data);
        } else if (source.businessType === 2) { // 子项
          result += this.calculateSubitem(source, data, configData);
        }
      });
      resolve({ configData, value: result });
    });
  }

  /**
   * 汇总明细区数据
   * @param source 汇总配置
   * @param data 数据
   */
  calculateDetail(source, data) {
    const datas = data[source.detailField] || [];
    if (datas && datas.length === 0) {
      return 0;
    }
    // 过滤条件不符合的数据
    const availableDatas = datas.filter((item) => this.handleConditions(source, item));
    if (availableDatas.length === 0) {
      return 0;
    }
    const vals = availableDatas.map((item) => item[source.destField]);
    if (vals.length === 1 && vals[0] === undefined) { // fixme bug:37390 只有一行数据且值为undefined时返回空
      return undefined;
    }
    return sum(vals);
  }

  /**
   * 汇总子项数据
   * @param source 单个汇总配置
   * @param data 数据
   * @param configData 所有汇总配置
   */
  calculateSubitem(source, data, configData) {
    const datas = data[this.subItem.field];
    if (datas?.length === 0) {
      return 0;
    }
    // 过滤条件不符合的数据
    const availableDatas = datas.filter((item) => this.handleConditions(source, item));
    if (availableDatas.length === 0) {
      return 0;
    }
    // 进度条特殊处理, 返回子项进度完成的进度，不满足条件的代表没完成
    if (configData.srcFieldType === BusinessFieldType.newProgress) {
      const vals = availableDatas.map((item) => item[configData.srcField]).filter((item) => item && Number(item) >= 100);
      return math.round((vals.length * 100) / datas.length) / 100;
    }
    const vals = availableDatas.map((item) => item[configData.srcField]);
    return sum(vals);
  }

  /**
   * 判断条件是否符合
   * @param source 配置条件数据
   * @param data 判断所需数据
   */
  handleConditions(source, data) {
    if (!source.conditions) {
      return true;
    }
    if (source.conditions?.length === 0) {
      return true;
    }
    // 与或条件
    const conditionResults = [];
    source.conditions.forEach((item, index) => {
      if (conditionResults.length > 0) {
        conditionResults.push("&");
      }
      const result = this.handleSingleCondition(item, data);
      conditionResults.push(result);
      if (source.conditions.length - 1 !== index && Number(item.andOr) !== 1) {
        conditionResults.push("||");
      }
    });
    const conditionStr = conditionResults.join("");
    const conditionStrs = conditionStr.split("||");
    let result = true;
    conditionStrs.forEach((item) => {
      result = item.indexOf("false") === -1 && result;
    });
    return result;
  }

  /**
   * 处理单个条件
   * @param condition 条件
   * @param data 数据
   */
  handleSingleCondition(condition, data) {
    const fieldType = condition.fieldType;
    let value1 = data[condition.field];
    let value2 = condition.value;
    const operator = condition.operator;
    // 日期
    if (fieldType === BusinessFieldType.date) {
      if (value1?.length === 0) {
        return false;
      }
      value1 = dayjs(value1);
      value2 = dayjs(value2);
      if (!value1.isValid() || !value2.isValid()) {
        return false;
      }
      value1 = value1.unix();
      value2 = value2.unix();
      switch (operator) {
        case ConditionOperatorType.equal: return value1 === value2;
        case ConditionOperatorType.notEqual: return value1 !== value2;
        case ConditionOperatorType.greater: return value1 > value2;
        case ConditionOperatorType.greaterOrEqual: return value1 >= value2;
        case ConditionOperatorType.lessOrEqual: return value1 <= value2;
        case ConditionOperatorType.less: return value1 < value2;
      }
      return true;
    }
    // 数值、金额
    if (fieldType === BusinessFieldType.inputNumber || fieldType === BusinessFieldType.inputAmount) {
      value1 = value1 ? Number(value1) : 0;
      value2 = value2 ? Number(value2) : 0;
      switch (operator) {
        case ConditionOperatorType.equal: return value1 === value2;
        case ConditionOperatorType.notEqual: return value1 !== value2;
        case ConditionOperatorType.greater: return value1 > value2;
        case ConditionOperatorType.greaterOrEqual: return value1 >= value2;
        case ConditionOperatorType.lessOrEqual: return value1 <= value2;
        case ConditionOperatorType.less: return value1 < value2;
      }
      return true;
    }
    // 单选
    if (fieldType === BusinessFieldType.select) {
      value1 = value1 ? value1.map((item) => item.id)[0] : "";
      value2 = value2 || "";
      switch (operator) {
        case ConditionOperatorType.notContainIn: return value1?.length === 0 || value2.indexOf(value1) === -1;
        case ConditionOperatorType.containIn: return value1.length > 0 && value2.indexOf(value1) !== -1;
        case ConditionOperatorType.empty: return value1?.length === 0;
        case ConditionOperatorType.notEmpty: return value1?.length > 0;
      }
      return true;
    }
    // 文本、多行文本
    if (fieldType === BusinessFieldType.inputCommon || fieldType === BusinessFieldType.multiText) {
      value1 = value1 || "";
      value2 = value2 || "";
      switch (operator) {
        case ConditionOperatorType.equal: return value1 === value2;
        case ConditionOperatorType.notEqual: return value1 !== value2;
        case ConditionOperatorType.contain: return value1.indexOf(value2) !== -1;
        case ConditionOperatorType.notContain: return value1.indexOf(value2) === -1;
        case ConditionOperatorType.empty: return value1?.length === 0;
        case ConditionOperatorType.notEmpty: return value1?.length > 0;
      }
      return true;
    }
    return true;
  }
}
