import store from "@/store";
import { templateDataHandle } from "@/api/businessModel";
import { AssignmentOptions, BusinessFieldType } from "../BusinessCommonHeader";
import { getMainInfos } from "./tools";

// 业务建模关联取值
export default class AssociatedDataHandle {
  /**
   *初始化
   * @param businessData 所有业务建模数据
   * @param relationSet 关联取值配置
   */
  constructor(businessData, relationSet) {
    this.businessData = businessData;
    this.allRelationSet = relationSet;

    this.relationSet = null;
    this.sourceInfo = null;
    this.mainInfos = null;
  }

  /**
   * 处理数据，部分关联取值
   * @param sourceInfo 触发取值操作控件数据 金额、文本、数值、单选、时间
   * @returns {Promise<unknown>}
   */
  handle(sourceInfo, rowIndex) {
    return new Promise((resolve) => {
      if (!sourceInfo) {
        resolve();
        return;
      }
      if (!this.mainInfos) {
        this.mainInfos = getMainInfos(this.businessData);
      }

      this.sourceInfo = sourceInfo;

      this.relationSet = this.allRelationSet.filter((item) => item?.relationCondition?.filter((item) => item.targetField === sourceInfo.field).length > 0);
      this.handleRelationSet(rowIndex).then(() => {
        resolve();
      });
    });
  }

  /**
   * 处理所有关联取值配置, 新增加载模板时调用一次
   * @returns {Promise<unknown>}
   */
  handleAll() {
    return new Promise((resolve, reject) => {
      this.relationSet = this.allRelationSet;
      this.handleRelationSet().then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**
   * 获取关联取值配置->拿到数据->赋值->完成
   */
  handleRelationSet() {
    return new Promise((resolve) => {
      if (this.relationSet.length === 0) {
        resolve();
        return;
      }

      const queryDatas = this.getQueryDatas().filter((item) => Object.values(item.condition).indexOf(null) === -1);
      if (queryDatas.length === 0) {
        resolve();
        return;
      }
      Promise.all(queryDatas.map(async(item) => {
        // 获取数据
        const response = await templateDataHandle.queryFisrtAssociatedData(item.relationSet, item.condition, this.businessData.mdInfo.templateId);
        console.log(22222222, response);
        if (response) {
          item.data = response;
          resolve({ queryData: item, data: response });
        } else {
          resolve({ queryData: item });
        }
      })).then(() => {
        // 赋值处理
        queryDatas.forEach((item) => {
          const { data } = item;
          if (!data) {
            return;
          }
          item.relationSet.mappingRule.forEach((mappingRule) => {
            const info = item.infos.filter((item) => item.field === mappingRule.targetField)[0];
            if (!info) {
              return;
            }
            const value = this.getMappingValue(mappingRule, item.data[mappingRule.sourceField]);
            info.updateValueWithOptions(value, AssignmentOptions.associatedData);
          });
        });
        resolve();
      });
    });
  }

  /**
   * 转换映射处理后的值
   * @param mappingRule
   * @param value
   * @returns {null|*}
   */
  getMappingValue(mappingRule, value) {
    if (!value) {
      return null;
    }
    if (mappingRule.targetFieldType === BusinessFieldType.multiText
      || mappingRule.targetFieldType === BusinessFieldType.inputCommon) {
      if (mappingRule.sourceFieldType === BusinessFieldType.inputAmount
        || mappingRule.sourceFieldType === BusinessFieldType.inputNumber) {
        value = String(value);
      } else if (mappingRule.sourceFieldType === BusinessFieldType.select
        || mappingRule.sourceFieldType === BusinessFieldType.multiSelect
        || mappingRule.sourceFieldType === BusinessFieldType.superior) {
        value = value.map((item) => item.name).join(",");
      } else if (mappingRule.sourceFieldType === BusinessFieldType.address) {
        const val = [];
        if (value.provinceName) {
          val.push(value.provinceName);
        }
        if (value.cityName) {
          val.push(value.cityName);
        }
        if (value.districtName) {
          val.push(value.districtName);
        }
        if (value.address) {
          val.push(value.address);
        }
        value = val.join(" ");
      } else if (mappingRule.sourceFieldType === BusinessFieldType.date) {
        // value = value.split(",")[0];
      }
    }
    return value;
  }

  /**
   * 更新需要取值的数据，根据配置获取需要更新的区域
   */
  getQueryDatas() {
    const queryDatas = [];
    this.relationSet.forEach((relationSet) => {
      // 主表区
      if (relationSet.selAreaType === 1) {
        queryDatas.push(this.createQueryData(relationSet, this.mainInfos));
      }
      // 明细区
      if (relationSet.selAreaType === 2) {
        const detailInfo = this.mainInfos.filter((item) => item.field === relationSet.selAreaField)[0];
        if (detailInfo) {
          detailInfo.subDetails.forEach((item) => {
            queryDatas.push(this.createQueryData(relationSet, [...this.mainInfos, ...item.fields]));
          });
        }
      }
      // 子集区
      if (relationSet.selAreaType === 3) {
        const detailInfo = this.mainInfos.filter((item) => item.field === relationSet.selAreaParentField)[0];
        if (detailInfo) {
          detailInfo.subDetails.forEach((item1) => {
            const subsetInfo = item1.fields.filter((item1) => item1.field === relationSet.selAreaField)[0];
            if (subsetInfo) {
              subsetInfo.subDetails.forEach((item2) => {
                queryDatas.push(this.createQueryData(relationSet, [...this.mainInfos, item2.fields, item1.fields]));
              });
            }
          });
        }
      }
    });
    return queryDatas;
  }

  /**
   * 关联条件数据
   * @param relationSet
   */
  createQueryData(relationSet, infos) {
    const condition = {};
    const mdData = this.businessData.mdData.originData;
    const userInfo = store.getters.userInfo;
    relationSet.relationCondition.forEach((relationCondition) => {
      const targetField = relationCondition.targetField;
      if (targetField === "createId") {
        if (mdData[targetField]) {
          condition[targetField] = [{ id: mdData[targetField], name: mdData.createName }];
        } else if (userInfo) {
          condition[targetField] = [{ id: userInfo.userId, name: userInfo.name }];
        }
      } else if (targetField === "createDepId") {
        if (mdData[targetField]) {
          condition[targetField] = [{ id: mdData[targetField], name: mdData.createDepName }];
        } else if (userInfo) {
          condition[targetField] = [{ id: userInfo.depatementId, name: userInfo.depatementName }];
        }
      } else if (targetField === "createOrgId") {
        if (mdData[targetField]) {
          condition[targetField] = [{ id: mdData[targetField], name: mdData.createOrgName }];
        } else if (userInfo) {
          condition[targetField] = [{ id: userInfo.orgId, name: userInfo.orgName }];
        }
      } else {
        const info = infos.filter((item) => item.field === targetField)[0];
        const data = info?.paramsForSubmit;
        if (data) {
          condition[targetField] = data[targetField];
        } else {
          condition[targetField] = null;
        }
      }
    });
    const queryData = {};
    queryData.relationSet = relationSet;
    queryData.condition = condition;
    queryData.infos = infos;
    return queryData;
  }
}
