/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-17 14:25:13
 * @LastEditTime: 2019-09-17 14:33:25
 */
export default class MDData {
  constructor(data) {
    this.originData = data;
  }

  hasValueWithField(field) {
    return Object.prototype.hasOwnProperty.call(this.originData, field);
  }

  valueWithField(field) {
    return this.originData[field];
  }
}
