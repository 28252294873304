/*
 * @Description: 固化的一块section,会包含评论/审批人/日志/通知人/审批概要等等东西
 * @Author: 梁平贤
 * @Date: 2020/7/4 15:19
 */

// import { ProcessButtonType } from "@/tools/classes/process/ProcessButton";
import { BusinessFieldType, BusinessDataOpenType } from "../../BusinessCommonHeader";
import BaseColumn from "../../columns/base/BaseColumn";
import BusinessSection from "../../section/BusinessSection";

export default function addFixedSection(target) {
  target.prototype.addFixedSection = function add() {
    console.log(this);
    const fixedArray = [];
    // 可编辑状态才加这个
    if (this.pageEdit) {
      // 审批概要
      this.mdInfo.shouldShowApprovalComments && fixedArray.push(BusinessFieldType.approvalComment);
      // 通知人
      this.mdInfo.shouldShowNotifier && fixedArray.push(BusinessFieldType.notifier);
      // 自由流
      this.mdInfo.shouldShowFlow && fixedArray.push(BusinessFieldType.flow);
    }

    // 日志信息
    this.mdInfo.shouldShowLog && fixedArray.push(BusinessFieldType.log);
    if (this.mdInfo.shouldShowSeeFlow) {
      const hasSeeFlow = !!this.sections.find((sec) => sec.areas.some((area) => area.find((field) => field.fieldType === BusinessFieldType.seeFlow)));
      !hasSeeFlow && fixedArray.push(BusinessFieldType.seeFlow);//! 这个走服务器配置了 原本不需要加，但为了兼容祖传的配置数据还是需要做特殊处理
    }
    // 回执
    if (this.openType !== BusinessDataOpenType.add && Number(this.mdInfo.receiptEnable) === 0) {
      fixedArray.push(BusinessFieldType.noticeReceipt);
    }
    // 评论 服务器数据, 并且不是未提交...这里还要改一下未提交的判断
    // if (this.isServerData && this.mdInfo && this.commentsInfo) {
    this.mdInfo.shouldShowComment && fixedArray.push(BusinessFieldType.comment);

    if (fixedArray.length === 0) {
      return undefined;
    }
    const columns = fixedArray.map((val) => BaseColumn.createFixedColumn(val));

    const section = BusinessSection.creatFixSection(columns);
    section.parentNode = this;
    return section;
  };
}
