/*
 * @Description: 提交数据
 * @Author: 梁平贤
 * @Date: 2020/7/30 11:07
 */

import { BillStatus } from "@/tools/classes/process/ProcessHeader";
import { BusinessDataOpenType } from "../../BusinessCommonHeader";

export default function submitData(target) {
  // 提交数据的
  target.prototype.paramsForSubmit = function paramsForSubmit(submitParams) {
    // 没传默认就是false 当前只用到这个参数
    const isSave = submitParams?.isSave;
    // 控件填写数据
    let params = {};
    // 控件编辑数据
    let editParams = {};
    this.mainInfos.forEach((column) => {
      params = Object.assign(params, column.paramsForSubmit);
      editParams = Object.assign(editParams, column.fieldEditInfo);
    });
    // 关联的行程id,考勤id是放在mdData里面
    params = Object.assign(params, {
      tripId: this.tripId,
      attRecordId: this.attRecordId,
      // 邮件id
      mailId: this.mailId,
      // 邮件按钮id
      mailBtnId: this.mailBtnId,
      // 任务相关
      taskSource: this.taskSource, // 这里加上后面判断 因为任务新加任务不能新加缺字段
      // taskSource: this.taskSource || this.originData.mdInfo.type, // 这里加上后面判断 因为任务新加任务不能新加缺字段
      taskSourceTemplateId: this.taskSourceTemplateId,
      taskSourceId: this.taskSourceId,

      relationSessionId: this.relationSessionId
    });
    // 需要把关联数据合进去
    if (this.relationData) {
      Object.assign(params, this.relationData);
    }
    let submitData = {
      mdData: JSON.stringify(params),
      mdEditField: JSON.stringify(editParams),
      templateId: this.mdInfo.templateId,
      id: this.id,
      mailId: this.mailId,
      mailBtnId: this.mailBtnId,
      version: this.mdInfo.version,
      submitToken: this.submitToken
    };
    // 保存类型
    submitData = Object.assign(submitData, {
      saveType: isSave ? "1" : "2"
    });
    // mdInfo的数据
    const flowParams = this.mdInfo.flowParameters;
    submitData = Object.assign(submitData, flowParams);
    return submitData;
  };

  // 接口配置关联业务建模的参数
  target.prototype.interfaceParamsForSubmit = function interfaceParamsForSubmit(interfaceId) {
    // 调用接口时根据状态确认的saveType
    let isSave = false;
    if (this.mdInfo.billStatus === BillStatus.unCommit || this.openType === BusinessDataOpenType.add) {
      isSave = true;
    }
    return {
      businessData: JSON.stringify(this.paramsForSubmit({ isSave })),
      interfaceId
    };
  };
  // 是否需要保存
  target.prototype.needSave = function needSave() {
    // 状态
    if (String(this.openType) === BusinessDataOpenType.edit && !this.isSubmitted) {
      const submitParams = this.paramsForSubmit();
      if (submitParams && submitParams.mdEditField) {
        const editInfo = JSON.parse(submitParams.mdEditField);
        if (editInfo && Object.keys(editInfo).length > 0) {
          return true;
        }
      }
    }
    return false;
  };
}
