/*
 * @Description: 业务建模详情页面用到的一些请求
 * @Author: 梁平贤
 * @Date: 2020/7/1 11:20
 */

import { businessService } from "@/api/businessModel";
import {
 BusinessType, BusinessFieldType, InterfaceType, BusinessDataOpenType
} from "../../BusinessCommonHeader";

export default function businessRequest(Target) {
  // 获取详情数据
  Target.requestDetailData = async function requestDetailData(query, options = {}) {
    // 类型强制转换下
    query.openType = String(query.openType);
    query.businessType = parseInt(query.businessType, 10);
    const requestParams = {
      templateId: query.templateId || "",
      templateName: query.templateName,
      businessType: parseInt(query.businessType, 10),
      openType: String(query.openType),
      objectTypeId: query.objectTypeId,
      objectType: query.objectType,
      tripId: query.tripId,
      tripType: query.tripType,
      mailId: query.mailId,
      mailBtnId: query.mailBtnId,
      id: query.id,
      copyId: query.copyId,
      version: query.version // 新模板编辑
    };
    // 查看，只读，编辑时不触发计算公式
    if ([BusinessDataOpenType.view, BusinessDataOpenType.readOnly].includes(query.openType)) {
      Target.prototype.notCalculate = true;
    }
    console.time("业务建模请求接口");
    console.log(BusinessDataOpenType);
    const { businessType } = requestParams;
    let detailData = {};
    if (businessType === BusinessType.pureBasic) {
      // 纯基础数据
      detailData = await businessService.pureBasicDetail(requestParams);
      detailData.mdInfo.objectTypeId = query.objectTypeId;
    } else if (businessType === BusinessType.basic) {
      // 基础数据类型业务建模
      detailData = await businessService.basicDetail(requestParams);
    } else if (businessType === BusinessType.dataObject) {
      // 数据对象
      detailData = await businessService.dataObjectDetail(requestParams);
    } else {
      // 业务建模等 资源 任务
      detailData = await businessService.detail(requestParams);
    }
    // 权限管理优化
    if (detailData.mdInfo?.billStatus === "000" || query.openType === BusinessDataOpenType.add) {
      detailData.powerList?.forEach((item) => {
        item.isShow = item.isAddShow;
      });
    }
    // params.datas = detailData;
    console.timeEnd("业务建模请求接口");
    console.time("业务建模数据处理");
    if (query.sourcetype) {
      if (query.sourcetype === "message") {
        // 消息新增 参数在query里面
        const mappingData = await businessService.messageDataMapping(query);
        detailData.mdData = Object.assign(mappingData || {}, detailData.mdData || {});
      } else if (query.sourcetype === "list_resources") {
        // 资源新增要给一些字段赋值
        if (query.resourceId) {
          const defaultData = { refResource: [{ id: query.resourceId, name: query.resourceName }], refResourceDate: query.resourceDate };
          detailData.mdData = Object.assign(defaultData, detailData.mdData || {});
        }
      }
    }
    // if (query.fillFields) {
    //   detailData.mdData = Object.assign(JSON.parse(query.fillFields), detailData.mdData || {});
    // }
    // 第一次编辑不触发计算公式
    if (query.openType === BusinessDataOpenType.edit) {
      setTimeout(() => {
        Target.prototype.notCalculate = false;
      });
    }
    // 额外参数
    const newOptions = Object.assign(options, query);
    const model = new Target(detailData, newOptions);
    // 处理可能需要的天眼查数据
    await model.querySkyEyeDataIfNeeded();
    // 处理可能需要的关联邮件
    await model.getMdRefMailList();
    console.timeEnd("业务建模数据处理");
    return model;
  };
  // 查询是否有邮件映射
  Target.prototype.getMdRefMailList = async function getMdRefMailList() {
    if (this.id) {
      const { records } = await businessService.getMdRefMailList({ id: this.id });
      if (records.length) {
        this.hasMailBtn = true;
      }
    }
  };

  // 处理天眼查的配置,修改模板样式
  Target.prototype.querySkyEyeDataIfNeeded = async function processSkyEyeDataIfNeeded() {
    const tycBtnFields = [];
    this.mdInfo?.mdBtnList?.forEach((item) => {
      if (item.btnKey === "setLetter") {
        item.ticketOpenField.forEach((itm) => {
          tycBtnFields.push(itm.field);
        });
      }
    });
    // 评估放在这里循环去获取会更好, 解耦.
    const skyEyeColumns = [];
    // 循环模板获取有无配置天眼查功能
    this.allInfos.forEach((column) => {
      if ((column.fieldType === BusinessFieldType.inputCommon || column.fieldType === BusinessFieldType.ocr)
        && column.interfaceType === InterfaceType.skyEye) {
        // 默认值赋值
        skyEyeColumns.push(column);
      }
      if (tycBtnFields.includes(column.field)) {
        column.isConnectBySkyEye = true;
        column.isConnectBySkyEyeBtn = true;
        column.skyEyeColumn = column;
      }
    });
    if (skyEyeColumns.length !== 0) {
      const interfaceIds = skyEyeColumns.map((column) => column.refInterfaceId);
      const idSet = new Set(interfaceIds);
      // 去重
      const filterInterfaceIds = Array.from(idSet);
      let skyInterfaceConfigs = {};
      for (let i = 0; i < filterInterfaceIds.length; i++) {
        const id = filterInterfaceIds[i];
        // eslint-disable-next-line no-await-in-loop
        const data = await businessService.queryInterfaceDetail({
          id,
          type: 4
        });
        // 接口请求参数里面获取到底关联了那个模板字段
        const interfaceConf = data.reqParams.find((interfaceConf) => interfaceConf.fieldNameVariable === "name");
        if (interfaceConf) {
          skyInterfaceConfigs = Object.assign(skyInterfaceConfigs, { [id]: interfaceConf.refField });
        }
      }
      // 都获取完了之后, 把关联的字段,和被关联的字段处理一下
      skyEyeColumns.forEach((sourceColumn) => {
        const connectColumn = sourceColumn.findMainAreaField(skyInterfaceConfigs[sourceColumn.refInterfaceId]);
        if (connectColumn) {
          sourceColumn.skyEyeColumn = connectColumn;
          connectColumn.isConnectBySkyEye = true;
        }
      });
    }
  };
}
