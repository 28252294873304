/*
 * @Description: 按钮功能实现
 * @Author: 梁平贤
 * @Date: 2020/8/18 16:32
 */

import { Message } from "element-ui";
import { request, componentMixins } from "en-js";
import { btnService } from "@/api/businessModel";
import { ProcessButton } from "@/tools/classes/process/ProcessButton";

export default {
  mixins: [componentMixins],
  computed: {
    // 一般情况下的提交,暂存参数saveType不一样
    paramsForSubmit() {
      if (this.businessData && this.businessData.paramsForSubmit) {
        return this.businessData.paramsForSubmit();
      }
      return {
        id: this.id,
        pId: this.pId
      };
    },
    // 业务建模组件
    businessComponents() {
      return this._MxFindComponentUpward(this, "BusinessDetail");
    }
  },
  methods: {
    // 收回公共方法
    @request()
    async btnGetBackComm(button) {
      await btnService.mdTakeBack(this.paramsForSubmit);
      this.goFlowButtonSuccess(button);
    },
    @request(false)
    // 加签
    btnSigned(button) {
      this.signerButton = button;
      this.triggerButton = button;
      this.signerDialogVisible = true;
    },
    // 收回
    btnGetBack(button) {
      this.$confirm("确认收回？收回后此单据可重新提交或审批", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.btnGetBackComm(button);
        });
    },
    // 退回
    btnReject(button) {
      this.rejectType = "reject";
      this.triggerButton = button;
      this.rejectDialogVisible = true;
    },
    // 驳回
    async btnRebut(button) {
      this.rejectType = "rebut";
      this.triggerButton = button;
      this.rejectDialogVisible = true;
    },
    // 再拟办
    btnProposeTo() {
      this.proposeType = 2;
      this.proposeDialogVisible = true;
    },
    // 拟办
    async btnPropose() {
      this.proposeType = 1;
      this.proposeDialogVisible = true;
    },
    // 保存 详情才会有
    @request()
    async btnFlowDataSave(button) {
      // 比较奇怪,要多传个businessKey=id
      const params = Object.assign(this.businessData.paramsForSubmit({ isSave: true }), {
        businessKey: this.businessData.id
      });
      const res = await btnService.mdSaveDataInFlow(params);
      this.goFlowButtonSuccess(button, res);
    },
    // 流程自定义
    @request()
    async wfBtnCustom(button) {
      // 这里必须要传btnKey,不然后端处理会出错(流程状态不对)
      const params = Object.assign(this.paramsForSubmit, {
        btnName: button.btnName,
        btnKey: button.btnKey
      });
      const res = await btnService.mdCustom(params);
      this.goFlowButtonSuccess(button, res);
    },
    @request(false)
    // 终止公共方法
      @request()
    async btnStopComm(button) {
      await btnService.detailStop(this.paramsForSubmit);
      this.goFlowButtonSuccess(button);
    },
    @request(false)
    // 终止
    btnStop(button) {
      this.$confirm("确认终止？终止后此单据将直接退出当前流程", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.btnStopComm(this.paramsForSubmit, button);
        });
    },
    // 同意
    @request()
    async btnAgree(button) {
      await btnService.mdAgree(this.paramsForSubmit);
      this.goFlowButtonSuccess(button);
    },
    @request(false)
    // 不同意
      @request()
    async btnDisAgree(button) {
      await btnService.mdDisAgree(this.paramsForSubmit);
      this.goFlowButtonSuccess(button);
    },
    @request(false)
    // 获取退回节点数据
    async queryFlowNodeInfo(par) {
      return await btnService.queryFlowNodeInfo(par) || [];
    },

    // 这块目前做的比较乱, 其实可以用协议去实现每个按钮操作之后的跳转,目前先完成功能, 也不是很复杂, 后续再重构一下.
    goFlowButtonSuccess(button, res) {
      Message.success({
        message: "操作成功",
        duration: 2000
      });

      if (this.sourceType === "toDo") {
        // 待办不会配置任何东西, 只需要处理回调就行了
        this.flowButtonProcessed();
        return;
      }

      // 弹窗不处理,直接刷新当前页面
      if (this.isBusinessDialog) {
        // console.log(this.businessComponents);
        if (this.businessComponents.businessParams.originOpenBusinessType === "theme") {
          this.businessComponents.$emit("closeDialog");
        } else {
          this.businessComponents.init();
        }
      } else {
        // 页面跳转
        this.flowButtonJump(button, res);
      }
    },
    // 流程按钮操作完成的跳转
    flowButtonJump(button, res) {
      // 如果按钮有配置触发其它按钮,那么优先触发配置的按钮
      let buttonKey = "";
      if (button.ruleIds) {
        button.ruleIds.some((val) => {
          if (val.type === "3") {
            // 功能
            buttonKey = val.valueList[0].id;
            return true;
          }
          return false;
        });
      }
      // 发票不处理
      if (buttonKey.length > 0 && buttonKey !== "invoice") {
        // 存在配置的功能按钮
        const button = new ProcessButton({
          btnKey: buttonKey,
          buttonType: buttonKey
        });
        // index.js方法,混入的
        this.handleButtonClick(button);
        // 重置业务建模
        // this.businessComponents.refreshDetail();
        // 隐藏页面下方按钮
        this.$set(this.businessData, "hideBusinessBottom", true);
        return;
      }

      /*
      jumpPage   int 选填  1:详情页 2列表
      jumpTemplateId int 跳转的业务建模id
      jumpTemplateType int 1：基础数据 2：业务建模
      res 后台返回的数据， 带跳转页面的数据id
       */
      // 跳转
      const jumpPage = button && button.jumpPage;

      // 无配置,返回
      if (!jumpPage) {
        this.$router.go(-1);
        return;
      }
      if (jumpPage && jumpPage.id && jumpPage.jumpType === "1") {
        this.jumpToDetail(jumpPage.id, res.jumpDataId, jumpPage.templateType);
      } else {
        this.jumpToList(jumpPage.id);
      }
    }
  }

};
