/*
 * @Description: 数据解析, 会解析默认值,服务器返回数据等
 * @Author: 梁平贤
 * @Date: 2020/7/13 14:23
 */

import { AssignmentOptions, BusinessDataOpenType, BusinessFieldType } from "../../BusinessCommonHeader";

export default function dataParsingIfNeeded(target) {
  // 处理数据
  target.prototype.dataParsingIfNeeded = function dataParsingIfNeeded() {
    // 设置向下向上分解字段
    this.parsingRollUpAndBreakDownDatasIfNeeded();
    // 处理单选选项控制
    this.dealSelectFieldControl();
    // 解析默认值
    this.parsingDefaultDataIfNeeded();
    // 解析服务器数据
    this.parsingServerDataIfNeeded();
    // 循环处理输入限制影响字段 如果模板上存在输入限制的字段,标记下,下次别的字段改变的时候,需要循环去触发下
    this.processInputLimitAffect();
    // 处理向上汇总,向下分解等等
  };

  // 默认值解析
  target.prototype.parsingDefaultDataIfNeeded = function parsingDefaultDataIfNeeded() {
    if (this.openType !== BusinessDataOpenType.add) {
      // 不是新增不会走这个逻辑
      return;
    }
    this.mainInfos.forEach((column) => {
      column.defaults && column.updateValueWithOptions(column.defaults, AssignmentOptions.default);
    });
  };

  // 服务器数据解析
  target.prototype.parsingServerDataIfNeeded = function parsingDefaultDataIfNeeded() {
    this.mainInfos.forEach((column) => {
      // 存在这个key才赋值
      if (this.mdData.hasValueWithField(column.field)) {
        column.updateValueWithOptions(this.mdData.valueWithField(column.field), AssignmentOptions.server);
      }
      // 字段值隐藏逻辑
      const flagKey = `${column.field}_ShowFlag`;
      if (this.mdData.hasValueWithField(flagKey)) {
        column.updateServerShowFlag(this.mdData.valueWithField(flagKey));
      }
    });
  };

  // 向上汇总,向下分解字段
  target.prototype.parsingRollUpAndBreakDownDatasIfNeeded = function parsingRollUpAndBreakDownDatasIfNeeded() {
    //  向上汇总
    this.templateHandle?.summaryUpFields?.forEach((field) => {
      const column = this.mainInfos.find((column) => column.field === field);
      if (column) {
        column.isConnectByRollup = true;
      }
    });
    //  向下分解
    this.templateHandle?.breakDownFields?.forEach((field) => {
      const column = this.mainInfos.find((column) => column.field === field);
      if (column) {
        column.isConnectByBreakDown = true;
      }
    });
  };

  // 循环处理输入限制影响字段 如果模板上存在输入限制的字段,标记下,下次别的字段改变的时候,需要循环去触发下
  target.prototype.processInputLimitAffect = function processInputLimitAffect() {
    // 查找有没有配置输入限制字段
    this.allInfos.some((column) => {
      if (column.inputConfig && column.inputConfig.length > 0) {
        this.shouldTriggerInputLimit = true;
        return true;
      }
      return false;
    });
  };

  // 处理单选 选项控制
  target.prototype.dealSelectFieldControl = function dealSelectFieldControl() {
    // 处理主表区单选控件
    const selectColumn = this.mainInfos.filter((column) => column.fieldType === BusinessFieldType.select);
    selectColumn.forEach((column) => {
      column.dataArea?.forEach((data) => {
        data.fieldControl?.forEach((fc) => {
          const target = column.findColumnByField(fc.field);
          if (target && Number(fc.flag) === 0) { // 显示 受控
            target.isConfigOptionShowField = true;
            target.showOptionField = false;
          }
          if (target && Number(fc.requrein) === 0) { // 必填 受控
            target.isConfigOptionRequireField = true;
            target.requireOptionField = false;
          }
        });
      });
    });
  };
}
