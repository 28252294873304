import {
  getMainInfos,
  evaluate,
  getTriggerPath,
  getDataValue,
  getResultValues,
  systemTime,
  getMainData
} from "./tools";
import {
  handleCalcValueFunction,
  handleStepFunction,
  handleIfFunction,
  handleAsyncTimeDifference,
  handleAsyncAddSubDate,
  handleDateElement,
  handleDateFunction,
  handleSumFunction,
  handleABSFunction,
  handleLeftStringFunction,
  handleMIDStringFunction,
  handleRightStringFunction,
  handleSumIfFunction,
  handleSumIfsFunction,
  handleSumrowFunction,
  handleTimeDifference,
  handleIntFunction,
  handleRoundFunction,
  handleSmalltoBIGFunction,
  handleCompareFunction
} from "./calculateFunc";
import { AssignmentOptions, BusinessFieldType } from "../BusinessCommonHeader";

// 业务建模计算公式
export default class CalculateHandle {
  /**
   * 初始化
   * @param businessData 业务数据
   * @param computeRuleList 计算公式配置
   */
  constructor(businessData, computeRuleList) {
    this.businessData = businessData;
    this.mainInfs = null;
    this.computeRuleList = computeRuleList;
    this.formulaInfos = null;
    this.fields = null;
  }

  /**
   * 处理公式元素
   */
  handleFormulaItem(formulaItem, item, rowIndex) {
    const key = item.key;
    const monitorField = formulaItem.data.monitorField;
    if (monitorField) {
      const copyMonitorField = JSON.parse(JSON.stringify(monitorField));
      copyMonitorField.value = copyMonitorField.field;
      formulaItem.triggerFields = [copyMonitorField];
    }
    if (key === "fields" || key === "statistics") {
      if (item.value === "systemTime") { // 系统当前时间
        item.result = (() => systemTime());
      } else {
        if (item.caliber?.length > 0 && ["sum", "count", "avg"].indexOf(item.caliber) !== -1) {
          item.result = (() => handleCalcValueFunction(item, this.businessData.mdInfo.templateId, this.businessData.id, this.businessData));
          formulaItem.asyncFields.push(item);
        } else {
          item.result = ((data, getParentData = false) => getDataValue(data, item, getParentData));
          if (item.parentField?.length > 0) {
            item.parentFields = item.parentField.split(".");
          } else {
            item.parentFields = [];
          }
          !monitorField ? formulaItem.triggerFields.push(item) : "";
        }
        item.trigger = true;
      }
      formulaItem.name = `${formulaItem.name}${item.text}`;
    } else if (key === "oper" || key === "constant") {
      item.result = (() => item.value);
      formulaItem.name = `${formulaItem.name}${item.value}`;
    } else if (key === "textconstant") {
      item.result = (() => `${ item.value }`);
      formulaItem.name = `${formulaItem.name}${item.value}`;
    } else if (key === "fun") {
      const name = item.name.toLowerCase();
      console.log("---===>>", name);
      formulaItem.name = `${formulaItem.name}${name}(`;
      item.value.forEach((item) => {
        this.handleFormulaItem(formulaItem, item, rowIndex);
      });
      formulaItem.name = `${formulaItem.name})`;
      if (name === "step") {
        item.result = ((data) => handleStepFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "if") {
        item.result = ((data, falg, rowIndex) => handleIfFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "sum") {
        item.result = ((data) => handleSumFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "abs") {
        item.result = ((data) => handleABSFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "sumif") {
        item.result = ((data) => handleSumIfFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "sumifs") {
        item.result = ((data) => handleSumIfsFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "date") {
        item.result = ((data) => handleDateFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (["year", "month", "day", "hour", "minute", "second"].indexOf(name) !== -1) {
        item.result = ((data) => handleDateElement(item, data, name, formulaItem, this.businessData, rowIndex));
      } else if (name === "mondif") {
        item.result = ((data) => handleTimeDifference(item, data, "month", formulaItem, this.businessData, rowIndex));
      } else if (name === "daydif") {
        item.result = ((data) => handleTimeDifference(item, data, "day", formulaItem, this.businessData, rowIndex));
      } else if (name === "houdif") {
        item.result = ((data) => handleTimeDifference(item, data, "hours", formulaItem, this.businessData, rowIndex));
      } else if (name === "mindif") {
        item.result = ((data) => handleTimeDifference(item, data, "minute", formulaItem, this.businessData, rowIndex));
      } else if (name === "wdaydif") {
        item.result = ((data) => handleAsyncTimeDifference(item, data, "day", formulaItem, this.businessData, rowIndex));
      } else if (name === "whoudif") {
        item.result = ((data) => handleAsyncTimeDifference(item, data, "hours", formulaItem, this.businessData, rowIndex));
      } else if (name === "wmindif") {
        item.result = ((data) => handleAsyncTimeDifference(item, data, "minute", formulaItem, this.businessData, rowIndex));
      } else if (name === "addsubdate") {
        // eslint-disable-next-line no-undef
        console.log("时间计算测试", item, formulaItem);
        item.result = ((data) => handleAsyncAddSubDate(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "sumrow") {
        item.result = ((data) => handleSumrowFunction(item, data, formulaItem, this.businessData, rowIndex));
        if (item.value.length > 0) {
          formulaItem.hasSumrowFunction = true;
        }
      } else if (name === "int") {
        item.result = ((data) => handleIntFunction(item, data, formulaItem, this.businessData, rowIndex));
        console.log(item);
      } else if (name === "round") {
        item.result = ((data) => handleRoundFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "upperamt") {
        item.result = ((data) => handleSmalltoBIGFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "left") {
        item.result = ((data) => handleLeftStringFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "mid") {
        item.result = ((data) => handleMIDStringFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "right") {
        item.result = ((data) => handleRightStringFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else if (name === "compare") {
        item.result = ((data) => handleCompareFunction(item, data, formulaItem, this.businessData, rowIndex));
      } else {
        item.result = (() => 0);
      }
    }
  }

  /**
   * 获取需要计算的公式
   * @param sourceInfo 改变的来源控件数据
   * @param sort 排序公式
   * @returns {null}
   */
  getHandleFormulaInfos(sourceInfo, sort = false) {
    let formulaInfos = this.formulaInfos;
    if (sourceInfo) {
      formulaInfos = formulaInfos.filter((item) => {
        const resultField = sourceInfo.findColumnByField(item.field);
        const hasManual = resultField.lastAssignment === AssignmentOptions.manual;// 判断结果字段最后一次赋值操作是不是手动
        const triggerRule = item.data.triggerRule;
        const data = item.triggerFields.filter((subItem) => {
          if (subItem.value === sourceInfo.field
            || subItem.parentField?.indexOf(sourceInfo.field) !== -1) {
            return !triggerRule ? !hasManual : true;
          }
          return false;
        });
        return data.length > 0;
      });
    }
    if (sort) {
      formulaInfos.sort((item1, item2) => {
        const groupSort1 = Number(item1.data.groupSort);
        const groupSort2 = Number(item2.data.groupSort);
        if (groupSort1 < groupSort2) {
          return 1;
        }
        if (groupSort1 === groupSort2) {
          return 0;
        }
        return -1;
      });
    }
    return formulaInfos;
  }

  initFormulaItemData(rowIndex) {
    if (this.formulaInfos) {
      return;
    }
    console.log("初始化所有公式");
    this.mainInfs = getMainInfos(this.businessData);
    const formulaInfos = [];
    const fields = [];
    this.computeRuleList.forEach((item) => {
      const formulaItem = {
        data: item,
        triggerFields: [],
        name: "",
        hasStepFunction: false,
        asyncFields: [],
        asyncData: {},
        field: item.srcField
      };
      item.expressRule.forEach((el) => {
        this.handleFormulaItem(formulaItem, el, rowIndex);
      });
      if (item.monitorField) {
        fields.push(item.monitorField.field);
      } else {
        fields.push(...formulaItem.triggerFields.map((item) => item.value));
        fields.push(...formulaItem.asyncFields.map((item) => item.value));
      }

      formulaInfos.push(formulaItem);
    });
    this.fields = fields;
    this.formulaInfos = formulaInfos;
    console.log("初始化完成");
  }

  formulaFields(rowIndex) {
    this.initFormulaItemData(rowIndex);
    return this.fields;
  }

  /**
   * 处理数据
   * @param sourceInfo 触发取值操作的控件数据 金额、文本、数值、金额、明细区、子集区、收付对象
   * @param sourceData 子数据
   */
  handle(sourceInfo, sourceData, rowIndex) {
    return new Promise((resolve) => {
      console.log("开始计算", rowIndex);
      this.initFormulaItemData(rowIndex);
      this.handleCalculateFormulas(sourceInfo, sourceData, undefined, undefined, rowIndex).then((response) => {
        resolve();
        if (!response) {
          console.log("没有触发计算公式");
        }
        console.log("结束计算");
      }).catch((error) => {
        resolve();
        console.log(`计算错误${error}`);
        console.log("结束计算");
      });
    });
  }

  /**
   * 计算多个公式
   * @param sourceInfo 影响公式的字段数据
   * @param sourceData 影响公式的字段数据子数据
   * @param sort 排序，一个公式计算完成修改目标数据后触发的其它公式需要排序计算
   * @param calculatePath 计算路径，判断死循环
   * @returns {Promise<unknown[]>}
   */
  handleCalculateFormulas(sourceInfo, sourceData, sort = false, calculatePath = [], rowIndex) {
    return new Promise((resolve, reject) => {
      let formulaInfos = this.getHandleFormulaInfos(sourceInfo, sort);
      if (calculatePath.length) {
        formulaInfos = formulaInfos.filter((item) => calculatePath.indexOf(item.field) === -1);
      }
      if (formulaInfos.length === 0) {
        resolve();
        return;
      }
      const all = formulaInfos.map((formulaItem) => new Promise((resolve, reject) => {
        this.handleSingleCalculateFormula(formulaItem, sourceInfo, sourceData, rowIndex).then((calculators) => {
          // 触发计算公式组的相关计算公式
          if (calculators.length > 0 && formulaItem.data.groupNumber?.length > 0) {
            const all = calculators.map((calculator) => this.handleCalculateFormulas(
              calculator.changeInfo,
              calculator.changeData,
              true,
              [...calculatePath, formulaItem.field], rowIndex
            ));
            Promise.all(all).then((response) => {
              resolve([...calculators, ...response]);
            }).catch((error) => {
              reject(error);
            });
          } else {
            resolve(calculators);
          }
        }).catch((error) => {
          reject(error);
        });
      }));
      console.log(1234567890, all);
      Promise.all(all).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**
   * 计算单个公式
   * @param formulaInfo 公式配置数据
   * @param sourceInfo 来源字段数据
   */
  handleSingleCalculateFormula(formulaInfo, sourceInfo, sourceData, rowIndex) {
    // 获取所有数据
    const data = getMainData(this.mainInfs);
    // 获取触发路径
    const triggerPath = getTriggerPath(sourceInfo, this.businessData, sourceData);
    // 获取计算区域
    const areaInfos = this.getCalculateAreaInfos(formulaInfo, sourceInfo, sourceData, triggerPath);
    const calculators = [];
    areaInfos.forEach((areaInfo, rowIndexnew) => {
      const calculator = { data: { ...data, triggerPath } };
      if (Array.isArray(areaInfo.infos)) {
        const info = areaInfo.infos.filter((item) => item.field === formulaInfo.field)[0];
        calculator.changeInfo = info;
      } else {
        calculator.changeInfo = areaInfo.infos.info;
        calculator.changeData = areaInfo.infos.data;
      }
      calculator.data.changePath = getTriggerPath(calculator.changeInfo, this.businessData, calculator.changeData);
      // 计算
      const nindex = rowIndex ?? rowIndexnew;
      calculator.calculate = new Promise((resolve, reject) => {
        getResultValues(formulaInfo.data.expressRule, calculator.data, false, false, this.businessData, nindex).then((response) => {
          const value = evaluate(response);
          console.log(`11111==>>"${formulaInfo.name}" of result: ${value}===>>>${rowIndex}`);
          // 修改对应值
          if (calculator.changeData) {
            calculator.changeData[formulaInfo.field] = value;
          } else {
            calculator.changeInfo.updateValueWithOptions(value, AssignmentOptions.calculate);
          }
          resolve(calculator);
        }).catch((error) => {
          reject(error);
          console.log(`"${formulaInfo.name}" of result: ${error}`);
        });
      });
      calculators.push(calculator);
    });

    return Promise.all(calculators.map((item) => item.calculate));
  }

  /**
   * 获取计算区域
   * @param formulaInfo 公式
   * @param sourceInfo 触发计算公式业务建模控件信息
   * @param sourceData 触发计算公式来源数据
   * @param triggerPath 触发字段的路径
   */
  getCalculateAreaInfos(formulaInfo, sourceInfo, sourceData, triggerPath) {
    if (!formulaInfo.parentFields) {
      formulaInfo.parentFields = {};
      const infos = this.mainInfs.filter((item) => item.field === formulaInfo.field);
      if (infos.length > 0) {
        formulaInfo.parentFields = { area: "main", parent: [] };
      } else if (formulaInfo.field === "money") {
        const cashObject = this.mainInfs.filter((item) => item.fieldType === BusinessFieldType.cashObject)[0];
        if (cashObject) {
          formulaInfo.parentFields = { area: "cashObject", parent: [cashObject.field] };
        }
      } else {
        const detailInfos = this.mainInfs.filter((item) => item?.originData?.sub?.length > 0);
        detailInfos.forEach((item1) => {
          if (item1.originData.sub.map((item) => item.field).indexOf(formulaInfo.field) !== -1) {
            formulaInfo.parentFields = { area: "detail", parent: [item1.field] };
          } else {
            const subSetInfos = item1.originData.sub.filter((item) => item?.originData?.sub?.length > 0);
            subSetInfos.forEach((item2) => {
              if (item2.originData.sub.map((item) => item.field).indexOf(formulaInfo.field) !== -1) {
                formulaInfo.parentFields = { area: "detail", parent: [item1.field, item2.field] };
              }
            });
          }
        });
      }
    }
    const triggerParentFields = triggerPath.filter((item) => typeof item !== "number");
    const areaInfos = [];
    if (formulaInfo.parentFields.area === "main") { // 主表区
      areaInfos.push({ area: formulaInfo.parentFields.area, infos: [...this.mainInfs] });
    } else if (formulaInfo.parentFields.area === "cashObject") { // 收付对象
      const cashObject = this.mainInfs.filter((item) => item.field === formulaInfo.parentFields.parent[0])[0];
      const datas = sourceData ? [sourceData] : cashObject.columnValue; // 某条收付对象触发的取那条数据就好，不是的取所有
      datas.forEach((item) => {
        areaInfos.push({ area: formulaInfo.parentFields.area, infos: { info: cashObject, data: item } });
      });
    } else { // 明细区、子集
      const detailInfo = this.mainInfs.filter((item) => item.field === formulaInfo.parentFields.parent[0])[0];
      const subDetails = [];
      if (formulaInfo.parentFields.parent.length === 1) { // 明细区 在某条明细中触发的且没有行累加函数取这条明细，否则计算所有明细区
        if (triggerParentFields.length === 1
          && triggerParentFields[0] === formulaInfo.parentFields.parent[0]
          && !formulaInfo.hasSumrowFunction) {
          subDetails.push(...detailInfo.subDetails.filter((item) => item.fields.indexOf(sourceInfo) !== -1));
        } else {
          subDetails.push(...detailInfo.subDetails);
        }
      } else { // 子集
        detailInfo.subDetails.forEach((subDetail) => {
          const subsetInfo = subDetail.fields.filter((item) => item.field === formulaInfo.parentFields.parent[0])[0];
          if (triggerParentFields.length === 1
            && triggerParentFields[0] === formulaInfo.parentFields.parent[0]) { // 明细区中触发，计算目标在子集区，获取这条明细的所有子集区计算
            if (subDetail.fields.indexOf(sourceInfo) !== -1) {
              subDetails.push(...subsetInfo.subDetails);
            }
          } else if (triggerParentFields.length === 2
            && triggerParentFields[0] === formulaInfo.parentFields.parent[0]
            && triggerParentFields[1] === formulaInfo.parentFields.parent[1]
            && !formulaInfo.hasSumrowFunction) { // 子集区触发 在某条子集中触发的且没有行累加函数取这条子集，否则计算所有子集区
            subDetails.push(...subsetInfo.subDetails.filter((item) => item.fields.indexOf(sourceInfo) !== -1).length > 0);
          } else {
            subDetails.push(...subsetInfo.subDetails);
          }
        });
      }
      subDetails.forEach((item) => {
        areaInfos.push({ area: formulaInfo.parentFields.area, infos: [...item.fields] });
      });
    }
    return areaInfos;
  }
}
