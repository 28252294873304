var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "bus-btn-share",
      attrs: {
        visible: _vm.dialogVisible,
        "append-to-body": true,
        title: _vm.type === 1 ? "拟办" : "再次拟办",
        width: "1000px",
        "close-on-click-modal": false,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
          staticStyle: { padding: "0" },
        },
        [
          _c(
            "en-transfer-data",
            {
              attrs: { config: _vm.transferSet },
              scopedSlots: _vm._u([
                {
                  key: "left-content",
                  fn: function ({ data }) {
                    return [
                      _c("div", { staticClass: "left-content-defined" }, [
                        _c("span", { staticClass: "left-post-name" }, [
                          _vm._v(_vm._s(data.postName + " " + data.no)),
                        ]),
                        _c("span", { staticClass: "left-name" }, [
                          _vm._v(_vm._s(data.name)),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "right-content",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "role",
                              class: { "role-dicision": item.role === 1 },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleRoleClick(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.showRoleNameWithItem(item)) +
                                  " "
                              ),
                            ]
                          ),
                          _c("en-user-logo", {
                            staticClass: "logo",
                            attrs: {
                              "user-name": item.name,
                              "image-url": item.userLogo,
                              size: "30px",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "label",
                              staticStyle: { display: "inline-block" },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectData,
                callback: function ($$v) {
                  _vm.selectData = $$v
                },
                expression: "selectData",
              },
            },
            [
              _c(
                "template",
                { slot: "right-title" },
                [
                  _c("en-icon", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: { name: "tishi", color: "#F7BF27", size: "16" },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#91A1B7",
                        "font-weight": "normal",
                        "vertical-align": "middle",
                      },
                    },
                    [
                      _vm._v(
                        "办理时按照从上往下的顺序流转，可拖动排序，最后一个必须为决策者"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("en-button", { on: { click: _vm.save } }, [_vm._v(" 确定 ")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }