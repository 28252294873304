import { templateDataHandle } from "@/api/businessModel";
import AssociatedDataHandle from "./associatedDataHandle";
import CalculateHandle from "./calculateHandle";
import SummaryUpHandle from "./summaryUpHandle";

export default class TemplateHandle {
  constructor(businessData) {
    this.businessData = businessData;
    const templateExt = businessData.mdInfo.templateExt || {};
    // 初始化关联取值处理实体
    if (templateExt.relationSet?.length > 0) {
      this.associatedDataHandle = new AssociatedDataHandle(businessData, templateExt.relationSet);
    }
    // 初始化计算处理实体, 只支持新版计算公式
    if (templateExt.computeRuleList?.length > 0) {
      const computeRuleList = templateExt?.computeRuleList.filter((item) => Number(item.ruleVersion) >= 2);
      if (computeRuleList.length > 0) {
        this.calculateHandle = new CalculateHandle(businessData, computeRuleList);
      }
    }
    // 初始化监听计算实体
    if (templateExt.monitorCalculateRuleList?.length > 0) {
      const monitorCalculateRuleList = templateExt?.monitorCalculateRuleList.filter((item) => Number(item.ruleVersion) >= 2);
      if (monitorCalculateRuleList.length > 0) {
        this.monitorCalculateHandle = new CalculateHandle(businessData, monitorCalculateRuleList);
      }
    }
    // 向下分解
    if (templateExt.breakDownList?.length > 0) {
      this.breakDownFields = templateExt.breakDownList.map((item) => item.srcField);
    }
    // 向上汇总
    if (templateExt.rollUpList?.length > 0) {
      this.summaryUpFields = templateExt.rollUpList.map((item) => item.srcField);
      this.summaryUpHandle = new SummaryUpHandle(businessData, templateExt.rollUpList);
    }
  }

  // 执行计算公式计算
  calculateExecute(sourceInfo, sourceData, rowIndex) {
    return this.calculateHandle?.handle(sourceInfo, sourceData, rowIndex);
  }

  // 执行监听字段计算
  monitorCalculateExecute(sourceInfo, sourceData, rowIndex) {
    return this.monitorCalculateHandle?.handle(sourceInfo, sourceData, rowIndex);
  }

  // 执行关联取值
  associatedDataExecute(sourceInfo) {
    return this.associatedDataHandle?.handle(sourceInfo);
  }

  // 执行所有关联取值
  associatedDataAllExecute(sourceInfo) {
    return this.associatedDataHandle?.handleAll(sourceInfo);
  }

  // 执行汇总
  summaryUpExecute(sourceInfo) {
    return this.summaryUpHandle?.handle(sourceInfo);
  }

  // 查看汇总分解明细
  querySumaryResolveDetail(field) {
    return templateDataHandle.querySummaryOrResolveDetail(this.businessData.mdInfo.templateId, this.businessData.id, field);
  }
}
