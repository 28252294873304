<!--
 * @Description: 按钮所需的弹窗,打印等东西,都挂载在这个上面, 目前会把所有跳转都处理掉. 如果后续需要扩充功能,可以考虑把这些东西都做成协议, 交给各自去实现.
 * @Author: 梁平贤
 * @Date: 2020/9/17 16:35
 -->
 <template>
  <div>
    <!-- 分享 -->
    <share
      :id="id"
      v-if="shareDialogVisible"
      :dialog-visible.sync="shareDialogVisible"
      :dataSource="dataSource"
    ></share>
    <!-- 打印 -->
    <en-print
      v-if="(templateId && businessData) || batchPrintIds"
      ref="print"
      :templateId="templateId"
      :detailId="id || batchPrintIds || ''"
      :batchType="!!batchPrintIds"
      :powerList="businessData && businessData.powerList"
      :businessData="businessData"
    ></en-print>

    <!-- 映射赋值弹窗 -->
    <mappingDialog
      v-if="buttonEventMappingDialog"
      :dialogType="2"
      :exsitBusinessData="buttonEventMappingBusinessData"
      :businessParams="buttonEventMappingBusinessData.businessParams"
      :buttonInfo="mappingButton"
      :showFieldList="mappingShowFieldList"
      @submitCallBack="mappingSubmit"
      :dialogVisible.sync="buttonEventMappingDialog"
    ></mappingDialog>
    <!-- 退回驳回弹窗 -->
    <RejectDialog
      v-if="rejectDialogVisible"
      :type="rejectType"
      :dialogVisible.sync="rejectDialogVisible"
      :content="rejectContentParams"
      @rejectOrRebutOK="flowButtonProcessed"
    >
    </RejectDialog>
    <!-- 加签弹窗 -->
    <signerDialog
      v-if="signerDialogVisible"
      :businessData="businessData"
      :button="signerButton"
      :id="id"
      :p-id="pId"
      :dialogVisible.sync="signerDialogVisible"
      @signOk="flowButtonProcessed"
    ></signerDialog>
    <!-- 拟办弹窗 -->
    <propose
      v-if="proposeDialogVisible"
      :businessData="businessData"
      :id="id"
      :p-id="pId"
      :type="proposeType"
      :dialogVisible.sync="proposeDialogVisible"
      @proposeOk="flowButtonProcessed"
    ></propose>
    <!-- 二维码标签 -->
    <BusinessQrcodeTagDialog
      ref="qrcodeTagDialog"
      :qrcodeTags="qrcodeTags"
      :businessData="qrcodeDataSource"
    ></BusinessQrcodeTagDialog>
    <!-- 二维码 -->
    <qrcodeDialog
      ref="qrcodeDialog"
      :data-id="businessData.id"
      :url="qrCodeUrl"
    ></qrcodeDialog>
    <!-- 同步对账单 -->
    <async-data-dialog
      ref="asyncDataDialog"
      @confirmHandle="confirmAsyncData"
    ></async-data-dialog>
    <BusinessLogDialog
      ref="logDialog"
      :template-id="businessData.templateId"
      :id="id"
      :data-type="businessData.businessType"
    ></BusinessLogDialog>
  </div>
</template>

<script>
import enPrint from "@/components/en-print";
import mappingDialog from "@/components/businessDetail/mappingDialog";
import RejectDialog from "@/components/RejectDialog";
import { ProcessButtonType } from "@/tools/classes/process/ProcessButton";
import share from "./components/share.vue";
import buttonFunctions from "./index";
import signerDialog from "./components/signerDialog";
import propose from "./components/propose";
import asyncDataDialog from "./components/asyncDataDialog";

export default {
  name: "businessFunction",
  mixins: [buttonFunctions],
  components: {
    share,
    enPrint,
    mappingDialog,
    RejectDialog,
    signerDialog,
    propose,
    asyncDataDialog
  },
  methods: {
    // 触发按钮操作入口111
   async triggerButtonFunction(button, batchPrint, len, dataSource = {}) {
      this.dataSource = JSON.parse(JSON.stringify(dataSource));
      if (len && len > 0) {
        // 批量打印
        this.batchPrintIds = batchPrint.split(",");
        this.templateId = button.templateId;
      }
      // 等待外面赋值的属性同步再处理
      this.$nextTick(() => {
        this.handleButtonClick(button);
      });
    },
    // 映射赋值回调
    mappingSubmit(params) {
      if (params.button.buttonType === ProcessButtonType.custom) {
        this.customButtonProcess(params);
      } else {
        this.advanceButtonProcess(params);
      }
    },
    // 回调列表页面刷新
    handleListRefresh() {
      this.$emit("handleListRefresh");
    },
    // 审批按钮处理完的回调, 一般是回退, 待办那儿自己处理刷新
    flowButtonProcessed(isFlowButton) {
      if (isFlowButton) {
        // triggerButton为点击的按钮
        this.flowButtonJump(this.triggerButton);
      } else if (this.sourceType === "toDo" || this.sourceType === "chat") {
        this.$emit("flowButtonProcessed");
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    // 退回参数
    rejectContentParams() {
      return {
        businessKey: this.id,
        pId: this.pId
        // backAttr: this.businessData.mdInfo.backAttr, // fixme: 这两个属性需要修改?
        // nodeId: this.businessData.mdInfo.nodeId
      };
    },
    findMainAreaFieldByUuid() {
      return this.businessData._mainInfos[0].findMainAreaFieldByUuid;
    },
    // 二维码标签数据源
    qrcodeTags() {
      if (this.sourceType === "detail") {
        return [
          Object.assign(this.businessData.mdData.originData, {
            btnList: this.businessData.mdInfo.mdBtnList,
            id: this.businessData.id
          })
        ];
      }
      return [];
    },
    // 二维码标签数据源
    qrcodeDataSource() {
      if (this.sourceType === "detail") {
        return {
          colList: this.qrcodeColList,
          templateId:
            this.businessData.templateId || this.businessData.mdInfo.templateId,
          type: this.businessData.businessType
        };
      }
      return {};
    }
  },
  data() {
    return {
      batchPrintIds: "", // 批量打印ids
      // 分享弹窗
      shareDialogVisible: false,
      // 映射赋值弹窗
      buttonEventMappingDialog: false,
      // 映射赋值的按钮信息
      mappingButton: {},
      // 映射赋值显示字段
      mappingShowFieldList: Array,
      // 映射赋值一次请求处理之后的数据
      buttonEventMappingBusinessData: {},
      // 退回驳回弹窗控制
      rejectDialogVisible: false,
      // 拖回驳回类型 reject or  rebut
      rejectType: "reject",
      // 加签弹窗控制
      signerDialogVisible: false,
      // 拟办弹窗控制
      proposeDialogVisible: false,
      // 1拟办, 2再次拟办
      proposeType: 1,
      // 加签按钮
      signerButton: {},
      // 分享url
      qrCodeUrl: "",
      // 二维码标签表头
      qrcodeColList: [],
      dataSource: {}
    };
  },
  props: {
    // 可能需要的整体数据,列表以及待办可以不需要
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 列表的列头字段信息, 高级功能需要展示字段的名称
    listFields: Array,
    // 业务数据id, 详情是单个, 列表可能是多个
    id: String,
    // 是否是批量操作  默认是 否 只有列表操作的时候 顶部按钮是true zjh
    batch: {
      type: Boolean,
      default: false
    },
    // 业务类型
    businessType: String,
    // 业务建模模板id
    templateId: String,
    // 流程id
    pId: String,
    // 类型
    sourceType: {
      type: String,
      default() {
        return "detail";
      },
      validator(val) {
        // 业务详情 业务列表 待办
        return ["detail", "list", "toDo", "chat"].indexOf(val) > -1;
      }
    }

  }
};
</script>

<style scoped>
</style>
