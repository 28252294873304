/*
 * @Description: 映射赋值,创建业务建模
 * @Author: 梁平贤
 * @Date: 2020/9/8 15:52
 */
import { BusinessDataOpenType, BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default function createBPEventModel(Target) {
  // 拿到映射赋值返回的1次请求数据,生成一个业务建模
  Target.createBusinessModelWithButtonResponse = function createBusinessModelWithButtonResponse(res, additionsData) {
    // 不知道为什么reqFieldList里面的字段都是带有编码规则的,都清理下.
    res.reqFieldList.forEach((column) => {
      column.codeRule = "";
    });

    const datas = {
      fieldAttr: res.reqFieldList,
      mdInfo: {
        templateId: res.templateId,
        objTypeId: res.objectTypeId,
        businessType: res.templateType
      }
    };
    const options = {
      businessParams: {
        openType: BusinessDataOpenType.add, // 新增
        pageType: BusinessDataPageType.dialog// 弹窗
      },
      openType: BusinessDataOpenType.add, // 新增
      pageType: BusinessDataPageType.dialog, // 弹窗
      additionsData// 需要依赖弹窗传递的2次接口请求数据
    };
    return new Target(datas, options);
  };
}
