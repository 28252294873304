<!--
 * @Author: 周晴龙
 * @Date: 2020-07-18 09:24:46
 * @Description: In User Settings Edit
 * @FilePath: \user\src\components\businessDetail\mixins\buttonFunction\components\signerDialog\index.vue
-->
<template>
  <el-dialog
    title="加签人"
    :visible="dialogVisible"
    width="1000px"
    @close="close"
    class="signerDialog"
    :append-to-body="true"
  >
    <en-transfer-data v-model="selectData" :config="transferSet">
      <template slot="left-title">
        <div class="left-head">选择加签人</div>
      </template>
      <template #left-content="{ data }">
        <div class="left-content-defined">
          <span class="left-name">{{ data.name }}</span>
          <span class="left-post-name" v-if="data.postName && data.no">{{ data.postName + "   " + data.no }}</span>
        </div>
      </template>
      <template slot="right-title">
        <div class="right-head">
        <en-icon name="tishi" color="#F7BF27" size="16" style="vertical-align: middle;margin-right:6px;"></en-icon>
        <span style="color:#91A1B7;font-weight: normal;vertical-align: middle">{{rightAlertMeassage}}</span>
        </div>
      </template>
      <template #right-content="{ item }">
        <div style="width: 100%;">
          <div class="role" :class="{'role-dicision':item.role === 1}" @click.stop.prevent="handleRoleClick(item)">
            {{ showRoleNameWithItem(item) }}
          </div>
          <en-user-logo :user-name="item.name"
                        :image-url="item.userLogo"
                        size="30px"
                        class="logo"
          >
          </en-user-logo>
          <div class="label" style="display: inline-block; vertical-align: middle">
            {{ item.name }}
          </div>
        </div>
      </template>
    </en-transfer-data>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>

<script>
import { request } from "en-js";
import enTransferData from "@/components/en-transfer-data";
import { btnService } from "@/api/businessModel";

export default {
  props: {
    dialogVisible: Boolean,
    businessData: Object,
    id: String,
    pId: String,
    button: Object
  },
  components: { enTransferData },
  data() {
    return {
      selectData: [],
      selectType: "1", // 当前选择的加签类型
      transferSet: {
        pageNo: 0,
        pageSize: 20,
        isMulti: true,
        hideRight: false,
        choiceRule: false,
        exclude: false,
        imgPath: "userLogo",
        dependData: {
          areaObjType: "002"
        },
        filterObj: {
          type: "002"
        },
        canSort: false
      },
      resultList: [],
      pageModel: { // 分页
        pageSize: 20,
        pageNo: 1,
        total: null,
        totalPages: null
      }
    };
  },
  mounted() {
    // 处理默认加签类型默认选中
    if (this.signMode === 2) {
      this.selectType = "2";
    }
    if (this.signMode === 1) {
      this.transferSet.isMulti = false;
    }
  },
  watch: {
    selectData() {
      if (this.signMode === 1) {
        // 选中全变为决策者
        this.selectData.forEach((val) => {
          this.$set(val, "role", 1);
        });
      }
    }
  },
  computed: {
    // 1决策者 2参与者 0都可以
    signMode() {
      if (!this.button.signPower || this.button.signPower === "1,2") {
        return 0;
      }
      return Number(this.button.signPower);
    },
    rightAlertMeassage() {
      if (this.signMode === 0) {
        return "点击右侧标签可切换加签人角色";
      }
      if (this.signMode === 1) {
        return "只允许设置一个加签决策者";
      }
      return "可设置多个加签参与者";
    }
  },
  methods: {
    // 角色点击
    handleRoleClick(item) {
      if (item.disabled || this.signMode !== 0) {
        return;
      }
      // 决策
      let toRole = 1;
      if (item.role === 1) {
        // 参与
        toRole = 0;
      }

      // 切换决策者需要把其它都决策者置为非决策
      if (toRole === 1) {
        this.selectData.forEach((val) => {
          this.$set(val, "role", 0);
        });
      }

      this.$set(item, "role", toRole);
    },
    // 展示的角色名称
    showRoleNameWithItem(item) {
      if (item.role && item.role === 1) {
        return "决策者";
      }
      return "参与者";
    },
    save() {
      if (!this.selectData || this.selectData.length === 0) {
        this.$message("请至少选择一个加签人员");
        return;
      }
      this.submit();
    },
    @request()
    async submit() {
      let params = this.selectData.map((val) => ({
        addSignUser: val.id,
        endorseType: val.role === 1 ? "000" : "001"
      }));
      params = {
        signs: JSON.stringify(params)
      };
      console.log(this);
      params = Object.assign(params, this.businessData && this.businessData.paramsForSubmit ? this.businessData.paramsForSubmit() : {
        id: this.id,
        pId: this.pId
      });
      await btnService.mdSign(params);
      this.$message.success("操作成功");
      this.$nextTick(() => {
        this.close();
        this.$emit("signOk", true);
      });
    },
    @request(false)
    close() {
      this.$emit("update:dialogVisible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
  .logo {
    margin-left: 6px;
    margin-right: 15px;
    /*margin-top: 3px;*/
    vertical-align: middle;
  }
  .role {
    color: #3e90fe;
    float: right;
    margin-right: 100px;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
    width: 62px;
    height: 26px;
    background: #3e90fe1A;
    border-radius: 4px;
  }
  .role-dicision {
    width: 62px;
    height: 26px;
    background: #FFAD2C1A;
    border-radius: 4px;
    color:#FFAD2C;
  }
  .left-content-defined {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .left-post-name {
    color: #a6b3c5;
    margin-left: 50px;
  }
  /deep/ .en-transfer .en-transfer-panel .en-transfer-panel-title{
    padding:0;
    font-size:0;
    height:0;
    border-bottom: 0;
  }
  /deep/ .en-transfer .en-transfer-panel-body.with-search{
    height: calc(100% - 50px);
  }
  /deep/ .en-transfer .en-transfer-panel-body .el-checkbox-group{
    margin-top:46px
  }
  /deep/ .en-transfer .en-transfer-panel-body .en-transfer-panel-item{
    height:38px;
    line-height:38px;
  }
  /deep/ .en-transfer .en-transfer-panel-body .en-transfer-panel-item .el-checkbox__inner{
    margin:12px 0 0 0;
  }
  .right-head{
    background:#f6fafd;
    font-size:12px;
    border-bottom: 1px solid #E7EBF1;
    padding: 7px 20px;
  }
  .left-head{
    font-size:0;
    height:0;
  }
</style>
