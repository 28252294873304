var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "退回",
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _vm.options.length > 0
                ? _c(
                    "div",
                    { staticClass: "content-node" },
                    [
                      _c("span", [_vm._v("选择退回")]),
                      _c("en-select", {
                        attrs: {
                          placeholder: "请选择",
                          "data-mode": "data",
                          "value-key": "nodeId",
                          data: _vm.options,
                          props: { label: "nodeName", value: "nodeId" },
                          filterable: "",
                        },
                        model: {
                          value: _vm.customNode,
                          callback: function ($$v) {
                            _vm.customNode = $$v
                          },
                          expression: "customNode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "500",
                  rows: 3,
                  placeholder:
                    _vm.type === "reject" ? "请输入退回意见" : "请输入驳回意见",
                },
                model: {
                  value: _vm.approvalComment,
                  callback: function ($$v) {
                    _vm.approvalComment = $$v
                  },
                  expression: "approvalComment",
                },
              }),
              _c(
                "en-button",
                { staticClass: "save-button", on: { click: _vm.saveClick } },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }