/*
 * @Description: 自定义和高级按钮,列表也可以用
 * @Author: 梁平贤
 * @Date: 2020/8/22 11:56
 */

import { componentMixins } from "en-js";
import { btnService } from "@/api/businessModel";
import BusinessModel from "../../data/detailModel/BusinessModel";

export default {
  mixins: [componentMixins],
  computed: {
    // 业务建模组件
    businessComponents() {
      return this._MxFindComponentUpward(this, "BusinessDetail");
    }
  },
  methods: {
    /**
     * 自定义按钮点击
     * @param button 按钮
     * @param isFirstRequst 是否第一次请求
     * @param templateId 数据模板id
     * @param selectIds 数据id,可能是多个
     * @param otherData 第一次接口返回的数据
     * @param data 映射数据后台返回的数据
     * @param batch 列表操作的时候 需要判断是否是 批量操作的 批量操作的 跳转设置中会有不一样的处理
     * @param businessData 需要这个数据来处理 字段的跳转
     * @returns {Promise<void>}
     */
    async customButtonProcess({
      button, isFirstRequst, templateId, selectIds, otherData, data, batch, businessData
    }) {
      const {
 filterFields, uncheckIds = [], checkAll, isRow
} = this.dataSource;

      //  首次请求

      let requestParams = {
        btnKey: button.btnKey,
        templateId,
        ids: selectIds,
        reqType: isFirstRequst ? "1" : "2"
      };

      if (!isRow) {
        requestParams = {
 ...requestParams,
          filterFields,
checkAll,
uncheckIds: uncheckIds.join(",")
        };
      }
      // 第一次接口的返回数据
      if (otherData) {
        const { processId, ruleId } = otherData;
        requestParams = Object.assign(requestParams, {
          processId,
          ruleId
        });
      }
      // 映射数据
      if (data) {
        requestParams = Object.assign(requestParams, data);
      }
      try {
        this.$showLoading();
        const firResp = await btnService.mdCustomBtn(requestParams);
        this.$hideLoading();
        if (firResp && firResp.reqFieldList && firResp.reqFieldList.length) {
          // 二次请求需要的参数
          const addittionDatas = {
            button,
            otherData: firResp,
            templateId,
            selectIds
          };
          // 需要填写映射数据
          const model = BusinessModel.createBusinessModelWithButtonResponse(firResp, addittionDatas);
          this.buttonEventMappingBusinessData = model;
          this.buttonEventMappingBusinessData.businessParams = model.businessParams;
          this.mappingButton = button;
          this.mappingShowFieldList = firResp.showFieldList;
          this.buttonEventMappingDialog = true;
          return;
        }
        this.customJump(button, firResp, batch, selectIds, businessData);
      } finally {
        this.$hideLoading();
      }
    },
    /**
     * 自定义按钮的跳转
     * @param button 按钮
     * @param resp 接口返回的数据
     * @param otherData
     * @param batch 是否是批量操作
     * @param selectIds 当前操作的ids
     */
    async customJump(button, resp, batch, selectIds, businessData) {
      const sleep = (wait) => new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, wait);
      });
      if (button.confirmContents) {
        const confirmContents = typeof button.confirmContents === "object" ? button.confirmContents : JSON.parse(button.confirmContents);
        // eslint-disable-next-line camelcase
        const { post_hints, post_type } = confirmContents;
        // eslint-disable-next-line camelcase
        if (post_hints) {
          if (Number(post_type) === 1) {
            await this.$confirm(post_hints, "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            });
          } else if (Number(post_type) === 2) {
            this.$message.warning(post_hints);
            await sleep(2000);
          }
        }
      } else {
        this.alertToUserWithResp(resp);
      }
      // 跳转
      const jumpPage = button && button.jumpPage;
      // 回退业务建模templateId
      const backBusinessTemplateId = this.$route.query.templateId;
      // 路由参数
      if (jumpPage && jumpPage.id) {
        // 列表
        // if (jumpPage.jumpType === "2" && resp.targetId) {
        if (jumpPage.jumpType === "2") {
          // 详情
          if (button.btnKey === "edit" && batch) {
            // 编辑 批量 走列表
            this.jumpToList(jumpPage.id, "", jumpPage.displayWindow, backBusinessTemplateId);
          } else if (button.btnKey === "edit" || button.btnKey === "add") {
            // 编辑 不是批量 走列表
            this.jumpToDetail(jumpPage.id, selectIds, jumpPage.templateType, jumpPage.displayWindow);
          } else if (resp.targetId && resp.targetId.indexOf(",") < 0 && button.btnKey !== "delete") {
            // 自定义按钮 的逻辑 删除一定走列表
            this.jumpToDetail(jumpPage.id, resp.targetId, jumpPage.templateType, jumpPage.displayWindow);
          } else {
            // 列表
            this.jumpToList(jumpPage.id, "", jumpPage.displayWindow, backBusinessTemplateId);
          }
        } else if (jumpPage.jumpType === "1") {
          // 列表
          this.jumpToList(jumpPage.id, "", jumpPage.displayWindow, backBusinessTemplateId);
        } else if (jumpPage.jumpType === "4") {
          // 外部链接
          if (jumpPage.urlObtainType === "1") {
            // 字段 批量处理的时候 不做跳转
            // eslint-disable-next-line no-constant-condition
            if (selectIds) {
              if (!batch && businessData) {
                // 不是批量操作
                let url = "";
                const mdData = JSON.parse(businessData.paramsForSubmit().mdData);
                url = mdData[jumpPage.refField];
                // if (businessData.mdData && businessData.mdData.originData) {
                //   const businessParams = businessData.paramsForSubmit();
                //   let mdData;
                //   if (businessParams.mdData) {
                //     mdData = JSON.parse(businessParams.mdData);
                //     url = mdData[jumpPage.refField];
                //   } else {
                //     url = businessData.mdData.originData[jumpPage.refField];
                //   }
                // } else {
                //   url = businessData[jumpPage.refField];
                // }

                if (url instanceof Array) {
                  // 单选类型 取第一个name
                  try {
                    url = url[0].name;
                  } catch (error) {
                    console.log(error);
                    url = "";
                  }
                }
                if (url) {
                  // if (url.indexOf("http") === -1) {
                  //     url = `http://${url}`;
                  // }
                  try {
                    // 需要返回值列表 避免停留在原路由
                    this.jumpToList(this.$route.query.templateId);
                    if (jumpPage.displayWindow === "2") {
                      window.open(url);
                    } else {
                      window.location.href = url;
                    }
                  } catch (error) {
                    console.log(error);
                    this.$message("无链接值");
                  }
                } else {
                  this.$message("无链接值");
                  this.jumpToList(this.$route.query.templateId);
                }
              } else {
                this.$message("无链接值");
              }
            } else {
              this.$message("无链接值");
            }
          } else if (jumpPage.urlObtainType === "2") {
            // 外部地址 displayWindow 1 跳转 2新开
            const url = jumpPage.linkUrl || "";
            // if (url.indexOf("http") === -1) {
            //     url = `http://${url}`;
            // }
            try {
              // 需要返回值列表 避免停留在原路由
              this.jumpToList(this.$route.query.templateId);
              if (jumpPage.displayWindow === "2") {
                window.open(url);
              } else {
                window.location.href = url;
              }
            } catch (error) {
              console.log(error);
              this.$message("无链接值");
            }
          }
        }
      } else {
        // 无配置, 刷新当前页面?
        this.refreshCurrentPage();
      }
    },

    // 高级按钮点击
    async advanceButtonProcess({
      button, isFirstRequst, templateId, selectIds, data
    }) {
      // 首次请求
      let requestParams = {
        btnKey: button.btnKey,
        templateId,
        ids: selectIds,
        reqType: isFirstRequst ? "1" : "2"
      };
      // 第一次接口的返回数据 都已经给了,就不需要再给了
      // if (otherData) {
        // 没有给第一次返回的数据
        // requestParams = Object.assign(requestParams, otherData);
      // }
      // 映射数据
      if (data) {
        requestParams = Object.assign(requestParams, data);
      }
      try {
        this.$showLoading();
        const res = await btnService.mdTopBtn(requestParams);
        this.$hideLoading();
        if (!isFirstRequst) {
          // 第二次请求把弹框消失
          this.buttonEventMappingDialog = false;
        }
        const actionType = res.actionType.toString();
        if (actionType === "1") {
          this.alertToUserWithResp(res);
          this.refreshCurrentPage();
        } else if (actionType === "2") {
          // 填写映射数据
          if (res && res.reqFieldList && res.reqFieldList.length) {
            // 需要填写映射数据
            // 二次请求需要的参数
            const addittionDatas = {
              button,
              otherData: res,
              templateId,
              selectIds
            };
            // 需要填写映射数据
            const model = BusinessModel.createBusinessModelWithButtonResponse(res, addittionDatas);
            this.buttonEventMappingBusinessData = model;
            this.buttonEventMappingBusinessData.businessParams = model.businessParams;
            this.mappingButton = button;
            this.mappingShowFieldList = res.showFieldList;
            this.buttonEventMappingDialog = true;
          }
        } else if (actionType === "4") {
          this.jumpToList(res.templateId, "", 1, this.$route.query.templateId);
        } else if (actionType === "5") {
          this.jumpToDetail(res.templateId, res.targetId, res.templateType);
        }
      } finally {
        this.$hideLoading();
      }
    }

  }

};
