<!--
 * @Descripttion: 打印模板
 * @Author: 彭博
 * @Date: 2020-08-20 10:12:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-14 10:42:51
-->
<template>
  <div class="en-print">
    <en-dialog :visible="showDialog" title="打印模板" en-body-scroll @close="closeDialog">
      <div class="content">
        <div class="card-one" v-for="(template, index) in templateList" :key="template.id">
          <div class="card" :class="{'checked': checkIndex === index }" @click="handleCheckCard(template, index)" >
            <en-user-logo :user-name="template.name" icon="icondayin" size="36"></en-user-logo>
            <div class="title">{{ template.name }}</div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button class="assist-btn" @click="dialogConfirm">确定</en-button>
      </div>
    </en-dialog>
    <!-- 打印模板页 :renderInfo="renderInfo" -->
    <div :id='genID'  >
        <template-html
          style="page-break-after:always"
          v-for="(infoItem,ind) in renderInfoList"
          :renderInfo="infoItem"
          :key="ind" ref="printTemlate"
          :businessData='infoItem.businessData'
          :checkTempalte='checkTempalte'
          :detailId='infoItem.id'
          @print='printHtml'></template-html>
    </div>
    <!-- 自定义打印模板 -->
    <img src="" id='JsBarcode-img' alt="" hidden>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import printJS from "print-js";
import { printService } from "@/api";
import * as utils from "@/views/themeSet/components/model/util/index.js";
import { enEqual } from "@/tools/compare";
import { fmoney } from "@/tools/util.js";
import BusinessModel from "@/components/businessDetail/data/detailModel/BusinessModel.js";
import templateHtml from "./templateHtml/temporary";
import formatTemplate from "./mixin/formatTemplate.js";
// import { businessListEntranceRoute } from "@/components/businessDetail/helper";

const JsBarcode = require("jsbarcode");

export default {
  name: "EnPrint",
  componentName: "EnPrint",
  components: { templateHtml },
  mixins: [formatTemplate],
  props: {
    templateId: {
      required: true,
      type: String
    },
    detailId: {
      required: true,
      type: String
    },
    batchType: {
      type: Boolean,
      default: false
    },
    businessData: {
      require: true,
      type: Object,
      default() {
        return {};
      }
    },
    powerList: {
      require: true,
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"]),
    genID() {
      return `printMe${ Number(Math.random().toString().substr(3, 5) + Date.now()).toString(36)}`;
    }
  },
  mounted() {
    // // 打印模板dom 移至body层
    // this.$nextTick(() => {
    //   this.appendBodyPrintMe();
    // });
  },
  data() {
    return {
      printIng: false,
      loadPrintHtmlNum: 0,
      showDialog: false,
      templateList: [],
      checkTempalte: null,
      checkIndex: null,
      renderInfo: {},
      fieldAttr: null,
      renderInfoList: [],
      planState: {
        1: "未开始",
        2: "已完成",
        3: "已延期",
        4: "逾期完成",
        5: "预警中",
        6: "进行中"
      }
    };
  },
  methods: {
    handleCheckCard(template, index) {
      this.checkIndex = index;
      this.checkTempalte = template;
    },
    closeDialog() {
      this.showDialog = false;
    },
    appendBodyPrintMe() {
      const body = document.querySelector("body");
      const printEl = document.querySelector(`#${this.genID}`);
      if (!printEl) return;
      if (body.append) {
        body.append(printEl);
      } else {
        body.appendChild(printEl);
      }
    },

    printHtml() {
      this.loadPrintHtmlNum++;
      if (this.loadPrintHtmlNum < this.renderInfoList.length) return;
      this.loadPrintHtmlNum = 0;
      this.$nextTick(() => {
        this.appendBodyPrintMe();
        printJS({
          printable: `${this.genID}`,
          type: "html",
          // header: "打印测试",
          documentTitle: "河洛为企业盈利",
          maxWidth: 1080,
          scanStyles: true,
          // honorColor: true, // 彩打
          frameId: "ywjm-print",
          targetStyles: ["*"],
          onPrintDialogClose: (err) => {
            console.log("取消打印", err);
          },
          error: (err) => {
            console.log("打印errrrr", err);
          },
          onLoadingStart: (err) => {
            console.log("onLoadingStart", err);
          },
          onLoadingEnd: (err) => {
            console.log("onLoadingEnd", err);
          }
        });
      });
      this.$nextTick(() => {
        this.$refs.printTemlate.forEach((item) => {
          item.opened = false;
        });
      });
    },
    // 判断 是否有权限 没有权限 返回 true
    power(field) {
      // var po = true;
      let obj = "";
      // eslint-disable-next-line consistent-return
      this.powerList.forEach((v) => {
        if (v.field === field) {
          obj = v;
          return false;
        }
      });
      if (!obj) {
        // 关联的数据
        // 有权限
        return false;
      }
        if (enEqual(obj.isShow, 0)) {
          // 有权限
          return false;
        }
          // 没有权限
          return true;
    },
    // 返回显示值 是否需要权限判断 flg 需要false 不需要true
    fillingData1(b, attr, data, flg) { // 填数据
        // $.each(data || [], function (a, b) {
        let value = "";
        // eslint-disable-next-line no-unused-vars
        let tabfield = "";
        // eslint-disable-next-line no-unused-vars
        let tabuuid = ""; // 当前字段对应的mxxx 字段的field和uuid
        if (!attr) {
          return "";
        }
        if (attr.mainTabField) { // 选项卡
          tabfield = attr.mainTabField;
          tabuuid = attr.mainTabUuid;
        }
        const type = `${attr.fieldType}`;
        const field = attr.field;
        if (enEqual(attr.pageHide, "0")) {
          return "";
        }
        // 权限
        // flg 默认false
        if (!flg) {
          if (this.power(field)) {
            return "";
          }
        }

        if (enEqual(type, "1") || enEqual(type, "2")) { // 单行 多行  状态
          if (field === "billStatus") {
            return data.billStatusName;
          }
          return b;
        } if (enEqual(type, 3)) { // 数值
          const percentFlag = attr.percentFlag;
            const reserverDecimal = attr.reserverDecimal || 0;
            const suffix = attr.suffix || "";
          if (enEqual(percentFlag, 0)) {
            value = (Number(b) * 100).toFixed(reserverDecimal) + suffix;
          } else {
            value = (Number(b)).toFixed(reserverDecimal) + suffix;
          }
          return value;
        } if (enEqual(type, 4)) { // 金额
          const suffix = (enEqual(attr.suffix, 2) ? "万元" : "元");
          value = fmoney(Number(b), 2) + suffix;
          return value;
        } if (enEqual(type, 5) || enEqual(type, 15)) { // 单选 多选
          const narr = [];
          const ids = [];
          if (b instanceof Array) {
            b.forEach((d) => {
              narr.push(d.name);
              ids.push(d.id);
            });
          } else if (b instanceof String) {
            narr.push(b);
          }
          return narr.join(",");
        } if (type === "6") { // 关联数据

        } else if (type === "20") { // 业务关联
        } else if (type === "7") { // 日期
          const dateRange = attr.dateRange;
          let value = b.split(",");
          let sta = value[0];
            let end = value[1] || "";
          if (dateRange === "0") {
            if (!end) {
              try {
                sta = utils.getDateAfter(sta, attr.timeFormat);
              } catch (error) {
                sta = "";
              }
              try {
                end = utils.getDateAfter(end, attr.timeFormat);
              } catch (error) {
                end = "";
              }
              value = `${sta }至${ end}`;
              return value;
            }
              sta = utils.getDateAfter(sta, attr.timeFormat);
              end = utils.getDateAfter(end, attr.timeFormat);
              value = `${sta }至${ end}`;
              if (attr.statisticalDate === "0") {
                let count = "";
                let _hour = new Date(utils.safariDate(end)) - new Date(utils.safariDate(sta));
                if (_hour >= 0) {
                  _hour = _hour / 1000 / 3600;
                  count = 0;
                  if (attr.timeFormat === "8") {
                    count = `共${ window.parseInt(_hour / 24) + 1 }天`;
                  } else {
                    const _h = Math.floor(_hour);
                    if (_h >= 24) {
                      count = `共${ Math.floor(_h / 24) }天${ _h % 24 }时${ ((_hour - _h) * 60).toFixed(0) }分`;
                    } else {
                      count = `共${ _h }时${ ((_hour - _h) * 60).toFixed(0) }分`;
                    }
                  }
                }
                return count;
              }
          } else {
            value = utils.getDateAfter(sta, attr.timeFormat);
          }
          return value;
        } else if (type === "8") { // 标识
          let str = "";
          // eslint-disable-next-line consistent-return
          this.fieldAttr.forEach((v) => {
            if (v.uuid === attr.targetField) {
              str = v.name;
              return false;
            }
          });
          if (enEqual(b, 0)) {
            // 完成
            return `已标记（${ str }）`;
          }
            return `未标记（${ str }）`;
        } else if (type === "9" || type === "36") { // 附件与图片
          return "";
        } else if (type === "10") { // 进度
          const initData = {
            value: b || 0,
            time: data[`${field }_operationTime`],
            state: data[`${field }_progressStatus`],
            plan: data[`${field }_planProgress`]
          };
          let str = `${initData.value }%`;
          if (initData.plan && initData.state) {
            str = `完成进度：${ str }（计划进度${ initData.plan }%，${ this.planState[initData.state] || "" }）`;
          } else {
            if (initData.plan) {
              str = `完成进度：${ str }（计划进度：${ initData.plan }%）`;
            }
            if (initData.state) {
              str = `完成进度：${ str }（${ this.planState[initData.state] || "" }）`;
            }
          }
          return str;
        } else if (type === "19") { // 收付信息

        } else if (type === "11" || type === "19") { // 明细
          // return 明细和子集没有处理 关联的数据

        } else if (type === "16") { // 纯文本控制
          return attr.captionDescription;
        } else if (type === "29") { // 地址
          const val = (b.provinceName || "") + (b.cityName || "") + (b.districtName || "") + (b.address || "");
          return val;
        } else if (type === "30") { // 子项
        } else if (type === "31") { // 分割线
        } else if (type === "32") { // 文本 ？
        } else if (type === "37") { // 收付对象

        } else if (type === "38") { // 关联对象  类型为数据对象 的时候

        } else if (type === "39") {
          return b;
        }
        return "";
      // });
    },
    async print() {
      console.log(1);
      this.$showLoading();
      const status = await this.beforePrint();
      this.$hideLoading();
      if (!status) {
        this.openDialog();
      } else {
        this.startPrint();
      }
    },
    dealPrintTemplate(data) {
      const map = {};
      let src = "";
      data.logoUrl = this.userInfo.channelLogo;
      this.fieldAttr = data.fieldAttr;
      if (data.logoUrl) {
          src = `${this.urlParam.showImageServer}?token=${ this.urlParam.showImageToken }&ts=${ this.urlParam.imgTs }`
          + `&fileCode=${ data.logoUrl }&appKey=${ this.urlParam.appKey }&attachmentName=${ data.logoUrl}`;
      } else {
         src = `${window.location.origin}/public/images/logoall.png`;
      }
      map.logo = src;
      JsBarcode("#JsBarcode-img", data.mdData.code || "");
      const qrcode = document.querySelector("#JsBarcode-img").src;
      map.qrcode = qrcode;
      // eslint-disable-next-line consistent-return
      this.fieldAttr.forEach((v) => {
        if ([11, 19, 38].indexOf(v.fieldType) > -1) {
          if (v.sub && v.sub.length) {
            // 取出明细数据
            const mdData11 = data.mdData[v.field] || [];
            // eslint-disable-next-line consistent-return
            v.sub.forEach((v1) => {
              if (v1.fieldType === 35) {
                // 子集
                // 取出所有子集的数据
                let mdData35 = [];
                // 合并数组
                mdData11.forEach((v2) => {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const [k3, v3] of Object.entries(v2)) {
                    if (k3 === v1.field) {
                      mdData35 = mdData35.concat(v3 || []);
                    }
                  }
                });
                // console.log("ziji",mdData35)
                // 循环子集
                if (v1.sub && v1.sub.length) {
                  // eslint-disable-next-line consistent-return
                  v1.sub.forEach((v2) => {
                    if (map[v2.uuid]) {
                      console.log(map[v2.uuid]);
                    } else {
                      // 判断权限 如果没有权限直接终止循环  ""
                      if (this.power(v2.field)) {
                        map[v2.uuid] = "";
                        return true;
                      }
                      map[v2.uuid] = [];
                    }
                    // 格式化数据 循环子集的listmap
                    mdData35.forEach((v3) => {
                      if (v3[v2.field] === undefined) {
                        map[v2.uuid].push("");
                      } else {
                        map[v2.uuid].push(this.fillingData1(v3[v2.field], v2, v3) || "");
                      }
                    });
                  });
                }
              } else {
                // 明细这里的结果是个数组
                if (map[v1.uuid]) {
                  console.log(map);
                } else {
                  // 判断权限 如果没有权限直接终止循环  ""
                  if (this.power(v1.field)) {
                    map[v1.uuid] = "";
                    return true;
                  }
                  map[v1.uuid] = [];
                }
                // 格式化数据 循环明细的listmap
                mdData11.forEach((v2) => {
                  if (v2[v1.field] === undefined) {
                    map[v1.uuid].push("");
                  } else {
                    map[v1.uuid].push(this.fillingData1(v2[v1.field], v1, v2) || "");
                  }
                });
              }
            });
          }
        } else if (v.fieldType === 6) {
          // 关联数据没有权限 没有值
          if (this.power(v.field)) {
            return true;
          }
          // showContent d.uuid = b.field + "_" + d.field;
          const li = data.mdData[v.field] || [];
          // 循环数据 数组输出【】
          // eslint-disable-next-line consistent-return
          v.showContent.forEach((v1) => {
            // 明细不打印
            let field = "";
            if (v1.field === "billStatus") {
              field = "billStatusName";
            } else if (v1.field === "createDepId") {
              field = "createDepName";
            } else if (v1.field === "createId") {
              field = "createName";
            } else if (v1.field === "createOrgId") {
              field = "createOrgName";
            } else if (v1.field === "createTime") {
              // field = "createTime";
              v1.timeFormat = 9;
            }
            if (v1.fieldType === 6 || v1.fieldType === 11 || v1.fieldType === 19 || v1.fieldType === 37) {
              return true;
            }
            if (map[`${v.field }_${ v1.uuid || v1.field}`]) {
              console.log(map[`${v.field }_${ v1.uuid || v1.field}`]);
            } else {
              map[`${v.field }_${ v1.uuid || v1.field}`] = [];
            }
            li.forEach((v2) => {
              if (field) {
                if (v2[field] === undefined) {
                  map[`${v.field }_${ v1.uuid || v1.field}`].push("");
                } else {
                  map[`${v.field }_${ v1.uuid || v1.field}`].push(v2[field]);
                }
              } else if (v2[v1.field] === undefined) {
                  map[`${v.field }_${ v1.uuid || v1.field}`].push("");
              } else {
                map[`${v.field }_${ v1.uuid || v1.field}`].push(this.fillingData1(v2[v1.field], v1, v2, true) || "");
              }
            });
          });
        } else if (v.fieldType === 37) {
          // 收付对象 可以改成整体输出
          // ["name", "baddr", "no", "way", "money"]
          const bankInfo = [
            {
              uuid: "name", fieldType: "1", name: "", field: "name", requrein: "0", showPrefix: "0", dataSource: 3, parentField: "bankInfo"
            },
                        {
             uuid: "baddr", fieldType: "1", name: "", field: "baddr", requrein: "0", showPrefix: "0", dataSource: 3, parentField: "bankInfo"
            },
                        {
             uuid: "no", fieldType: "1", name: "", field: "no", requrein: "0", showPrefix: "0", dataSource: 3, parentField: "bankInfo"
            },
                        {
              uuid: "way", fieldType: "1", name: "", field: "way", requrein: "0", onlyFlag: "1", showPrefix: "0", parentField: "bankInfo"
            },
                        {
              uuid: "money", fieldType: "4", name: "", field: "money", requrein: "0", onlyFlag: "1", showPrefix: "0", parentField: "bankInfo"
            }
          ];
          //
          if (this.power(v.field)) {
            return true;
          }
          const mdData37 = data.mdData[v.field] || [];

          // eslint-disable-next-line no-constant-condition
          if (true) {
            // 整体输出
            map[v.uuid] = [];
            mdData37.forEach((v2) => {
              let str = "";
              str = `${v2.name || "" } ${ v2.baddr || "" } ${ v2.no || "" } ${ v2.way || "" } ${ v2.money || ""}`;
              map[v.uuid].push(str);
            });
          } else {
            // 字段输出
            bankInfo.forEach((v1) => {
              // 明细这里的结果是个数组
              if (map[v1.uuid]) {
                console.log(map);
              } else {
                map[v1.uuid] = [];
              }
              // 格式化数据 循环明细的listmap
              mdData37.forEach((v2) => {
                if (v2[v1.field] === undefined) {
                  map[v1.uuid].push("");
                } else {
                  map[v1.uuid].push(this.fillingData1(v2[v1.field], v1, v2) || "");
                }
              });
            });
          }
        } else if (data.mdData[v.field] === undefined) {
            if (v.fieldType === 16) {
              // 说明的特殊处理
              map[v.uuid] = v.captionDescription;
            } else {
              map[v.uuid] = "";
            }
        } else if (this.power(v.field)) {
            map[v.uuid] = "";
        } else {
          map[v.uuid] = this.fillingData1(data.mdData[v.field], v, data.mdData) || "";
        }
        });
      // 系统字段
      const sys = [
        {
          field: "createName", fieldType: 1, name: "创建人", uuid: `${this.templateId}createId`, areaObjType: "002", dataSource: 1, fieldArea: 1
        },
                {
          field: "createTime", timeFormat: "9", fieldType: 7, name: "创建时间", uuid: `${this.templateId}createTime`, dateRange: 1, fieldArea: 1
        },
                {
          field: "createDepName", fieldType: 1, name: "创建人部门", uuid: `${this.templateId}createDepId`, areaObjType: "001", dataSource: 1, fieldArea: 1
        },
                {
          field: "createOrgName", fieldType: 1, name: "创建人机构", uuid: `${this.templateId}createOrgId`, areaObjType: "001", dataSource: 1, fieldArea: 1
        },
                {
          field: "billStatus", fieldType: 1, name: "状态", uuid: `${this.templateId}billStatus`, fieldArea: 1
        },
        {
          field: "dataSources", fieldType: 1, name: "数据来源", uuid: `${this.templateId}dataSources`, dataSource: 1, areaObjType: 999, areaObjTypeId: "MD_SORUCE_TYPE"
        },
        {
          field: "vcTime", timeFormat: "8", fieldType: 7, name: "记账时间", uuid: `${this.templateId}vcTime`, dateRange: 1, fieldArea: 1
        },
        {
          field: "vcStatus", fieldType: 5, name: "记账状态", uuid: `${this.templateId}vcStatus`, dataSource: 1, areaObjType: 999, areaObjTypeId: "MD_VC_STATUS"
        },
        {
          field: "mSummary", fieldType: 1, name: "业务摘要", uuid: `${this.templateId}mSummary`, fieldArea: 1
        }
      ];
      sys.forEach((v) => {
        // 格式化数据
        if (data.mdData[v.field] === undefined) {
          map[v.uuid] = "";
        } else if (this.power(v.field)) {
            map[v.uuid] = "";
        } else {
          map[v.uuid] = this.fillingData1(data.mdData[v.field], v, data.mdData) || "";
        }
      });
      this.customPrint(data.mdHtml, map);
    },
    customPrint(mdHtml, map) {
      console.log(mdHtml, "content");
      if (mdHtml) {
        mdHtml = typeof mdHtml === "string" ? JSON.parse(mdHtml) : mdHtml;
      } else {
        mdHtml = {};
      }
      let printWrap;
      if (!document.querySelector("#x-spreadsheet-custom")) {
          printWrap = document.createElement("div");
          printWrap.id = "x-spreadsheet-custom";
          document.body.appendChild(printWrap);
      }
      // 打印前隐藏 业务建模区域
      const xs = window.x_spreadsheet("#x-spreadsheet-custom",
          {
            showBottombar: false,
            printMod: true,
            sourceData: map,
            // 关闭
            closePrintCb() {
            },
            printCallBack(err) {
              this.$message.error(err);
            }
          });

      xs.loadData(mdHtml.content);
      xs.printData();
      this.showDialog = false;
    },
    // 打印之前查询打印模板 如大于1个模板则需选择模板
    async beforePrint() {
      const result = await printService.queryPrintTemplate({
        templateId: this.templateId
      });
      if (this.batchType) {
        this.templateList = result.filter((item) => String(item.enableFlag) === "0" && String(item.styleType) !== "4");
      } else {
        console.log(result);
        this.templateList = result.filter((item) => `${item.enableFlag}` === "0");
      }
      console.log(this.templateList, "this.templateList");
      if (this.templateList && this.templateList.length === 1) {
        this.checkTempalte = this.templateList[0];
      }
      return result?.length <= 1;
    },
    // 打开模板选择
    openDialog() {
      this.showDialog = true;
    },
    getNewArray(arr, len) { // size=5，要分割的长度
      const size = Number(len);
      const arrNum = Math.ceil(arr.length / size, 10); // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
      let index = 0; // 定义初始索引
      let resIndex = 0; // 用来保存每次拆分的长度
      const result = [];
      while (index < arrNum) {
          result[index] = arr.slice(resIndex, size + resIndex);
          resIndex += size;
          index++;
      }
      return result;
    },
    dialogConfirm() {
      if (!this.checkTempalte) {
        this.$message("请选择要打印的模板");
      } else {
        this.startPrint();
      }
    },
    async queryTemplateDetail() {
      this.$showLoading();
      let result;
      if (this.batchType) {
        result = await printService.queryPrintDetailBatch({
          templateId: this.templateId,
          printId: this.checkTempalte?.id,
          ids: this.detailId,
          styleType: this.checkTempalte.styleType
        });
      } else {
        result = await printService.queryPrintDetail({
          templateId: this.templateId,
          printId: this.checkTempalte?.id,
          id: this.detailId,
          styleType: this.checkTempalte.styleType
        });
      }

      this.$hideLoading();
      return result;
    },
    spliceRender(info) {
      const res = [];
      const { fieldAttr, mdData } = info;
      const { page } = typeof info.mdHtml === "string" ? JSON.parse(info.mdHtml) : info.mdHtml;

      if (page.detailRepeatFlag === 0 && page.detailRepeatNum > 0) {
        const fields = fieldAttr.filter((fieldItem) => `${fieldItem.fieldType}` === "11");//  找到明细数据
        let maxLen = 0;
        if (fields && fields.length) {
            const childRenderInfos = [];
            fields.forEach((fieldItem) => {
              const { field } = fieldItem;
              const childData = this.getNewArray(mdData[field], page.detailRepeatNum);
              maxLen = Math.max(maxLen, childData.length);
              childRenderInfos.push({
                field,
                data: childData
              });
            });

            Array(maxLen).fill({}).forEach((i, index) => {
              const result = cloneDeep(info);
              childRenderInfos.forEach((childRender) => {
                result.mdData[childRender.field] = childRender.data[index] || [];
              });
              res.push(result);
            });
        } else {
          res.push(info);
        }
      } else {
        res.push(info);
      }
      return res;
    },
    async startPrint() {
      if (!this.checkTempalte) {
        this.$message.error("找不到打印模板");
        return;
      }
      this.renderInfoList = [];
      const detailInfo = await this.queryTemplateDetail();
      console.log(this.checkTempalte);
      if (this.checkTempalte.styleType === 4) { // 自定义打印
        this.dealPrintTemplate(detailInfo);
        return;
      }
      this.printIng = true;
      if (this.batchType) {
        Object.keys(detailInfo).forEach((i) => {
          console.log(detailInfo[i]);

          const detailInfos = this.spliceRender(detailInfo[i]);// 打印明细设置启用
          detailInfos.forEach((detailItem) => {
            this.renderInfoList.push({ ...this.formatData(detailItem), id: i, businessData: new BusinessModel(detailInfo[i]) });
          });
        });
      } else {
        const detailInfos = this.spliceRender(detailInfo);
        detailInfos.forEach((detailItem) => {
          this.renderInfoList.push({ ...this.formatData(detailItem), id: this.detailId, businessData: this.businessData });
        });
      }
      console.log("%c 开始调用浏览器打印功能", "color: blue", this.renderInfoList);
      this.$nextTick(() => {
        this.$refs.printTemlate.forEach((item) => {
          item.print();
        });
      });
    }
  },
  created() {
    console.log("created print", this.templateId, this.detailId);
  }
};
</script>

<style scope lang="scss">
.x-spreadsheet-print {
  z-index: 10000;
}
.en-print {
  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    // justify-content: flex-start;
  }
  .en-card {
    margin-bottom: 20px;
    margin-right: 20px;
    .title {
      display: inline-block;
      font-size: 14px;
    }
  }
  .card-one {
    width: 220px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .card {
    width: 100%;
    height: 80px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px 4px 4px;
    border: 1px solid #e8ecf2;
    transition: 0.3s ease-in-out all;
    display: flex;
    align-items: center;
    cursor: pointer;
    // 悬浮状态下的样式处理
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
    &.checked{
      background-color: #f5f9fd;
    }
    .title {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}
</style>
