<template>
  <en-dialog
    :visible="dialogVisible"
    v-if="dialogVisible"
    class="async-data-dialog"
    destroy-on-close
    width="700px"
    :close-on-click-modal="false"
    @close="dialogVisible = false"
  >
    <div slot="title">
      <div>
        同步数据
        <el-tooltip effect="dark" :offset="-20" popper-class="async-tips" placement="bottom-start">
            <div slot="content">
              <p>
                <span class="dot" style="background: #49bef3;"></span>
                对账日期：指同步该日的对财文件
              </p>
              <p>
                <span class="dot" style="background: #4fd2c2;"></span>
                批次号：仅支持中金支付，指每天按时间顺序，每个小时为一个对账批次，每天的对账批次有24个（01-24）。
                通过该字段可指定下载对账日期的对应批次号的对账文件（只包含明细对账单），若该字段为空，默认下载对账日期所有时段对账文件
              </p>
            </div>
            <i class="el-icon-warning" ></i>
          </el-tooltip>
      </div>
    </div>
    <el-form :model="dataForm" ref="dataForm" label-width="80px" class="async-form">
      <el-form-item
        label="对账日期"
        prop="billDate"
        :rules="[
          { required: true, message: '请选择对账日期'}
        ]"
      >
        <en-date-picker
          v-model="dataForm.billDate"
          type="date"
          style="width: 100%;"
          placeholder="选择日期">
        </en-date-picker>
      </el-form-item>
      <el-form-item
        label="批次"
        prop="batchNo"
      >
        <en-select style="width: 100%;" :data="batchNoOptions" v-model="dataForm.batchNo" placeholder="请选择">
          <!-- <el-option
            v-for="item in batchNoOptions"
            :key="item.value"
            :label="item.value"
            :value="Number(item.value)">
          </el-option> -->
        </en-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <en-button @click="confirmHandle">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import dayJs from "dayjs";

export default {
  name: "AsyncDataDialog",
  props: {
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        billDate: Date.now(),
        batchNo: ""
      }
    };
  },
  computed: {
    batchNoOptions() {
      const padLeftZero = (num) => (num > 10 ? num : `0${ num}`);
      return Array.from({ length: 24 }, (v, value) => ({ label: padLeftZero(value + 1), value: value + 1 }));
    }
  },
  methods: {
    // 保存
    confirmHandle() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const paramList = Object.keys(this.dataForm).map((field) => ({
            field,
            value: field === "billDate" ? dayJs(this.dataForm[field]).format("YYYY-MM-DD") : this.dataForm[field],
            colType: field === "billDate" ? "7" : "1"
          }));
          this.$emit("confirmHandle", paramList);
        }
      });
    },
    open() {
      this.dialogVisible = true;
    }
  }
};
</script>
<style lang="scss">
.async-data-dialog {
  .async-form {
    width: 100%;
  }
  .el-icon-warning {
    color:#f7bf27;
    font-size: 16px;
  }
}

.async-tips {
  p {
    font-size: 12px;
    color: #fff;
    width: 400px;
  }
  .dot {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
</style>
