// import ProcessEntity from "@/tools/classes/process/ProcessEntity.js";

import ProcessEntity from "@/tools/classes/process/ProcessEntity";

export default class MdInfo extends ProcessEntity {
  // 共享人[{ID:"", name:"",userl:""}]
  shareUserList;

  // 按钮收起或者平铺 1 平铺 2收起
  btnArrange;

  // 模板id
  templateId;

  // 模板名称
  templateName;

  version;

  // 最新版本号
  latestVersion;

  // 模板向上汇总和向下分解配置
  templateExt;

  // 上层父级的Id
  parentId;

  // 存在父级,则此字段名称有值
  parentName;

  // 0有 1没有  所属上级是否存在模板中
  hasLevel;

  // 基础数据名称
  objectName;

  objTypeId;

  objectTypeId;

  objectType;

  basicType;

  // 关联的seesionId
  relationSessionId;

  // 关联基础数据名称
  name;

  // 数据类型 BusinessType
  type;

  constructor(data) {
    super(data);
    this.parsingData(data);
  }

  get shareUserName() {
    if (this.shareUserList && this.shareUserList.length > 0) {
      return `由${this.shareUserList.map((user) => user.name).join("、")}共享`;
    }
    return "";
  }

  // 流程需要的参数
  get flowParameters() {
    const params = {
      approvalComment: this.approvalComment,
      customNodeId: this.customNodeId,
      customUserId: this.customUserId,
      pId: this.pId,
      relationSessionId: this.relationSessionId,
      objTypeId: this.objTypeId,
      objectTypeId: this.objectTypeId

    };
    // 通知人, 流程审批人
    Object.assign(params, this.noticeSubmitData, this.flowSubmitData);
    return params;
  }
}
