/*
 * @Author: 梁平贤
 * @Date: 2020-06-16 14:44:40
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-23 15:51:22
 * @Description: 业务建模实体入口
 */

// 解析业务建模数据的方法
import { uuid } from "en-js";
import BaseClass from "@/tools/classes/base/BaseClass";
import * as BusinessColumnProcess from "../parse/BusinessColumnProcess";
// 类型声明
import { BusinessDataOpenType, BusinessFieldType } from "../BusinessCommonHeader.js";
import BusinessTemplateExt from "./templateExt/BusinessTemplateExt.js";
import MDData from "./mdData/MDData";
import MdInfo from "./mdInfo/MdInfo";
import MdPermission from "./permission";
import TemplateHandle from "../templateFunction/index";
import addFixedSection from "./extensions/BMFixedSection";
import dataParsingIfNeeded from "./extensions/BMDataParsing";
import businessRequest from "./extensions/BMRequest";
import submitData from "./extensions/BMSubmitData";
import buttonCreateModel from "./extensions/BMBPEventCreateModel";
import { BillStatus } from "../../../../tools/classes/process/ProcessHeader";
import { enEqual } from "../../../../tools/compare";
// 添加固化的section装饰器(拆分代码,解耦)
@addFixedSection
// 数据赋值等抽离处理(默认值,服务器数据等)
@dataParsingIfNeeded
// 封装的数据请求等
@businessRequest
// 提交数据
@submitData
// 映射赋值生成数据
@buttonCreateModel
export default class BusinessModel extends BaseClass {
  // 子项新增或者解析的时候,需要给这个字段赋值 子项值为1
  dataLevel = 0;

  // 主表区字段 缓存使用, 如果需要调用,请使用get方法, this.mainInfos
  _mainInfos;

  // 所有字段 缓存使用 明细和子集的只包含最先开始解析的模板字段,不包含明细行 请使用get方法, this.allInfos
  _allInfos;

  // 校验类型 有的只校验输入限制, 有的需要全部校验..1:全部校验 2:输入限制校验
  checkType = 1;

  // 是否已经提交过, 提交过, 就不判断路由返回钩子提示保存了.
  isSubmitted = false;

  constructor(datas = {}, options = {}) {
    super();
    // 原始数据.无解析,预留
    this.originData = datas;
    // 类型
    this.businessType = options.businessType;
    //  openType:1新增 2查看 3只读 4编辑
    this.openType = options.openType;
    // 数据id,可能为空
    this.id = options.id;
    // 业务建模请求参数
    this.businessParams = options || {};
    // 数据解析
    this.mdData = new MDData(datas.mdData || {});
    // 区域信息
    this.sections = BusinessColumnProcess.parsingSections(datas.fieldAttr, this);
    // 布局信息
    BusinessColumnProcess.parsingLayout(datas, this.sections, this.businessType, this);
    // 获取主表区字段, 这块用的比较频繁
    // 提交的submitToken,生成一个
    this.submitToken = uuid();
    // 关联的行程id 首次提交用
    this.tripId = options.tripId;
    // 考勤id
    this.attRecordId = options.attRecordId;
    // 邮件id
    this.mailId = options.mailId;
    // 邮件映射按钮id
    this.mailBtnId = options.mailBtnId;
    // 任务相关参数
    this.taskSourceId = options.taskSourceId;
    this.taskSourceTemplateId = options.taskSourceTemplateId;
    this.taskSource = options?.viewType === "3" ? "1" : options.taskSource; // 当是任务的新建任务的时候 定值1
    this.bindProperties([
      // 字段权限
      {
        name: "powerList",
        type: Array,
        className: MdPermission
      },
      // 额外属性
      {
        name: "templateExt",
        type: Object,
        className: BusinessTemplateExt
      },
      // 模板信息
      {
        name: "mdInfo",
        type: Object,
        className: MdInfo
      }
    ], datas);
    // 模板计算相关
    this.templateHandle = new TemplateHandle(this);
    // 特殊添加的section,在子项里面不应该展示,所以没放入sections中
    this.additionSection = this.addFixedSection();
    // 详情页需要展示的东西 合并处理
    this.showSections = [...this.sections];
    if (this.additionSection) {
      this.showSections.push(this.additionSection);
    }
    //  处理数据
    this.dataParsingIfNeeded();
  }

  // 页面编辑权限统一读取
  get pageEdit() {
        if (enEqual(this.openType, BusinessDataOpenType.readOnly)) {
      return false;
    }
    if (enEqual(this.openType, BusinessDataOpenType.add) || enEqual(this.openType, BusinessDataOpenType.edit)) {
      return true;
    }
    // 下面这个状态按逻辑是不会走的 也就是新增
    if (!this.mdInfo.billStatus) {
      return true;
    }
    // 如果是查看模式, 并且不是在流程中, 并且不是草稿状态, 则不能编辑
    if (enEqual(this.openType, BusinessDataOpenType.view)) {
      // 草稿,和这三种状态都是能直接编辑的 其他都不能,要点编辑按钮才行
      if ([BillStatus.unCommit, BillStatus.pending, BillStatus.approved, BillStatus.approving].indexOf(this.mdInfo.billStatus) > -1) {
        return true;
      }
      return false;
    }

    return false;
  }

  // 是不是服务器数据,判断有没有数据id
  get isServerData() {
    return !!this.id;
  }

  // 使用的比较频繁, 缓存一下 主表区字段
  get mainInfos() {
    if (this._mainInfos) {
      return this._mainInfos;
    }
    const mainInfos = [];
    this.sections.forEach((section) => {
      if (section.fields?.length > 0) {
        mainInfos.push(...section.fields);
      }
    });
    this._mainInfos = mainInfos;
    return this._mainInfos;
  }

  // 使用的比较频繁, 缓存一下 所有字段
  get allInfos() {
    if (this._allInfos) {
      return this._allInfos;
    }
    const allInfos = [];
    this.mainInfos.forEach((column) => {
      // 主表字段
      allInfos.push(column);
      if (column.fieldType === BusinessFieldType.detailParent) {
        column.sub.forEach((detailColumn) => {
          // 明细字段
          allInfos.push(detailColumn);
          if (detailColumn.fieldType === BusinessFieldType.subSet) {
            // 子集字段
            allInfos.push(...detailColumn.sub);
          }
        });
      }
    });
    this._allInfos = allInfos;
    return this._allInfos;
  }

  // 处理页签数据 zjh
  field45change() {
    (this._allInfos || []).forEach((item) => {
      if (String(item.fieldType) === "45") {
        // 页签类型 字段
        // 显示的 子页签
        const showList = [];
        (item.showColumns || []).forEach((item1) => {
          let flg = false;
          (item1.values || []).forEach((item2) => {
            (item2 || []).forEach((item3) => {
              // 找到 页签下面的字段
              const a = item.findMainAreaFieldByUuid(item3.uuid);
              // 如果有一个显示字段的 就说明这个页签要显示
              if (a.displayable) {
                flg = true;
              }
            });
          });
          // 全都是隐藏的字段页签也不显示
          if (!flg) {
            item1.show = false;
          } else {
            item1.show = true;
            // 只要有一个显示 整体就会显示
            showList.push(item1.id);
          }
        });
        if (showList.length === 0) {
            // 没有显示的页签 页签整体不显示
            item.showColumnsFlg = false;
        } else if (!showList.includes(item.currentSelectTabId)) {
            // 显示 默认显示第一个页签
            item.showColumnsFlg = true;
            item.currentSelectTabId = showList[0];
        }
      }
    });
  }
}
